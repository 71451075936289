import React, { ReactNode, createContext, useContext, useState } from 'react';

import { MatchType, SubSystem, Tag } from '@controlrooms/models';

interface TagExplorerContextProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  matchType: MatchType;
  setMatchType: (type: MatchType) => void;
  selectedTags: Record<number, Tag[]>;
  setSelectedTags: (tags: Record<number, Tag[]>) => void;
  selectedSystems: SubSystem[];
  setSelectedSystems: (systems: SubSystem[]) => void;
}

const TagExplorerContext = createContext<TagExplorerContextProps | undefined>(undefined);

export const TagExplorerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [matchType, setMatchType] = useState<MatchType>(MatchType.ANY);
  const [selectedTags, setSelectedTags] = useState<Record<number, Tag[]>>({});
  const [selectedSystems, setSelectedSystems] = useState<SubSystem[]>([]);

  const value = {
    searchTerm,
    setSearchTerm,
    matchType,
    setMatchType,
    selectedTags,
    setSelectedTags,
    selectedSystems,
    setSelectedSystems,
  };

  return <TagExplorerContext.Provider value={value}>{children}</TagExplorerContext.Provider>;
};

export const useTagExplorer = (): TagExplorerContextProps => {
  const context = useContext(TagExplorerContext);
  if (!context) {
    throw new Error('useTagExplorer must be used within a TagExplorerProvider');
  }
  return context;
};
