import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import { useTagExplorer } from '../../../app-v2/context/tag-explorer-context';
import { customScrollPrimary } from '../../../app/global-styles';
import { highlightCss } from '../analyze-system-browser/styles/shared-styles';

import { Checkbox } from '@controlrooms/components';
import { SubSystem, Tag, MatchType } from '@controlrooms/models';
import { flattenFolders } from '@controlrooms/utils';

interface FolderTreeProps {
  folders: SubSystem[];
  onSelectTags: (selectedTags: Record<number, Tag[]>, selectedSystems: SubSystem[]) => void;
  preSelectedTags?: Record<number, Tag[]>; // New prop for preselected tags
  defaultSelectedTags?: Record<number, Tag[]>; // New prop for preselected tags
  multiselect?: boolean;
  systemSelectable?: boolean;
}

const StyledVirtuoso = styled(Virtuoso)`
  ${customScrollPrimary}
`;
const System = styled.div<{ level: number }>`
  font-family: 'Open Sans';
  ${highlightCss}
  .tagNode {
    padding-left: ${({ level }) => (level + 1) * 20}px;
    background: ${({ theme }) => theme.v2.sidebar.tag.tagEvenBg};
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    align-self: stretch;
    height: 28px;
    padding-right: 4px;
    align-self: stretch;
    .name {
      display: block;
      height: 15px;
      padding: 1px 2px;
      align-items: center;
      gap: 1px;
      border-radius: 2px;
      background: ${({ theme }) => theme.v2.sidebar.tag.tagColor};
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      textwrap: none;
    }
    .description {
      overflow: hidden;
      color: ${({ theme }) => theme.investigate.tag.folder.label};
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      flex: 1 0 0;
    }
  }
  .tagNode:nth-child(odd) {
    padding-left: ${({ level }) => (level + 1) * 20}px;
    background: ${({ theme }) => theme.v2.sidebar.tag.tagOddBg};
  }
  .tagNode:hover {
    background: ${({ theme }) => theme.v2.sidebar.tag.hoverBg};
  }
  .tagNode.selected {
    background: ${({ theme }) => theme.v2.sidebar.tag.selectedBg};
  }
`;

const TreeNode = styled.div<{ indent: number }>`
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.indent ? props.indent * 20 : 10)}px;
  background: ${({ theme, indent }) =>
    indent === 0 ? theme.v2.sidebar.parentSystemBackground : theme.v2.sidebar.systemBackground};
  &:hover {
    background: ${({ theme }) => theme.v2.sidebar.tag.hoverBg};
  }
  &.selected {
    background: ${({ theme }) => theme.v2.sidebar.tag.selectedBg};
  }
`;

const TagNodes = styled.div`
  cursor: pointer;
`;

const FolderTree: React.FC<FolderTreeProps> = memo(
  ({
    folders,
    onSelectTags,
    preSelectedTags = {},
    defaultSelectedTags = {},
    multiselect = true,
    systemSelectable = false,
  }) => {
    const [collapsed, setCollapsed] = useState<{ [key: number]: boolean }>({});
    const [selectedTags, setSelectedTags] = useState<Record<number, Tag[]>>(preSelectedTags);

    // Initialize selectedSystems based on preSelectedTags
    const initialSelectedSystems = useMemo(
      () => new Set<number>(Object.keys(preSelectedTags).map(Number)),
      [preSelectedTags],
    );
    const [selectedSystems, setSelectedSystems] = useState<Set<number>>(initialSelectedSystems);

    const [flattenedFolders, setFlattenedFolders] = useState<
      Array<{ folder: SubSystem; level: number; visible: boolean }>
    >([]);
    const { searchTerm, matchType } = useTagExplorer();

    // Expand folders with preselected tags
    useEffect(() => {
      if (!multiselect) return;
      const foldersToExpand = Object.keys(preSelectedTags).map(Number);
      setCollapsed((prev) => {
        const newCollapsed = { ...prev };
        foldersToExpand.forEach((folderId) => {
          newCollapsed[folderId] = false; // Ensure folders are expanded
        });
        return newCollapsed;
      });
    }, [multiselect, preSelectedTags]);

    useEffect(() => {
      const flattenAndFilterFolders = (
        folders: SubSystem[],
        searchTerm: string,
        matchType: MatchType,
      ) => {
        const matchesSearch = (text = ''): boolean => {
          if (text === '') return false;
          const _searchTerm = searchTerm.toLowerCase();
          const searchTermArray = _searchTerm.trim().split(' ');
          if (matchType === MatchType.ANY) {
            return searchTermArray.some((el) => {
              return text.toLowerCase().includes(el);
            });
          } else {
            return searchTermArray.every((el) => {
              return text.toLowerCase().includes(el);
            });
          }
        };

        const filterTags = (folders: SubSystem[]): SubSystem[] => {
          const result: SubSystem[] = [];
          const filterFolder = (folder: SubSystem): SubSystem | null => {
            const folderMatches = matchesSearch(folder?.infra_display_name);
            const filteredTags = folder.tags
              ? folder.tags.filter((tag) => {
                  const tagNameDescription =
                    `${tag?.tag_display_name} ${tag?.description} ${tag?.uom}`.toLowerCase();
                  return matchesSearch(tagNameDescription);
                })
              : [];
            const filteredSubfolders = folder.subfolders
              ? folder.subfolders
                  .map(filterFolder)
                  .filter((subfolder): subfolder is SubSystem => subfolder !== null)
              : [];

            // If folder matches but no tags match, include folder with empty tags array
            if (folderMatches && filteredTags.length === 0 && filteredSubfolders.length === 0) {
              return {
                ...folder,
                tags: [],
                subfolders: filteredSubfolders,
              };
            }

            // Include the folder if it has any matching tags or subfolders
            if (filteredTags.length > 0 || filteredSubfolders.length > 0) {
              return {
                ...folder,
                tags: filteredTags,
                subfolders: filteredSubfolders,
              };
            }

            // If the folder itself matches and has no tags or subfolders, include it
            if (folderMatches) {
              return {
                ...folder,
                tags: [],
                subfolders: [],
              };
            }

            return null;
          };
          folders.forEach((folder) => {
            const filteredFolder = filterFolder(folder);
            if (filteredFolder) {
              result.push(filteredFolder);
            }
          });
          return result;
        };

        const filteredFolders = filterTags(folders);
        return flattenFolders(filteredFolders, 0, true, collapsed);
      };

      setFlattenedFolders(flattenAndFilterFolders(folders, searchTerm, matchType));
    }, [folders, searchTerm, matchType, collapsed]);

    const handleToggle = useCallback((folderId: number) => {
      setCollapsed((prevState) => ({
        ...prevState,
        [folderId]: !prevState[folderId],
      }));
    }, []);

    const handleTagSelect = useCallback((systemId: number, tag: Tag) => {
      setSelectedTags((prevState) => {
        const systemTags = prevState[systemId] || [];
        const isSelected = systemTags.some((selectedTag) => selectedTag.name === tag.name);
        const newSystemTags = isSelected
          ? systemTags.filter((selectedTag) => selectedTag.name !== tag.name)
          : [...systemTags, tag];
        return { ...prevState, [systemId]: newSystemTags };
      });
    }, []);

    const handleTagClick = useCallback(
      (subsystem: SubSystem, tag?: Tag) => {
        onSelectTags({ [subsystem.folder]: tag ? [tag] : [] }, [subsystem]);
      },
      [onSelectTags],
    );

    const matchesSearch = useCallback(
      (text = ''): boolean => {
        if (text === '') return false;
        const _searchTerm = searchTerm.toLowerCase();
        const searchTermArray = _searchTerm.trim().split(' ');
        if (matchType === MatchType.ANY) {
          return searchTermArray.some((el) => {
            return text.includes(el);
          });
        } else {
          return searchTermArray.every((el) => {
            return text.includes(el);
          });
        }
      },
      [matchType, searchTerm],
    );

    const getAllMatchingTags = useCallback(
      (system: SubSystem): Tag[] => {
        if (searchTerm.length === 0) {
          return system.tags || [];
        }
        let matchingTags: Tag[] = [];
        if (system.tags) {
          matchingTags = system.tags.filter((tag) => {
            const tagNameDescription =
              `${tag?.tag_display_name} ${tag?.description} ${tag?.uom}`.toLowerCase();
            return matchesSearch(tagNameDescription);
          });
        }
        if (system.subfolders) {
          system.subfolders.forEach((subfolder) => {
            matchingTags = [...matchingTags, ...getAllMatchingTags(subfolder)];
          });
        }
        return matchingTags;
      },
      [searchTerm.length, matchesSearch],
    );

    const getAllSubSystemIds = useCallback((system: SubSystem): number[] => {
      let allSubSystemIds: number[] = [system.folder];
      if (system.subfolders) {
        system.subfolders.forEach((subfolder) => {
          allSubSystemIds = [...allSubSystemIds, ...getAllSubSystemIds(subfolder)];
        });
      }
      return allSubSystemIds;
    }, []);

    const findSubSystemById = useCallback((systems: SubSystem[], id: number): SubSystem | null => {
      for (const system of systems) {
        if (system.folder === id) {
          return system;
        }
        if (system.subfolders) {
          const found = findSubSystemById(system.subfolders, id);
          if (found) {
            return found;
          }
        }
      }
      return null;
    }, []);

    const handleSystemSelect = useCallback(
      (system: SubSystem, isSelected: boolean) => {
        const matchingTags = getAllMatchingTags(system);
        const allSubSystemIds = getAllSubSystemIds(system);

        setSelectedTags((prevState) => {
          const newSelectedTags = { ...prevState };
          if (isSelected) {
            delete newSelectedTags[system.folder];
            allSubSystemIds.forEach((subSystemId) => delete newSelectedTags[subSystemId]);
          } else {
            newSelectedTags[system.folder] = matchingTags;
            allSubSystemIds.forEach((subSystemId) => {
              newSelectedTags[subSystemId] = getAllMatchingTags(
                findSubSystemById(folders, subSystemId) as SubSystem,
              );
            });
          }
          return newSelectedTags;
        });

        setSelectedSystems((prevState) => {
          const newSelectedSystems = new Set(prevState);
          if (isSelected) {
            newSelectedSystems.delete(system.folder);
            allSubSystemIds.forEach((subSystemId) => newSelectedSystems.delete(subSystemId));
          } else {
            newSelectedSystems.add(system.folder);
            allSubSystemIds.forEach((subSystemId) => newSelectedSystems.add(subSystemId));
          }
          return newSelectedSystems;
        });
      },
      [getAllMatchingTags, getAllSubSystemIds, findSubSystemById, folders],
    );

    useEffect(() => {
      if (!multiselect) return;
      const selectedSystemsArray = Array.from(selectedSystems)
        .map((id) => findSubSystemById(folders, id))
        .filter((system): system is SubSystem => system !== null);
      onSelectTags(selectedTags, selectedSystemsArray);
    }, [selectedTags, selectedSystems, onSelectTags, folders, findSubSystemById, multiselect]);

    const renderTree = useCallback(
      (item: { folder: SubSystem; level: number; visible: boolean }) => {
        const { folder, level, visible } = item;
        if (!visible) return null;

        const matchingTags = getAllMatchingTags(folder);

        // Check if all tags under the folder are selected
        const allTagsSelected =
          matchingTags.length > 0 &&
          matchingTags.every((tag) =>
            (selectedTags[folder.folder] || []).some(
              (selectedTag) => selectedTag.name === tag.name,
            ),
          );

        return (
          <System key={folder.folder} level={level}>
            <TreeNode
              indent={level}
              onClick={() => {
                if (systemSelectable) {
                  handleTagClick(folder, undefined);
                  return;
                }
                handleToggle(folder.folder);
              }}
              className={`${
                defaultSelectedTags &&
                defaultSelectedTags[folder.folder] &&
                defaultSelectedTags[folder.folder].length === 0
                  ? 'selected'
                  : ''
              }`}
            >
              {level && multiselect ? (
                <Checkbox
                  checked={allTagsSelected}
                  onChange={() => handleSystemSelect(folder, allTagsSelected)}
                />
              ) : null}
              <span
                style={{
                  marginLeft: '5px',
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {collapsed[folder.folder] ? '▸' : '▾'}{' '}
                <Highlighter
                  className="folder_name"
                  searchWords={searchTerm.trim().split(' ')}
                  textToHighlight={folder?.infra_display_name}
                  autoEscape={true}
                />
              </span>
            </TreeNode>
            {!collapsed[folder.folder] && (
              <TagNodes>
                {matchingTags &&
                  matchingTags.length > 0 &&
                  matchingTags.map((tag, index) => (
                    <div
                      className={`tagNode ${
                        defaultSelectedTags[folder.folder] &&
                        defaultSelectedTags[folder.folder][0] &&
                        defaultSelectedTags[folder.folder][0].name === tag.name
                          ? 'selected'
                          : ''
                      }`}
                      key={index}
                      onClick={(e) => {
                        if (!multiselect) {
                          e.stopPropagation();
                          handleTagClick(folder, tag);
                        }
                      }}
                    >
                      {multiselect && (
                        <Checkbox
                          checked={(selectedTags[folder.folder] || []).some(
                            (selectedTag) => selectedTag.name === tag.name,
                          )}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleTagSelect(folder.folder, tag);
                          }}
                        />
                      )}
                      <>
                        {searchTerm.length ? (
                          <>
                            <div
                              className="name"
                              data-tip={tag.name}
                              data-for="tag-name-description"
                              data-testid="system-name-search"
                            >
                              <Highlighter
                                searchWords={searchTerm.trim().split(' ')}
                                textToHighlight={tag?.tag_display_name}
                                autoEscape={true}
                              />
                            </div>
                            <div data-tip={tag.description || ''} data-for="tag-name-description">
                              <Highlighter
                                className="description"
                                searchWords={searchTerm.trim().split(' ')}
                                textToHighlight={
                                  `${tag.description} ${tag.uom !== '' ? `(${tag.uom})` : ''}` || ''
                                }
                                autoEscape={true}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="name">{tag?.tag_display_name}</div>
                            <div className="description">
                              {`${tag?.description} ${tag?.uom !== '' ? `(${tag?.uom})` : ''}` ||
                                ''}
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  ))}
              </TagNodes>
            )}
          </System>
        );
      },
      [
        collapsed,
        defaultSelectedTags,
        getAllMatchingTags,
        handleSystemSelect,
        handleTagClick,
        handleTagSelect,
        handleToggle,
        multiselect,
        searchTerm,
        selectedTags,
        systemSelectable,
      ],
    );

    return (
      <StyledVirtuoso
        className="custom-scroll"
        style={{ width: '100%', fontSize: '11px', overflow: 'auto' }} // Adjust height as needed
        totalCount={flattenedFolders.length}
        itemContent={(index) => renderTree(flattenedFolders[index])}
      />
    );
  },
);

export default FolderTree;
