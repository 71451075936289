export enum Role {
  TROUBLESHOOT = 'TROUBLESHOOT',
  OBSERVER = 'OBSERVER',
  EXEC = 'EXEC',
  DATA = 'DATA',
  ACCOUNT = 'ACCOUNT',
  PAYMENT = 'PAYMENT',
  ALERT = 'ALERT',
  ALERTS_V2 = 'ALERTS_V2',
  ALERT_DASHBOARD_V2 = 'ALERT_DASHBOARD',
  ALERT_MANAGER_V2 = 'ALERT_MANAGER_V2',
  GLOBAL_ALERT_VIEWER = 'GLOBAL_ALERT_VIEWER',
  GLOBAL_ALERT_EDITOR = 'GLOBAL_ALERT_EDITOR',
  GLOBAL_LABEL_VIEWER = 'GLOBAL LABEL VIEWER',
  GLOBAL_LABEL_EDITOR = 'GLOBAL LABEL EDITOR',
  TEAMS_ADMIN = 'TEAMS_ADMIN',
  TAG_SEARCH = 'TAG_SEARCH',
  TAG_ADVANCED_SEARCH = 'TAG_ADVANCED_SEARCH',
  TAG_SECONDARY_SEARCH = 'TAG_SECONDARY_SEARCH',
  AUTO_LABEL_VIEWER = 'AUTO_LABEL_VIEWER',
  CACHE_CLEAR = 'CACHE_CLEAR',
  RESAMPLED_RAW = 'RESAMPLED_RAW',
}
