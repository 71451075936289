import dayjs from 'dayjs';
import { memo, useContext, useMemo } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';

import { Loading } from '../../../app/components/loading/loading';
import { Role } from '../../../app/constants/auth';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useGetAlertsForBoard } from '../../../app/hooks/alerts';
import { useAlertBoardContext } from '../../context/alert-board-context';
import { AlertMode, useAlertContext } from '../../context/alert-v2-context';
import { useTenants } from '../../hooks/tenants';

import { AlertTypes } from './alert-board';
import { AlertTicket } from './alert-ticket';
import { AlertTicketDetail } from './alert-ticket-detail';
import {
  AlertHeader,
  BottomFrame,
  BottomGrid,
  Grid,
  GridContainer,
  GridItem,
  ViewAlertContainer,
} from './style';

import { Button, Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

const SHOW_ALERT_DURATION = 62;

export const AlertList = memo(({ boardId }: { boardId: string }) => {
  const { handleModeChange } = useAlertContext();
  const { hasRole } = useContext(AuthorizationContext);
  const { currentTenant } = useTenants();
  const { showLog, setShowLog } = useAlertBoardContext();
  const { data: _boardAlerts, isLoading } = useGetAlertsForBoard(boardId);
  const twoDaysBefore = dayjs().subtract(SHOW_ALERT_DURATION, 'day');
  const boardAlerts = useMemo(
    () =>
      _boardAlerts?.alerts.filter(
        (alert) =>
          alert.alert_status === AlertTypes.ALERTED &&
          dayjs.utc(alert?.alert_time).tz(currentTenant?.timezone).isAfter(twoDaysBefore),
      ) || [],
    [_boardAlerts?.alerts, currentTenant?.timezone, twoDaysBefore],
  );

  const mutedAlerts = useMemo(
    () => boardAlerts?.filter((alert) => alert?.rule_info?.muted),
    [boardAlerts],
  );

  const handleManageAlert = () => {
    handleModeChange(AlertMode.MANAGER);
  };

  return (
    <>
      <ViewAlertContainer>
        <AlertHeader>
          <div
            className="close-log"
            onClick={() => {
              setShowLog(!showLog);
            }}
          >
            {showLog ? (
              <Icon name={ICONS.ArrowRight} style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <Icon name={ICONS.ArrowRight} />
            )}
            {showLog ? 'Close log' : 'Open log'}
          </div>
          <div className="board-name">{_boardAlerts?.rule_board_name}</div>
          <div>
            {hasRole(Role.ALERT_MANAGER_V2) && (
              <Button
                buttonSize="small"
                buttonType="text"
                iconName={ICONS.Gear}
                className="manage-alert"
                onClick={() => {
                  handleManageAlert();
                }}
              />
            )}
          </div>
        </AlertHeader>
        {isLoading ? (
          <Loading overlay small={true} />
        ) : (
          <>
            {/* Show count of active alerts */}
            <div className="active-count">
              {`${
                boardAlerts?.filter((alert) => alert.alert_status === AlertTypes.ALERTED).length ||
                0
              } Active Alerts`}
            </div>
            {/* Show alert tickets */}
            <Grid>
              <VirtuosoGrid
                className="alert-log-virtuoso"
                style={{ height: 'calc(100vh - 225px)', width: '100%' }}
                totalCount={boardAlerts?.length || 0}
                overscan={10}
                components={{
                  List: GridContainer, // Wraps the entire grid
                  Item: GridItem, // Wraps each item inside the grid
                }}
                itemContent={(index) => {
                  if (!boardAlerts.length) return null;
                  const alertData = boardAlerts?.[index];
                  return (
                    <AlertTicketDetail
                      type={alertData?.alert_condition.type}
                      alertLog={alertData}
                      key={index}
                    />
                  );
                }}
              />
            </Grid>
            {/* Show muted alerts */}
            {boardAlerts?.length ? (
              <BottomFrame>
                <div className="count">
                  <Icon name={ICONS.Mute} />
                  <span className="value">{`${mutedAlerts.length || 0} Muted`}</span>
                </div>
                <BottomGrid className="marquee">
                  {mutedAlerts.map((alert) => (
                    <AlertTicket
                      alertLog={alert}
                      type={alert?.alert_condition.type}
                      status={alert?.alert_status}
                      key={alert.alert_id}
                    />
                  ))}
                </BottomGrid>
              </BottomFrame>
            ) : (
              ''
            )}
          </>
        )}
      </ViewAlertContainer>
    </>
  );
});
