import React, { Suspense, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useQueryClient } from 'react-query';
import { generatePath, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

// Use React.lazy to lazily load components
const LazyMonitorV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.MonitorV2 })),
);
const LazyAnalyzeV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.AnalyzeV2 })),
);
const LazyAlertsV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.AlertV2Page })),
);
const LazyInvestigate = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.Investigate })),
);
const LazyShareV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.ShareV2 })),
);
const LazyShareCollection = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.ShareCollection })),
);
const LazyAlertViewV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.AlertViewV2 })),
);
const LazyLimitsV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.LimitsV2 })),
);

import { Role } from '../../../app/constants/auth';
import { Paths } from '../../../app/constants/paths';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useTenant } from '../../../app/context/tenant-context';
import { useAppContext } from '../../context/app-context';
import { useLayoutContext } from '../../context/layout-context';
import { ViewContextProvider } from '../../context/view-context';
import AlertPage from '../../pages/alert/alert';
import { Loading } from '../loading/loading';

import { LayoutV2 } from './LayoutV2';
import { ViewTab } from './ViewTab';

import { ViewType } from '@controlrooms/models';

const PanelLayout: React.FC = () => {
  const { setViewIds, setSavedViewIds, setActiveModes, setActiveView, setViewCollection } =
    useLayoutContext();
  const { tenant } = useTenant();
  const queryClient = useQueryClient();
  const { hasRole } = useContext(AuthorizationContext);
  const isAlertV2Visible = hasRole(Role.ALERT_DASHBOARD_V2) || hasRole(Role.ALERT_MANAGER_V2);

  useEffect(() => {
    const store = sessionStorage.getItem('tenant');
    if (!!store && Number(store) !== Number(tenant)) {
      setViewIds(['default']);
      setSavedViewIds([]);
      setActiveModes({ default: ViewType.MONITOR });
      setActiveView('default');
      setViewCollection({
        name: '',
        viewsIds: [],
        views: [],
      });
    } else {
      sessionStorage.setItem('tenant', tenant.toString());
    }
  }, [
    queryClient,
    setActiveModes,
    setActiveView,
    setSavedViewIds,
    setViewCollection,
    setViewIds,
    tenant,
  ]);

  return (
    <Routes>
      <Route
        index
        element={
          <LayoutV2>
            <Views />
          </LayoutV2>
        }
      />
      <Route
        path={'/analyze'}
        element={
          <Suspense fallback={<Loading overlay small={true} />}>
            <LayoutV2>
              <LazyAlertViewV2 />
            </LayoutV2>
          </Suspense>
        }
      />
      <Route
        path={'/alerts'}
        element={
          <Suspense fallback={<Loading overlay small={true} />}>
            <LayoutV2>
              <AlertPage />
            </LayoutV2>
          </Suspense>
        }
      />
      {isAlertV2Visible && (
        <Route
          path={'/alerts-v2'}
          element={
            <Suspense fallback={<Loading overlay small={true} />}>
              <LazyAlertsV2 />
            </Suspense>
          }
        />
      )}
      <Route
        path={'/limits'}
        element={
          <Suspense fallback={<Loading overlay small={true} />}>
            <LayoutV2>
              <LazyLimitsV2 />
            </LayoutV2>
          </Suspense>
        }
      />
      <Route
        path={'/c/:hash'}
        element={
          <Suspense fallback={<Loading overlay small={true} />}>
            <LayoutV2>
              <LazyShareCollection />
            </LayoutV2>
          </Suspense>
        }
      />
      <Route
        path={'/:hash'}
        element={
          <Suspense fallback={<Loading overlay small={true} />}>
            <LayoutV2>
              <LazyShareV2 />
            </LayoutV2>
          </Suspense>
        }
      />
    </Routes>
  );
};

const Views = () => {
  const { viewIds } = useLayoutContext();
  const { state } = useLocation();
  return (
    <>
      {viewIds.map((viewId) => (
        <React.Fragment key={viewId}>
          {state?.viewType === 'ALERT_LINK' && state?.referenceId === viewId ? (
            <ViewContextProvider
              viewId={viewId}
              tempNode={{
                viewType: 'ALERT_LINK',
                referenceId: state.referenceId,
                state: {
                  selectedFolders: state.state.selectedFolders || [],
                },
              }}
            >
              <Suspense fallback={<Loading overlay small={true} />}>
                <ViewContentArea viewId={viewId} />
              </Suspense>
            </ViewContextProvider>
          ) : (
            <ViewContextProvider viewId={viewId}>
              <Suspense fallback={<Loading overlay small={true} />}>
                <ViewContentArea viewId={viewId} />
              </Suspense>
            </ViewContextProvider>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const ViewContentArea = ({ viewId }: { viewId: string }) => {
  const { activeModes, setActiveModes, activeView } = useLayoutContext();
  const { state } = useLocation();
  const { isTenantSwitching } = useAppContext();
  const navigate = useNavigate();
  const { tenant } = useTenant();

  const [tabListElement, setTabListElement] = useState<HTMLElement | null>(null);
  const [defaultTabListElement, setDefaultTabListElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (state?.viewType === 'ALERT_LINK') {
      setActiveModes((prev) => {
        return {
          ...prev,
          [activeView]:
            state.viewType === 'ALERT_LINK' ? ViewType.ANALYZE : (state.viewMode as ViewType),
        };
      });

      // Clear the state from current location
      navigate(generatePath(Paths.VIEWS, { tenantId: tenant }), {
        replace: true,
      });
    }
  }, [state, activeView, setActiveModes, navigate, tenant]);

  // Effect to find the target for the portal after the component has mounted
  useEffect(() => {
    const tabList = document.getElementById('tab-container');
    const defaultTabList = document.getElementById('default-tab-container');
    if (tabList && defaultTabList) {
      setTabListElement(tabList);
      setDefaultTabListElement(defaultTabList);
    }
  }, []);

  return (
    <div
      id={viewId}
      key={viewId}
      style={{
        left: 0,
        right: 0,
        height: '100%',
        position: 'absolute',
        flexGrow: 1,
        display: 'flex',
        zIndex: viewId === activeView ? 1 : -1,
        visibility: viewId === activeView ? 'visible' : 'visible',
      }}
    >
      {/* <Sidebar /> */}
      {tabListElement &&
        viewId !== 'default' &&
        ReactDOM.createPortal(<ViewTab view_id={viewId} />, tabListElement)}
      {defaultTabListElement &&
        viewId === 'default' &&
        ReactDOM.createPortal(<ViewTab view_id={viewId} />, defaultTabListElement)}
      {isTenantSwitching && <Loading overlay small={true} />}
      {!isTenantSwitching && (
        <>
          {activeModes[viewId] === ViewType.MONITOR && (
            <Suspense fallback={<Loading overlay small={true} />}>
              <LazyMonitorV2 />
            </Suspense>
          )}
          {activeModes[viewId] === ViewType.ANALYZE && (
            <Suspense fallback={<Loading overlay small={true} />}>
              <LazyAnalyzeV2 />
            </Suspense>
          )}
          {activeModes[viewId] === ViewType.INVESTIGATE && (
            <Suspense fallback={<Loading overlay small={true} />}>
              <LazyInvestigate />
            </Suspense>
          )}
        </>
      )}
    </div>
  );
};

export default PanelLayout;
