import { AlertTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const alertDarkTheme: AlertTheme = {
  backgroundColor: colors.k[5],
  alertBackgroundColor: colors.container['b-g-8'],
  alertSecondaryBgColor: colors.container['b-g-8'],
  buttonTextColor: colors.k[100],
  search: {
    inputBorder: colors.k[12],
    inputBackground: colors.k[5],
    boxShadow: 'rgba(0, 0, 0, 0.35) inset',
    searchIcon: colors.focus.dark[4],
  },
  select: {
    borderColor: colors.content['neutral-5'],
    backgroundColor: colors.button.dark['ui-button-2'],
    dropdownTextColor: colors.content['neutral-2'],
  },
  table: {
    targetNameBg: colors.k[12],
    targetNameText: colors.k[65],
  },
  content: {
    text: colors.content['neutral-1'],
    tableHeader: colors.content['neutral-2'],
    inactive: colors.content['neutral-2'],
  },
  modal: {
    textColor: colors.content['neutral-1'],
    secondaryTextColor: colors.content['neutral-2'],
    subTextColor: colors.content['neutral-3'],
    titleText: colors.content['neutral-6'],
    titleCardBG: colors.container['b-g-1'],
    durationTitleColor: colors.content['neutral-2'],
    tooltipBackground: '#F0EEEB',
    tooltipText: colors.content['neutral-6'],
    sectionBorderColor: colors.content['neutral-5'],
    buttonBorderColor: colors.content['neutral-5'],
    actionButtonBgColor: colors.button.dark['ui-button-2'],
    inputInfo: colors.k[40],
    buttonTextColor: colors.content['neutral-2'],
    tagNameColor: colors.k[20],
    infoBorder: colors.datavis['orange-1'],
    infoBackground: colors.container['b-g-9'],
    infoIcon: colors.k[100],
    errorBorder: colors.datavis['pink-2'],
    errorBackground: colors.error[1],
    errorIcon: colors.k[100],
    tabText: colors.content['neutral-2'],
    tabActiveText: colors.content['neutral-1'],
    tabBorder: colors.content['neutral-3'],
    tabActiveBorder: colors.focus.dark[2],
    recipientSelectionBorder: colors.k[65],
    recipientSelectionBg: 'transparent',
    ensembleFilterColor: colors.content['neutral-1'],
    emailChipBg: 'linear-gradient(180deg,#222320 0%,#191A18 100%)',
  },
  channelPicker: {
    suggestedColor: colors.content['neutral-3'],
    textColor: colors.content['neutral-1'],
    menuBackground: colors.container['b-g-8'],
    selectedChannelBg: 'linear-gradient(180deg, #222320 0%, #191A18 100%)',
  },
  alertTicket: {
    background: colors.k[8],
    header: colors.k[85],
    detail: colors.k[65],
    timeDuration: colors.k[55],
    border: '',
    archived: {
      anomalyTimerBorder: 'rgba(31, 48, 51, 0.5)',
      limitTimerBorder: 'rgba(255, 113, 113, 0.1)',
    },
    alerted: {
      timerColor: 'rgba(31, 48, 51, 0.5)',
      alertTimerColor: colors.k[100],
      anomalyBG: 'linear-gradient(180deg, #0c1315 0%, #0d0d0d 100%)',
      limitBG: 'linear-gradient(180deg, #160a0a 0%, #0d0d0d 100%)',
    },
  },
  alertTicketDetail: {
    background: 'linear-gradient(180deg, #0c1315 0%, #0d0d0d 100%);',
    anomalyBackground: 'linear-gradient(180deg, #160a0a 0%, #0d0d0d 100%)',
    anomalyTimerBackground: '#2b3a3d',
    limitTimerBackground: 'var(--data-vis-pink-130, rgba(255, 113, 113, 0.3))',
    header: colors.k[85],
    anomalyName: '#c1eff9',
    limitName: '#ffb1b1',
    detail: colors.k[65],
    timerText: '#fff',
    tagDescription: '#b3b3b3',
    actionBG: 'rgba(8, 23, 26, 0.7)',
    actionIconColor: '#d9d9d9',
  },
};

export const alertLightTheme: AlertTheme = {
  backgroundColor: colors.container['b-g-3'],
  alertBackgroundColor: colors.container['b-g-2'],
  alertSecondaryBgColor: '#f2f1ee',
  buttonTextColor: colors.k[100],
  search: {
    inputBorder: colors.k[65],
    inputBackground: colors.container['b-g-1'],
    boxShadow: 'rgba(0, 0, 0, 0.15) inset',
    searchIcon: colors.focus.dark[2],
  },
  select: {
    borderColor: colors.container['b-g-5'],
    backgroundColor: colors.container['b-g-1'],
    dropdownTextColor: colors.content['neutral-5'],
  },
  table: {
    targetNameBg: colors.container['b-g-3'],
    targetNameText: colors.k[20],
  },
  content: {
    text: colors.content['neutral-6'],
    tableHeader: colors.content['neutral-4'],
    inactive: colors.content['neutral-4'],
  },
  modal: {
    textColor: colors.content['neutral-6'],
    secondaryTextColor: colors.content['neutral-4'],
    subTextColor: colors.k[5],
    titleText: colors.content['neutral-6'],
    titleCardBG: colors.container['b-g-1'],
    durationTitleColor: colors.content['neutral-4'],
    tooltipBackground: '#F0EEEB',
    tooltipText: colors.content['neutral-6'],
    sectionBorderColor: colors.container['b-g-3'],
    buttonBorderColor: colors.container['b-g-5'],
    actionButtonBgColor: colors.container['b-g-1'],
    inputInfo: colors.k[40],
    buttonTextColor: colors.content['neutral-5'],
    tagNameColor: colors.k[20],
    infoBorder: colors.datavis['orange-1'],
    infoBackground: colors.container['b-g-10'],
    infoIcon: colors.error[1],
    errorBorder: colors.datavis['pink-2'],
    errorBackground: '#FFE5E5',
    errorIcon: colors.error[1],
    tabText: colors.content['neutral-5'],
    tabActiveText: colors.content['neutral-6'],
    tabBorder: colors.content['neutral-4'],
    tabActiveBorder: colors.focus.light[2],
    recipientSelectionBorder: colors.k[65],
    recipientSelectionBg: colors.container['b-g-1'],
    ensembleFilterColor: colors.content['neutral-5'],
    emailChipBg: 'linear-gradient(180deg,#f9f8f4 0%,#e3edef 75%,#e2ebed 93.75%,#b7d4da 100%)',
  },
  channelPicker: {
    suggestedColor: colors.k[40],
    textColor: colors.content['neutral-5'],
    menuBackground: '#F0EEEB',
    selectedChannelBg:
      'linear-gradient(180deg, #f9f8f4 0%, #e3edef 75%, #e2ebed 93.75%, #b7d4da 100%)',
  },
  alertTicket: {
    background: colors.container['b-g-1'],
    header: colors.k[30],
    detail: colors.k[30],
    timeDuration: colors.k[30],
    border: '1px solid #cbc8c4',
    archived: {
      anomalyTimerBorder: 'rgba(31, 48, 51, 0.5)',
      limitTimerBorder: 'rgba(255, 113, 113, 0.1)',
    },
    alerted: {
      timerColor: 'rgba(31, 48, 51, 0.15)',
      alertTimerColor: colors.k[0],
      anomalyBG: colors.container['b-g-1'],
      limitBG: colors.container['b-g-1'],
    },
  },
  alertTicketDetail: {
    background: colors.container['b-g-1'],
    anomalyBackground: colors.container['b-g-1'],
    anomalyTimerBackground: '#CFDEDF',
    limitTimerBackground: '#FFB1B1',
    header: colors.k[30],
    anomalyName: '#2b3a3d',
    limitName: '#EF3E3E',
    detail: colors.k[65],
    timerText: '#4D4D4D',
    tagDescription: '#4D4D4D',
    actionBG: colors.k[100],
    actionIconColor: colors.k[0],
  },
};
