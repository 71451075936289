import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@controlrooms/components';

// Styled component for the sidebar
export const StyledSidebar = styled.div`
  height: 100vh;
  width: 50px;
  background: ${({ theme }) => theme.v2.header.background};
  z-index: 1;
  -webkit-box-pack: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  justify-content: space-between;
`;

// Styled component for the logo and footer
export const Logo = styled.div`
  color: white;
  height: 50px;
  font-size: 24px;
  background: #244649;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  min-width: 50px;
  max-width: 50px;
  display: flex;
  img {
    width: 100%;
  }
`;

export const Profile = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(
    --Heatmap-Dark-01-Gradient,
    linear-gradient(0deg, #202c2e 0%, #273133 23.18%, #303d40 100%)
  );
  padding: 20px;
`;
export const Invite = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: transparent;
  border: none;
  margin: 20px 0px;
  padding: 10px;
  svg {
    path {
      fill: #93bbc4;
    }
  }
`;

export const Nav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

// Styled component for the menu
export const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TooltipText = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: rgba(1, 1, 1, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 55px;
  font-size: 12px;
`;

// Styled component for the menu items
export const MenuItem = styled.div`
  position: relative;
  color: white;
  width: 50px;
  padding: 15px 0;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: rgba(1, 1, 1, 0.5); // Adjust hover color as needed
    border-right: 2px solid #c1eff9;
  }
  &.active {
    border-right: 2px solid #c1eff9;
  }
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;

export const NavMenuItem = styled(NavLink)`
  color: white;
  padding: 15px 0;
  width: 50px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid transparent;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: rgba(1, 1, 1, 0.5); // Adjust hover color as needed
    border-right: 2px solid #c1eff9;
  }
  &.active {
    border-right: 2px solid #c1eff9;
  }
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;

// Styled component for the icon
export const Icon = styled.div`
  font-size: 24px; // Adjust the size as needed
`;

// Optional: styled component for the tooltip text

// Modified MenuItem to include tooltip text
export const MenuItemWithTooltip = styled(MenuItem)`
  position: relative;
  &:hover ${TooltipText} {
    visibility: visible;
  }
`;
