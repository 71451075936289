import dayjs from 'dayjs';
import React from 'react';

import { useMultiTagMinMax } from '../../../app/hooks/tags';
import { useTenants } from '../../hooks/tenants';

import { AlertActions } from './alert-actions';
import { AlertTypes } from './alert-board';
import { AlertTicketDetailWrapper, TagList } from './alert-ticket-detail-style';
import LineChart from './charts/LineLimits';
import { ChartType } from './charts/chart-utils';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { AlertV2Type, AnomalyRange, CRTagData, Mode, TimeSeries } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

interface AlertTicketDetailProps {
  alertLog?: AlertV2Type;
  type?: string;
  status?: string;
}

export const AlertTicketDetail = ({
  alertLog,
  type = 'limit',
  status = AlertTypes.ALERTED,
}: AlertTicketDetailProps) => {
  const { currentTenant } = useTenants();
  const {
    data: tagData,
    isLoading: isLoadingTagData,
    isError: isErrorTagData,
  } = useMultiTagMinMax(
    {
      ...{
        startTime: dayjs.utc(alertLog?.generate_chart_time).tz(currentTenant?.timezone),
        endTime: dayjs.utc(alertLog?.alert_time).tz(currentTenant?.timezone), //TODO: Make that now
      },
      folder: Number(alertLog?.alert_condition?.data?.tags[0]?.folder_id),
      tag: [...new Set(alertLog?.alert_condition?.data?.tags.map((t) => t.tag_name))],
    },
    true,
    alertLog?.rule_info?.ensemble_family_id,
  );

  let allTags: CRTagData<TimeSeries, Mode, AnomalyRange>[] = [];

  if (tagData) {
    allTags = [...tagData];
  }

  return (
    <AlertTicketDetailWrapper type={type} status={status}>
      <div className="head">
        <div className="id">
          <div className="header-system">
            {alertLog?.rule_info.target_path.map((path, index) => {
              if (index === 0) {
                return;
              }
              return (
                <React.Fragment key={index}>
                  <span className="header" key={index}>
                    <Tooltip label={path || ''}>{path}</Tooltip>
                  </span>{' '}
                  /
                </React.Fragment>
              );
            })}
            <span className="header">
              <Tooltip label={alertLog?.rule_info?.target_name || ''}>
                {alertLog?.rule_info.target_name}
              </Tooltip>
            </span>
          </div>
          {/* <div className="header-system">Refrigeration</div> */}
          {type === 'anomaly' ? (
            <div className="name">
              <Icon name={ICONS.AnomalyAlert} />
              <span className="text">Anomaly</span>
            </div>
          ) : (
            <div className="name">
              <Icon name={ICONS.LimitAlert} /> <span className="text">Limit exceedence</span>
            </div>
          )}
        </div>
        <div className="timer">
          <div className="time">
            {dayjs.utc(alertLog?.alert_time).local().format('MMM D YY HH:mm')}{' '}
            {TimeUtils.zoneFormat(currentTenant.timezone, 'z')}
          </div>
          <div className="frame">
            {status === AlertTypes.SKIPPED ? (
              <Icon name={ICONS.Mute} />
            ) : (
              <Icon name={ICONS.Stopwatch} />
            )}
            <span className="value">{alertLog?.alert_condition?.snooze_duration}m</span>
          </div>
        </div>
      </div>
      <div className="body">
        <div className="chart">
          {!isErrorTagData ? (
            <>
              {!isLoadingTagData ? (
                <LineChart
                  data={allTags}
                  config={{ type: type === 'anomaly' ? ChartType.ANOMALY : ChartType.LIMIT }}
                />
              ) : (
                'Loading...'
              )}
            </>
          ) : (
            'Error occurred'
          )}
        </div>
      </div>
      <TagList>
        {alertLog?.alert_condition?.data?.tags
          ? alertLog?.alert_condition?.data?.tags?.map((tag, key) => (
              <div className="tag" key={key}>
                <div className="name">{tag.tag_display_name}</div>
                <div className="description">{tag.tag_display_name}</div>
              </div>
            ))
          : ''}
      </TagList>
      <AlertActions alertLog={alertLog} type={type} />
    </AlertTicketDetailWrapper>
  );
};
