import { LayoutProvider } from '../../context/layout-context';
import Sidebar from '../sidebar';

import PanelLayout from './PanelLayout';

export const LayoutV2: React.FC = () => {
  return (
    <>
      <LayoutProvider>
        <Sidebar />
        <PanelLayout />
      </LayoutProvider>
    </>
  );
};
