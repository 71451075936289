import { KnobTheme } from 'styled-components';

import { colors } from '@controlrooms/design-tokens';

export const knobDarkTheme: KnobTheme = {
  backgroundColor: colors.button.dark['ui-button-1'],
  knobBorderColor: colors.focus.dark[5],
  knobDialBackground: `radial-gradient(#293536, #212b2c)`,
  knobDialhandle: colors.content['neutral-1'],
  valueLabelColor: colors.content['neutral-4'],
  valueColor: colors.content['neutral-1'],
};

export const knobLightTheme: KnobTheme = {
  backgroundColor: colors.button.light['ui-button-1'],
  knobBorderColor: colors.focus.dark[2],
  knobDialBackground: `#E5E3E1`,
  knobDialhandle: colors.content['neutral-1'],
  valueLabelColor: colors.content['neutral-4'],
  valueColor: colors.content['neutral-6'],
};
