import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import {
  ChartType,
  drawAnomalyLines,
  drawAreaChart,
  drawAxes,
  drawDataLimitLine,
  drawDataLine,
  drawXAxisTicks,
  useResizeObserver,
} from './chart-utils';

import { AnomalyRange, CRTagData, Mode, TimeSeries } from '@controlrooms/models';

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  canvas {
    width: 100%;
    height: 100%;
  }
`;

// Tooltip styling
const Tooltip = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  pointer-events: none;
  transform: translate(-50%, -100%);
`;

interface LineChartProps {
  data: CRTagData<TimeSeries, Mode, AnomalyRange>[];
  config: { type: ChartType };
}

const LineChart: React.FC<LineChartProps> = ({ data, config }) => {
  const SHOW_TOOLTIP = false;
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useResizeObserver(containerRef);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const theme = useTheme();
  const [tooltip, setTooltip] = useState<{ x: number; y: number; text: string } | null>(null);
  const [hoveredPoint, setHoveredPoint] = useState<{ x: number; y: number } | null>(null);
  const margin = { top: 10, right: 10, bottom: 20, left: 10 };

  // Render based on config changes
  const { type } = config;

  // Main drawing effect
  useEffect(() => {
    if (!dimensions || !canvasRef.current || !data.length) return;
    const { width, height } = dimensions;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const dpr = window.devicePixelRatio || 1;
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    ctx.scale(dpr, dpr);
    ctx.clearRect(0, 0, width, height);
    ctx.save();
    ctx.translate(margin.left, margin.top);

    // Combine timeseries for x-domain.
    const allTimeseries = data.reduce(
      (acc, series) => acc.concat(series.timeseries),
      [] as { time: Date | number; value: number; min?: number; max?: number }[],
    );
    if (!allTimeseries.length) {
      ctx.restore();
      return;
    }
    const xExtent = d3.extent(allTimeseries, (d) => d.time) as [Date | number, Date | number];

    // Gather all y-values from timeseries (value, min, max), anomalies, and limit values.
    const allYValues: number[] = [];
    data.forEach((series) => {
      series.timeseries.forEach((d) => {
        allYValues.push(d.value);
        if (d.min !== undefined) allYValues.push(d.min);
        if (d.max !== undefined) allYValues.push(d.max);
      });
      if (series.anomalies) {
        series.anomalies.forEach((anomaly) => {
          if (anomaly.yScale) {
            anomaly.yScale.forEach((point) => allYValues.push(point.value));
          }
        });
      }
      if (series.highHighs) series.highHighs.forEach((mode) => allYValues.push(mode.value));
      if (series.highs) series.highs.forEach((mode) => allYValues.push(mode.value));
      if (series.lowLows) series.lowLows.forEach((mode) => allYValues.push(mode.value));
      if (series.lows) series.lows.forEach((mode) => allYValues.push(mode.value));
    });
    const yMinCombined = d3.min(allYValues);
    const yMaxCombined = d3.max(allYValues);
    if (yMinCombined == null || yMaxCombined == null) {
      ctx.restore();
      return;
    }
    const yRange = yMaxCombined - yMinCombined;
    const yPadding = yRange * 0.15;
    const yDomain: [number, number] = [yMinCombined - yPadding, yMaxCombined + yPadding];

    const xScale = d3.scaleTime().domain(xExtent).range([0, innerWidth]);
    const yScale = d3.scaleLinear().domain(yDomain).range([innerHeight, 0]);

    data.forEach((series) => {
      if (
        series.timeseries.length > 0 &&
        series.timeseries.every((d) => d.min !== undefined && d.max !== undefined)
      ) {
        const thresholds = {
          highHigh: series.highHighs && series.highHighs.length ? series.highHighs : [],
          high: series.highs && series.highs.length ? series.highs : [],
          low: series.lows && series.lows.length ? series.lows : [],
          lowLow: series.lowLows && series.lowLows.length ? series.lowLows : [],
        };

        drawAreaChart(
          ctx,
          series.timeseries as TimeSeries[],
          xScale,
          yScale,
          thresholds,
          type,
          theme,
        );
      }
      if (type === ChartType.LIMIT) {
        drawDataLimitLine(
          ctx,
          series.timeseries as TimeSeries[],
          xScale,
          yScale,
          {
            high: series.highs,
            highHigh: series.highHighs,
            low: series.lows,
            lowLow: series.lowLows,
          },
          theme,
        );
      } else {
        drawDataLine(ctx, series.timeseries, xScale, yScale, theme);
        drawAnomalyLines(ctx, series.anomalies, xScale, yScale, theme);
      }
      // drawLimitLines(
      //   ctx,
      //   {
      //     highHighs: series.highHighs,
      //     highs: series.highs,
      //     lowLows: series.lowLows,
      //     lows: series.lows,
      //   },
      //   xScale,
      //   yScale,
      //   innerWidth,
      // );
    });

    drawAxes(ctx, innerWidth, innerHeight, theme);
    drawXAxisTicks(ctx, xScale, innerWidth, innerHeight, theme);
    ctx.fillStyle = '#000'; //Y-axis line color
    // drawCrosshair(ctx, hoveredPoint, innerWidth, innerHeight);

    ctx.restore();
  }, [
    data,
    dimensions,
    theme,
    hoveredPoint,
    margin.left,
    margin.right,
    margin.top,
    margin.bottom,
    type,
  ]);

  // Mouse interactions: compute y-domain similarly so scales match.
  useEffect(() => {
    if (!dimensions || !canvasRef.current || !data.length) return;
    const { width, height } = dimensions;
    const canvas = canvasRef.current;
    const dpr = window.devicePixelRatio || 1;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const allYValues: number[] = [];
    data.forEach((series) => {
      series.timeseries.forEach((d) => {
        allYValues.push(d.value);
        if (d.min !== undefined) allYValues.push(d.min);
        if (d.max !== undefined) allYValues.push(d.max);
      });
      if (series.anomalies) {
        series.anomalies.forEach((anomaly) => {
          if (anomaly.yScale) {
            anomaly.yScale.forEach((point) => allYValues.push(point.value));
          }
        });
      }
      if (series.highHighs) series.highHighs.forEach((mode) => allYValues.push(mode.value));
      if (series.highs) series.highs.forEach((mode) => allYValues.push(mode.value));
      if (series.lowLows) series.lowLows.forEach((mode) => allYValues.push(mode.value));
      if (series.lows) series.lows.forEach((mode) => allYValues.push(mode.value));
    });
    const allTimeseries = data.reduce(
      (acc, series) => acc.concat(series.timeseries),
      [] as { time: Date | number; value: number }[],
    );
    if (!allTimeseries.length) return;

    const xExtent = d3.extent(allTimeseries, (d) => d.time) as [Date | number, Date | number];
    const yMinCombined = d3.min(allYValues);
    const yMaxCombined = d3.max(allYValues);
    if (yMinCombined == null || yMaxCombined == null) return;
    const yRange = yMaxCombined - yMinCombined;
    const yPadding = yRange * 0.15;
    const yDomain: [number, number] = [yMinCombined - yPadding, yMaxCombined + yPadding];

    const xScale = d3.scaleTime().domain(xExtent).range([0, innerWidth]);
    const yScale = d3.scaleLinear().domain(yDomain).range([innerHeight, 0]);

    const handleMouseMove = (event: MouseEvent) => {
      const rect = canvas.getBoundingClientRect();
      const mouseX = (event.clientX - rect.left - margin.left) * dpr;

      const closest = allTimeseries.reduce((prev, curr) => {
        const prevDist = Math.abs(xScale(prev.time) - mouseX);
        const currDist = Math.abs(xScale(curr.time) - mouseX);
        return currDist < prevDist ? curr : prev;
      });
      const pointX = xScale(closest.time);
      const pointY = yScale(closest.value);
      if (pointX >= 0 && pointX <= innerWidth && pointY >= 0 && pointY <= innerHeight) {
        setHoveredPoint({ x: pointX, y: pointY });
        SHOW_TOOLTIP &&
          setTooltip({
            x: pointX + margin.left,
            y: pointY + margin.top,
            text: `Time: ${d3.timeFormat('%b %d, %H:%M')(
              new Date(closest.time),
            )}, Value: ${closest.value.toFixed(2)}`,
          });
      } else {
        setHoveredPoint(null);
        SHOW_TOOLTIP && setTooltip(null);
      }
    };

    const handleMouseLeave = () => {
      setHoveredPoint(null);
      SHOW_TOOLTIP && setTooltip(null);
    };

    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [data, dimensions, margin.left, margin.top, margin.right, margin.bottom, SHOW_TOOLTIP]);

  return (
    <ChartContainer ref={containerRef}>
      <canvas ref={canvasRef} />
      {SHOW_TOOLTIP && tooltip && (
        <Tooltip style={{ left: tooltip.x, top: tooltip.y - 10 }}>{tooltip.text}</Tooltip>
      )}
    </ChartContainer>
  );
};

export default LineChart;
