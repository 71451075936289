import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { EnsembleErrorBanner } from '../../../app/components/header/styles';
import { EnsembleContext } from '../../../app/context/ensemble-context';
import { useGetUserViewsV2 } from '../../../app/hooks/accounts';
import { TenantSelector } from '../header';
import { Loading } from '../loading/loading';

import Panel, { PanelContainer } from './Panel';
import { TabContainer } from './TabbedContainer';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

const ViewArea = styled.div`
  position: relative;
  flex-grow: 1;
  background: ${({ theme }) => theme.investigate.backgroundColor};
`;
export const LayoutV2 = ({ children }: { children: React.ReactNode }) => {
  const { isLoading } = useGetUserViewsV2();
  const { showEnsembleErrorBanner, setShowEnsembleErrorBanner } = useContext(EnsembleContext);
  const [searchParams] = useSearchParams();

  return (
    <PanelContainer>
      <Panel>
        <div className="top-panel" style={{ display: 'flex' }}>
          <Panel.Left>
            <div style={{ display: 'flex' }}>
              <TenantSelector />
            </div>
          </Panel.Left>
          <Panel.Resizer />
          <Panel.Right>
            <TabContainer />
          </Panel.Right>
        </div>
        {showEnsembleErrorBanner && (
          <EnsembleErrorBanner>
            <Icon className="icon" name={ICONS.Info} />
            <span data-testid="permission-info">
              {searchParams.get('reference')
                ? `This alert was evaluated by an ensemble family that you are not currently authorized to
            see. The tags from the alert shown below are populated with anomalies from your current
            live ensemble family.`
                : `This shared view was evaluated by an ensemble family that you are not currently authorized to
            see. The view shown below are populated with anomalies from your current
            live ensemble family.`}
            </span>
            <Icon
              className="close"
              name={ICONS.Close}
              onClick={() => {
                setShowEnsembleErrorBanner(false);
              }}
            />
          </EnsembleErrorBanner>
        )}
        {isLoading && (
          <ViewArea>
            <Loading overlay small={true} />
          </ViewArea>
        )}
        {!isLoading && <ViewArea className="view-area">{children}</ViewArea>}
      </Panel>
    </PanelContainer>
  );
};
