import React, { useState, useRef, useContext } from 'react';

import { EnsembleContext } from '../../../app/context/ensemble-context';
import { useEnsembleModelsQuery } from '../../../app/hooks/tags';
import { useViewContext } from '../../context/view-context';

import { BrowserFilterWrapper, HeaderContainer } from './styles';

import { Button, Icon, Li, OptionsGroup, Ul } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { Ensemble } from '@controlrooms/models';

interface FilterProps {
  options?: Ensemble[];
}

export const BrowserFilter: React.FC<FilterProps> = () => {
  const { ensembleFamilies } = useEnsembleModelsQuery();

  const {
    viewState: { selectedEnsemble: viewEnsemble },
    setViewState,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble?.family_id ? viewEnsemble : appEnsemble;
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const filtersRef = useRef(null);

  const toggleViewActions = () => setIsFiltersOpen((prevState) => !prevState);

  useClickOutside(filtersRef, () => setIsFiltersOpen(false));

  const handleOnOptionChange = (option: Ensemble) => {
    setViewState((prev) => {
      return {
        ...prev,
        isDirty: true,
        selectedEnsemble: option,
      };
    });
  };

  return (
    <BrowserFilterWrapper>
      <OptionsGroup className="filter-select">
        <Button
          data-testid="Ensemble-family-browser-filter"
          buttonSize="small"
          buttonType="menu-text"
          className="filter-title"
          onClick={toggleViewActions}
          aria-haspopup="listbox"
          aria-expanded={isFiltersOpen}
        >
          Filters
        </Button>
        <Ul isOpen={isFiltersOpen} className="filter-dropdown" ref={filtersRef}>
          <HeaderContainer>ENSEMBLE FAMILIES</HeaderContainer>
          {ensembleFamilies?.map((option) => {
            const isSelected = selectedEnsemble?.family_id === option.family_id;
            return (
              <Li
                key={option.family_id}
                className={`filter-menu-item ${!isSelected ? 'unselected' : ''}`}
                data-testid={`ensemble_family_${option.family_id}`}
                onClick={() => {
                  setIsFiltersOpen(false);
                  handleOnOptionChange(option);
                }}
              >
                {isSelected && (
                  <Icon
                    data-testid={`${option.family_id}-checkmark`}
                    name={ICONS.CheckmarkDefault}
                  />
                )}
                <span className="ensemble-family">{option.family_name}</span>
              </Li>
            );
          })}
        </Ul>
      </OptionsGroup>
    </BrowserFilterWrapper>
  );
};

export default BrowserFilter;
