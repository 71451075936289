import { useEffect, useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line
import { registerSW } from 'virtual:pwa-register';
import { Button } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

const TooltipText = styled.span`
  width: 350px;
  background-color: rgba(1, 1, 1, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 0px 3px 3px 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 60px;
  font-size: 12px;

  ::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: -5px;
    top: 5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid rgba(1, 1, 1, 0.8);
  }
`;

const InstallContainer = styled.div`
  position: relative;
  .install-button {
    width: 100%;
  }
`;

const ReloadPrompt = () => {
  const [showRefresh, setShowRefresh] = useState(false);
  // This function, returned by registerSW, can be used to force activation of the new SW.
  const [updateServiceWorker, setUpdateServiceWorker] = useState<
    ((reloadPage?: boolean) => Promise<void>) | null
  >(null);

  useEffect(() => {
    // Register the service worker immediately and set up callbacks.
    const updateSW = registerSW({
      immediate: true,
      onNeedRefresh: () => {
        // When a new service worker is waiting, show the refresh prompt.
        setShowRefresh(true);
      },
      onRegisteredSW: (swUrl, registration) => {
        console.log('Service Worker registration:', swUrl, registration);
        // Optionally handle a successful registration here.
        // For instance, you could log the SW URL or keep track of the registration.
      },
      onRegisterError: (error) => {
        console.error('Service Worker registration error:', error);
      },
    });

    // Save the function that triggers the update.
    setUpdateServiceWorker(() => updateSW);
  }, []);

  if (!showRefresh) return null;

  const handleReload = () => {
    // Force the service worker to update (skip waiting) and optionally reload the page.
    if (updateServiceWorker) {
      updateServiceWorker(true);
    }
  };

  return (
    <InstallContainer>
      <Button
        className="install-button"
        buttonType="text"
        buttonSize="large"
        iconName={ICONS.Install}
        onClick={handleReload}
      />
      <TooltipText>There is a new version of app available. Click here to update!</TooltipText>
    </InstallContainer>
  );
};

export default ReloadPrompt;
