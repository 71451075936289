import styled from 'styled-components';

import { StyledButton } from '../button';

import { customScrollPrimary } from '@controlrooms/web/src/app/global-styles';

export const Ul = styled.ul<{ isOpen: boolean; position?: string; showOnTop?: boolean }>`
  position: ${({ position }) => (position ? position : 'fixed')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 211px;
  padding: 0;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  list-style: none;
  border: 1px solid black;
  border-radius: 4px;
  z-index: 10;
  ${(props) => {
    if (!props.isOpen) {
      return `
        display: none;
      `;
    }
  }}
  ${(props) => {
    if (props.showOnTop) {
      return `
        bottom: 0;
      `;
    }
  }}
`;

export const Li = styled.li<{ isDisabled?: boolean }>`
  color: white;
  list-style: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 200;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  line-height: 17px;
  padding: 4px 0;
  padding-left: 25px;
  color: ${({ theme }) => theme.modal.panel.color};
  &:hover {
    background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};
    ${(props) => {
      if (props.isDisabled) {
        return `
        background: none;
        `;
      }
    }}
  }

  &.separator {
    border-bottom: 1px solid #a0a38d;
  }

  ${StyledButton} {
    color: inherit;
    text-align: left;
    padding: 0;
    height: initial;

    span {
      font-weight: inherit;
    }
  }
`;

export const OptionsGroup = styled.div<{ isInactive?: boolean }>`
  color: ${({ theme }) => theme.modal.panel.optionsColor};
  cursor: pointer;
  display: flex;
  font-size: 9px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  margin-bottom: 5px;
  padding: 4px 0 4px 17px;
  ${(props) => {
    if (props.isInactive) {
      return `
        opacity: 0.4;
      `;
    }
  }}

  & > button {
    width: 100%;
  }

  .scrollable-container {
    max-height: 200px;
    overflow: auto;
    ${customScrollPrimary}
  }

  .no-border {
    border: none;
    background: none;
  }

  ul {
    border-radius: 3px;
    border: none;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
    padding: 5px 0px 10px 0px;
    li {
      font-family: Open Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
    }
  }

  &.menu-select {
    ul {
      width: 151px;
    }
  }
`;
