import { AlertConfiguration } from '../../pages/alertV2/alert-configurations';
import { TARGET_TYPE } from '../../pages/alertV2/alert-manager';

import { Modal, ModalContents } from '@controlrooms/components';
import { Tag } from '@controlrooms/models';

export const AlertModalV2 = ({
  onConfirm,
  onCancel,
  alert,
}: {
  onConfirm?: (closeState: boolean) => void;
  onCancel?: () => void;
  alert: {
    alertType: TARGET_TYPE;
    systemId: number;
    tags: Tag[];
  };
}) => {
  const { alertType, systemId: folderId, tags: selectedTags } = alert;
  return (
    <Modal open={true}>
      <ModalContents
        title={'Manage alerts'}
        styles={{ content: { minWidth: '450px', width: '1000px' } }}
        closeButtonCallback={() => {
          onConfirm && onConfirm(false);
        }}
        shouldCloseOnEsc={false}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <AlertConfiguration
          alertType={alertType}
          systemId={folderId}
          tags={selectedTags}
          handleCloseForm={() => onCancel && onCancel()}
        />
      </ModalContents>
    </Modal>
  );
};
