import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useAlertsAPI, useTeamsWebhookChannels } from '../../hooks/alerts';

import { ConditionError } from './styles';

import { Button, Checkbox, Li, Switch, TextInput } from '@controlrooms/components';
import { RegisteredChannel, TeamChannel, TeamsWebhook } from '@controlrooms/models';

const TeamsWebhookSelectionWrapper = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  p {
    margin: 0px;
    margin-left: 10px;
  }
`;

const ChannelList = styled(Li)`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 0px;
  margin-bottom: 5px;
  padding: 0px;
  :hover {
    background: none;
  }
  span {
    margin-left: 5px;
    font-size: 12px;
  }
`;

const WebhookRegistration = styled.div`
  .go-back {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    color: var(--heatmap-light-02-solid, #688d92);
    cursor: pointer;
    text-decoration: underline;
  }
  .form-control {
    margin-bottom: 10px;
    input {
      height: 24px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
`;

export const TeamsWebhookWrapper = memo(function TeamsWebhookWrapper({
  selected,
  teamsChannels,
}: {
  selected: boolean | null;
  teamsChannels: TeamChannel[];
}) {
  const { data: teamsWebhookChannels, isLoading } = useTeamsWebhookChannels();
  const registeredChannels = useMemo(
    () => teamsWebhookChannels?.result || [],
    [teamsWebhookChannels?.result],
  );

  if (isLoading) return <>Loading...</>;

  return (
    <TeamsWebhookPicker
      registeredChannels={registeredChannels}
      selected={selected}
      teamsChannels={teamsChannels}
    />
  );
});

export const TeamsWebhookPicker = memo(function TeamsWebhookPicker({
  registeredChannels,
  selected,
  teamsChannels,
}: {
  selected: boolean | null;
  teamsChannels: TeamChannel[];
  registeredChannels: RegisteredChannel[];
}) {
  const [sendViaWebhook, setSendViaWebhook] = useState<boolean | null>(selected);

  const { registerTeamsWebHookMutation } = useAlertsAPI();
  const { mutateAsync: registerTeamsWebHook } = registerTeamsWebHookMutation;

  const { testTeamsWebHookMutation } = useAlertsAPI();
  const { mutateAsync: testTeamsWebHook } = testTeamsWebHookMutation;

  const {
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors: formStateError },
  } = useFormContext();

  const [selectedChannels, setSelectedChannels] = useState<
    { channel_name: string; isSelected: boolean }[]
  >([]);
  const [openRegistration, setOpenRegistration] = useState<boolean>(false);
  const [testConnectionSuccess, setTestConnectionSuccess] = useState<boolean>(false);

  const methods = useForm<TeamsWebhook>({
    mode: 'onBlur',
    defaultValues: { channel_name: '', webhook_url: '' },
  });

  const errors = methods.formState.errors;

  const loadData = useCallback(
    async (newData = undefined) => {
      const allChannels = registeredChannels
        .filter((c) => c.channel_name)
        .map((c) => c.channel_name);

      if (newData) {
        allChannels.push(newData);
      }
      // Mark channels as selected if they are registered
      const channelsWithSelection = allChannels.map((channelName) => ({
        channel_name: channelName,
        isSelected: !!teamsChannels.filter((channel) => channel.channel_name === channelName)
          .length,
      }));

      setSelectedChannels(channelsWithSelection);
    },
    [teamsChannels, registeredChannels],
  );

  useEffect(() => {
    methods.reset({});
    loadData();
  }, [loadData, methods]);

  const handleCheckboxChange = useCallback(
    (channelName: string) => {
      const updatedChannels = selectedChannels.map((channel) => {
        return {
          ...channel,
          isSelected:
            channel.channel_name === channelName ? !channel.isSelected : channel.isSelected,
        };
      });
      const alertSelectedChannel = updatedChannels
        .filter((c) => c.isSelected)
        .map((c) => ({
          channel_name: c.channel_name,
        }));

      if (alertSelectedChannel.length) {
        clearErrors(['channelError']);
      }
      if (sendViaWebhook && !alertSelectedChannel.length) {
        setError('channelError', {
          type: 'custom',
          message: `Please select atleast 1 channel`,
        });
      }
      setSelectedChannels(updatedChannels);
      setValue('alert_destination.teams_destination.teams_channels', [...alertSelectedChannel], {
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [clearErrors, selectedChannels, sendViaWebhook, setError, setValue],
  );

  const handleTestConnection = async () => {
    try {
      setTestConnectionSuccess(false);
      const response = await testTeamsWebHook({
        channel_name: 'test',
        webhook_url: methods.getValues('webhook_url'),
      });
      if (response) {
        setTestConnectionSuccess(true);
      } else {
        setTestConnectionSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setTestConnectionSuccess(false);
    }
  };

  const handleRegistration = async (data: TeamsWebhook) => {
    try {
      await registerTeamsWebHook(data);
      await loadData(data.channel_name);
      methods.resetField('channel_name');
      methods.resetField('webhook_url');
      setOpenRegistration(false);
    } catch (error) {
      console.log('error in registration>>>>', error);
      methods.setError('webhook_url', new Error('Something went wrong!'));
    }
    setTestConnectionSuccess(false);
  };

  return (
    <>
      {openRegistration ? (
        <WebhookRegistration>
          <TextInput
            data-testid="webhook-name"
            label="Name"
            {...methods.register('channel_name', {
              validate: (value) => {
                if (!value) {
                  return 'Required field';
                }
                return true;
              },
            })}
            errorMessage={errors?.channel_name?.message || ''}
            errorId="name-required"
          />
          <TextInput
            data-testid="webhook-url"
            label="Webhook URL"
            {...methods.register('webhook_url', {
              validate: (value) => {
                if (!value) {
                  return 'Required field';
                }
                return true;
              },
            })}
            errorMessage={errors?.webhook_url?.message || ''}
            errorId="url-required"
          />
          <div className="button-wrapper">
            <Button
              data-testid="test-connection"
              buttonSize="small"
              buttonType="secondary"
              onClick={methods.handleSubmit(handleTestConnection)}
            >
              Test connection
            </Button>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ marginRight: '5px' }}
                data-testid="cancel-webhook"
                buttonSize="small"
                buttonType="secondary"
                type="submit"
                onClick={() => {
                  methods.resetField('channel_name');
                  methods.resetField('webhook_url');
                  setOpenRegistration(false);
                  setTestConnectionSuccess(false);
                }}
              >
                Cancel
              </Button>
              <Button
                data-testid="register-webhook"
                buttonSize="small"
                buttonType="primary"
                type="submit"
                disabled={!testConnectionSuccess}
                onClick={methods.handleSubmit(handleRegistration)}
              >
                Register
              </Button>
            </div>
          </div>
        </WebhookRegistration>
      ) : (
        <>
          <TeamsWebhookSelectionWrapper>
            <Switch
              selected={sendViaWebhook}
              dataTestId={'teams'}
              onClick={() => {
                setValue(
                  'alert_destination.teams_destination',
                  {
                    selected: !sendViaWebhook,
                    teams_channels: selectedChannels
                      .filter((c) => c.isSelected)
                      .map((c) => ({
                        channel_name: c.channel_name,
                      })),
                  },
                  {
                    shouldDirty: true,
                    shouldTouch: true,
                  },
                );
                setSendViaWebhook(!sendViaWebhook);
                const selectedTeamsChannels = getValues(
                  'alert_destination.teams_destination.teams_channels',
                );
                if (!sendViaWebhook && !selectedTeamsChannels.length) {
                  setError('channelError', {
                    type: 'custom',
                    message: `Please select atleast 1 channel`,
                  });
                } else {
                  clearErrors('channelError');
                }
              }}
            />
            <p className="active">Use MS Teams Webhook to push notifications</p>
          </TeamsWebhookSelectionWrapper>
          <div>
            {sendViaWebhook && (
              <fieldset>
                <legend style={{ fontSize: '13px' }}>Registered Channels</legend>
                <ul style={{ margin: '0px', padding: 0, maxHeight: '100px', overflow: 'auto' }}>
                  {selectedChannels.map((channel, id) => (
                    <ChannelList key={id}>
                      <Checkbox
                        data-testid={channel.channel_name}
                        onChange={() => handleCheckboxChange(channel.channel_name)}
                        checked={channel.isSelected ?? false}
                      />
                      <span>{channel.channel_name}</span>
                    </ChannelList>
                  ))}
                </ul>
              </fieldset>
            )}
          </div>
          <>
            {formStateError['channelError']?.message && (
              <ConditionError data-testid="alert-error">
                {formStateError['channelError']?.message}
              </ConditionError>
            )}
          </>
        </>
      )}
    </>
  );
});
