import styled, { css } from 'styled-components';

export interface customTextStyleProps {
  color?: string;
  family?: string;
  size?: number;
  style?: string;
  weight?: number;
  lineHeight?: number;
}

export const customTextStyle = ({
  color = '',
  size = 11,
  lineHeight = 13,
  weight = 400,
  family = 'Open Sans',
  style = 'normal',
}: customTextStyleProps) => {
  return css`
    color: ${color};
    font-family: ${family};
    font-size: ${size}px;
    font-style: ${style};
    font-weight: ${weight};
    line-height: ${lineHeight}px;
  `;
};

export const EditAlert = styled.div`
  color: ${({ theme }) => theme.alert.modal.textColor};
  padding: 10px 20px 20px;
  width: 100%;

  svg {
    margin: 0;
  }
  .info-message {
    display: flex;
    margin-bottom: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid ${({ theme }) => theme.alert.modal.sectionBorderColor};
    gap: 4px;
    .title {
      ${customTextStyle({ size: 13, lineHeight: 19 })}
    }
    .info {
      ${customTextStyle({})}
      color: ${({ theme }) => theme.alert.modal.subTextColor};
      margin-bottom: 10px;
    }
  }
  .alert-config-header {
    display: flex;
    align-items: center;
    padding-left: 140px;
    margin-bottom: 6px;
    > span {
      width: 50%;
    }
  }
`;

export const InfoCard = styled.div<{ type?: string }>`
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.alert.modal.infoBorder};
  background: ${({ theme }) => theme.alert.modal.infoBackground};
  padding: 7px 12px;
  margin-bottom: 10px;
  ${customTextStyle({ size: 13, lineHeight: 19 })}
  color: ${({ theme }) => theme.alert.modal.textColor};
  .icon {
    margin-right: 8px;
    padding-top: 2px;
  }
  ${(props) =>
    props.type === 'error' &&
    css`
      border: 1px solid ${({ theme }) => theme.alert.modal.errorBorder};
      background: ${({ theme }) => theme.alert.modal.errorBackground};
      .icon {
        margin-right: 6px;
        > svg {
          fill: ${({ theme }) => theme.alert.modal.errorIcon};
        }
      }
    `}
`;

export const TitleCard = styled.div`
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.alert.modal.titleText};
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  background: ${({ theme }) => theme.alert.modal.titleCardBG};
  margin-bottom: 24px;
  .system {
    ${customTextStyle({ family: 'Space Grotesk', size: 17, lineHeight: 19 })};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .summary-wrapper {
    display: flex;
    gap: 24px;
    .summary {
      display: flex;
      flex-direction: column;
      align-items: center;
      .count {
        ${customTextStyle({ family: 'Space Grotesk', size: 17, lineHeight: 19, weight: 300 })}
      }
      .name {
        text-align: center;
        ${customTextStyle({})}
      }
    }
  }
  .tag {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    .path {
      ${customTextStyle({})}
      color: ${({ theme }) => theme.alert.modal.titleText};
      margin-bottom: 4px;
    }
    .id {
      background: #cccccc;
      border-radius: 4px;
      ${customTextStyle({ size: 13, lineHeight: 19 })}
      color: ${({ theme }) => theme.alert.modal.titleText};
      margin-right: 10px;
      padding: 0 5px;
    }
    .description {
      ${customTextStyle({ size: 17, lineHeight: 19, family: 'Space Grotesk' })}
      color: ${({ theme }) => theme.alert.modal.titleText};
    }
  }
`;

export const ContentWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const DisableWrapper = styled.div<{ disabled?: boolean | null }>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const LeftColumn = styled.div`
  width: 400px;
  margin-right: 31px;
  .conditions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .alert-config {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    .checkbox {
      display: flex;
      align-items: center;
      label {
        margin-right: 5px;
      }
    }
    .ensemble {
      display: flex;
      align-items: center;
      justify-content: center;
      > span {
        ${customTextStyle({})}
        color: ${({ theme }) => theme.alert.modal.durationTitleColor};
      }
      .filter-select {
        margin: 0 0 0 5px;
        padding: 0;
        button {
          border: 1px solid ${({ theme }) => theme.alert.modal.buttonBorderColor};
          background: ${({ theme }) => theme.alert.modal.actionButtonBgColor};
          color: ${({ theme }) => theme.alert.modal.ensembleFilterColor};
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
          justify-content: flex-start;
          > span {
            width: 70px;
            text-wrap: nowrap;
            overflow: hidden;
          }
        }
        li {
          display: flex;
          padding: 4px 8px;
          align-items: center;
          gap: 6px;
          &.unselected {
            padding-left: 28px;
          }
          .ensemble-family {
            text-overflow: ellipsis;
            text-wrap: nowrap;
            overflow: hidden;
          }
        }
      }
    }
    .section-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      .checkbox {
        display: flex;
        align-items: center;
        gap: 4px;
        > span {
          ${customTextStyle({ size: 13, lineHeight: 19 })};
        }
      }
    }
    .duration-field {
      padding: 6px 18px 0;
      display: flex;
      gap: 16px;
      .wrapper {
        display: flex;
        flex-direction: column;
        .duration {
          margin-right: 4px;
          width: 71.5px;
          input {
            height: 14px;
            padding: 5px 0;
            padding-right: 20px;
          }
          .text-input {
            position: relative;
            div[name='system-down'] {
              display: none;
            }
            :after {
              content: 'min';
              font-size: 11px;
              position: absolute;
              right: 3px;
              top: 5;
              bottom: 0;
              padding: 5px 0;
            }
          }
        }
        .actions {
          button {
            margin: 0;
            width: 24px;
            height: 12px;
            padding: 0px 8px;
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.alert.modal.buttonBorderColor};
            background: ${({ theme }) => theme.alert.modal.actionButtonBgColor};
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
          }
        }
        .filter-select {
          margin: 6px 0 0 0;
          padding: 0;
          button {
            border: 1px solid ${({ theme }) => theme.alert.modal.buttonBorderColor};
            background: ${({ theme }) => theme.alert.modal.actionButtonBgColor};
            color: ${({ theme }) => theme.alert.modal.ensembleFilterColor};
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
            justify-content: flex-start;
            > span {
              width: 70px;
              text-wrap: nowrap;
              overflow: hidden;
            }
          }
          li {
            display: flex;
            padding: 4px 8px;
            align-items: center;
            gap: 6px;
            &.unselected {
              padding-left: 28px;
            }
            .ensemble-family {
              text-overflow: ellipsis;
              text-wrap: nowrap;
              overflow: hidden;
            }
          }
        }
      }
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
    &.iow-limits,
    &.anomalies {
      .wrapper {
        display: block;
        // flex-flow: row wrap;
        align-items: center;
        .pre-text {
          font-size: 13px;
        }
        .duration-input {
          .text-input {
            position: relative;
            div[name='system-down'] {
              display: none;
            }
            :after {
              content: 'min';
              font-size: 11px;
              position: absolute;
              right: 3px;
              top: 5;
              bottom: 0;
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
`;

export const RightColumn = styled.div`
  flex: 1;
  .button-container {
    display: flex;
    margin: 0 0 17px;
    button {
      height: 24px;
    }
  }
  .email-action {
    > div {
      ${customTextStyle({ size: 11, lineHeight: 13 })};
      color: ${({ theme }) => theme.alert.modal.secondaryTextColor};
      margin-bottom: 2px;
    }
    .options {
      display: flex;
      margin-bottom: 6px;
      > div {
        display: flex;
        align-items: center;
        flex: 1;
      }
    }
  }
  .teams-signin {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }
  .teams-channel-title {
    ${customTextStyle({ size: 11, lineHeight: 13 })};
    margin-bottom: 7px;
  }
  .selection-field {
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.alert.modal.recipientSelectionBorder};
    background: ${({ theme }) => theme.alert.modal.recipientSelectionBg};
    /* UI/Light/Field Inner Shadow */
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;
    height: 175px;
    overflow-y: auto;
    .selected-email {
      background: ${({ theme }) => theme.alert.channelPicker.selectedChannelBg};
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      padding: 5px 8px;
      ${customTextStyle({})};
      color: ${({ theme }) => theme.alert.channelPicker.textColor};
      margin: 0 4px 2px 0;
    }
    .text-input {
      border: none !important;
      box-shadow: none !important;
      background: none !important;
      textarea {
        resize: none;
      }
    }
  }
`;

export const TabGroup = styled.div`
  display: flex;
  padding-top: 4px;
  flex-direction: row;
  gap: 4px;
`;

export const Tabs = styled.div<{ isActive?: boolean }>`
  ${customTextStyle({ size: 13, lineHeight: 19 })};
  border-bottom: 2px solid ${({ theme }) => theme.alert.modal.tabBorder};
  color: ${({ theme }) => theme.alert.modal.tabText};
  cursor: pointer;
  display: flex;
  flex: 1;
  padding-bottom: 5px;
  margin-bottom: 9px;
  svg {
    height: 16px;
    width: 16px;
    margin-right: 3px;
  }
  ${(props) =>
    props.isActive &&
    css`
      color: ${({ theme }) => theme.alert.modal.tabActiveText};
      font-weight: 600;
      border-bottom: 2px solid ${({ theme }) => theme.alert.modal.tabActiveBorder};
    `}
`;

export const ActiveSelection = styled.div`
  margin-bottom: 26px;
  .active-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    .active {
      ${customTextStyle({ size: 11, lineHeight: 13 })};
      margin-bottom: 1px;
    }
  }
`;

export const ContentTitle = styled.div`
  ${customTextStyle({ size: 13, lineHeight: 19 })};
  padding-bottom: 5px;
  border-bottom: 1px solid var(--container-bg-5, #cbc8c4);
  margin-bottom: 16px;
`;

export const ConditionError = styled.div`
  ${customTextStyle({})};
  color: #ffb1b1;
  margin-bottom: 10px;
`;

export const DurationInfoTitle = styled.span`
  display: flex;
  span {
    display: flex;
    ${customTextStyle({})}
    color: ${({ theme }) => theme.alert.modal.durationTitleColor};
    svg {
      margin-left: 5px;
    }
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  .type-custom {
    background: ${({ theme }) => theme.alert.modal.tooltipBackground};
    color: ${({ theme }) => theme.alert.modal.tooltipText};
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
    width: 188px;
  }
  .duration-info {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    display: flex;
  }
  button:disabled {
    cursor: not-allowed;
    > span {
      cursor: not-allowed;
    }
  }
`;

export const ChannelMenu = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  .loading {
    padding: 4px 12px;
    ${customTextStyle({ size: 13 })};
    color: ${({ theme }) => theme.alert.channelPicker.suggestedColor};
  }
  .add-channel {
    border-top: 2px solid ${({ theme }) => theme.alert.modal.sectionBorderColor};
    display: flex;
    align-items: center;
    padding: 10px 8px 4px 18px;
    margin-top: 12px;
    > span {
      padding-left: 4px;
      ${customTextStyle({})};
      color: ${({ theme }) => theme.alert.channelPicker.textColor};
    }
  }
`;

export const TeamsListWrapper = styled.div`
  .teams-list {
    display: flex;
    align-items: center;
    padding: 6px 8px 4px;
    ${customTextStyle({})};
    color: ${({ theme }) => theme.alert.channelPicker.textColor};
    .arrow-icon {
      display: inline-block;
      transform: rotate(0deg);
      transition: transform 0.2s linear;
      svg {
        fill: none;
        stroke: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
      }
    }
    .logo {
      padding: 0 5px;
    }
  }
  .channel-list {
    padding: 0 18px 0 48px;
    display: none;
    ${customTextStyle({})};
    color: ${({ theme }) => theme.alert.channelPicker.textColor};
    > span {
      display: flex;
      align-items: center;
      padding: 4px 0;
    }
  }

  &.expanded {
    .teams-list {
      .arrow-icon {
        transition: transform 0.2s linear;
        transform: rotate(90deg);

        svg {
          fill: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
          stroke: none;
        }
      }
    }

    .channel-list {
      transition: transform 1s linear;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const AlertsPreviewWrapper = styled.div`
  padding: 12px 16px 20px;
`;

export const AlertHeader = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 8px;
  span {
    display: flex;
    ${customTextStyle({})}
    color: ${({ theme }) => theme.alert.content.tableHeader};
  }
  .type-custom {
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
    width: 188px;
  }
  .duration-info {
    cursor: pointer;
  }
`;
