import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';

import { Role } from '../../../app/constants/auth';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useUserId } from '../../../app/hooks/accounts';
import {
  useAlertsAPI,
  useFetchAlertConfigs,
  useGetAlertSummary,
  useGetRuleBoard,
} from '../../../app/hooks/alerts';
import { useSubSystemsById } from '../../../app/hooks/folders';
import { useEnsembleModelsQuery } from '../../../app/hooks/tags';
import { AlertFormV2 } from '../../components/alert-modal/alert-form-v2';
import { InfoCard } from '../../components/alert-modal/styles';
import { Loading } from '../../components/loading/loading';

import { TARGET_TYPE } from './alert-manager';
import { ActionContainer, Container, Head, ScrollFrame, TitleContainer } from './style';

import { Button, Icon, Switch } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { usePrevious } from '@controlrooms/hooks';
import { Alert, Tag } from '@controlrooms/models';

interface AlertConfigTableProps extends React.HTMLAttributes<HTMLTableRowElement> {
  alert: Alert;
  rowIndex: number;
  isSelected: boolean;
  onRowSelect: (index: number) => void;
}
type AlertConfigurationProps = {
  selectedRule?: number;
  alertType: string;
  systemId: number;
  tags: Tag[];
  handleCloseForm?: () => void;
};

export const AlertConfiguration: React.FC<AlertConfigurationProps> = ({
  selectedRule, // ruleid passed from the query param
  alertType,
  systemId,
  tags,
  handleCloseForm,
}) => {
  //Context
  const { hasRole } = useContext(AuthorizationContext);
  const { ensembleFamilies } = useEnsembleModelsQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: ruleBoardList } = useGetRuleBoard();
  //States
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const { data: subsystemById } = useSubSystemsById();

  const system = subsystemById?.[systemId];
  const selectedEnsemble = ensembleFamilies.find((ef) => ef.main_family);

  const defaultAlertValue: Alert = useMemo(
    () => ({
      alert_conditions: {
        anomalies_detected: {
          selected: false,
          duration: null,
          snooze_duration: null,
        },
        iow_limit_exceeded: {
          selected: false,
          duration: null,
          snooze_duration: null,
        },
      },
      alert_destination: {
        email_destination: {
          selected: true,
          emails: [],
        },
        teams_destination: {
          selected: true,
          teams_channels: [],
        },
        rule_board_destination: {
          selected: true,
          rule_board_ids: [],
        },
      },
      alert_view_summary: {
        name: '',
        description: '',
        pinned_tag_count: null,
        system_count: null,
        tag_count: null,
        infra_display_name: '',
        tag_display_name: '',
        target_display_name: '',
      },
      ensemble_family_id: selectedEnsemble?.family_id,
      disabled: false,
      target_type: '',
      target_id: '',
      target_name: '',
      target_display_name: '',
    }),
    [selectedEnsemble?.family_id],
  );
  const [newAlert, setNewAlert] = useState<Alert | null>(null);
  const userId = useUserId();

  const queryClient = useQueryClient();
  const methods = useForm<Alert>({
    mode: 'onChange',
    defaultValues: defaultAlertValue,
  });

  const previousAlertConfig = usePrevious({ alertType, system, tags });

  // Reset the default selected row when the alertType, systemId or tags change
  useEffect(() => {
    if (tags.length !== previousAlertConfig?.tags.length) {
      setSelectedRow(null);
    }
    if (tags.length && previousAlertConfig?.tags.length) {
      if (previousAlertConfig?.tags[0].name !== tags[0].name) {
        setSelectedRow(null);
      }
    }
    if (
      previousAlertConfig?.alertType !== alertType ||
      previousAlertConfig?.system?.folder !== system?.folder
    ) {
      setSelectedRow(null);
    }
  }, [
    alertType,
    previousAlertConfig?.alertType,
    previousAlertConfig?.system?.folder,
    previousAlertConfig?.tags,
    system?.folder,
    tags,
  ]);

  const { updateAlertMutation, createAlertMutation } = useAlertsAPI();

  const { mutateAsync: createAlert } = createAlertMutation;
  const { mutateAsync: updateAlert } = updateAlertMutation;

  const targetId = useMemo(
    () => (alertType === TARGET_TYPE.SYSTEM ? system?.folder.toString() : tags[0].name),
    [alertType, system?.folder, tags],
  );
  const targetType = alertType.toLowerCase();

  const { data: alertSummary } = useGetAlertSummary(alertType.toLowerCase(), targetId);
  const { data, isLoading: isLoadingAlertConfigs } = useFetchAlertConfigs(alertType, targetId);

  const alertConfigs = useMemo(() => data?.result.alert_configurations || ([] as Alert[]), [data]);

  const combinedAlertConfigs = useMemo(() => {
    return newAlert ? [...alertConfigs, newAlert] : alertConfigs;
  }, [alertConfigs, newAlert]);

  const alertConfigOwner = useMemo(
    () => alertConfigs.every((alert) => alert?.user_id == userId),
    [alertConfigs, userId],
  );

  const clearURLQuery = useCallback(() => {
    if (searchParams.has('targetId')) {
      searchParams.delete('targetId');
    }
    if (searchParams.has('targetType')) {
      searchParams.delete('targetType');
    }
    if (searchParams.has('folderId')) {
      searchParams.delete('folderId');
    }
    if (searchParams.has('ruleId')) {
      searchParams.delete('ruleId');
    }
    if (searchParams.has('alertType')) {
      searchParams.delete('alertType');
    }
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const _alertConfigData = data?.result.alert_configurations ?? ([] as Alert[]);
    //Set from the query param
    if (_alertConfigData.length && selectedRule !== undefined) {
      const selectIndex = _alertConfigData.findIndex((alert) => alert.id === selectedRule);
      if (selectIndex && selectIndex !== -1) {
        setSelectedRow(selectIndex);
        setNewAlert(null); // Clear the new alert
        const selectedAlert = combinedAlertConfigs[selectIndex];
        methods.reset(selectedAlert);
        return;
      }
      setSelectedRow(0);
      methods.reset(data?.result.alert_configurations[0]);
    } else if (_alertConfigData.length && selectedRule === undefined) {
      if (selectedRow === null && !newAlert) {
        // const selectIndex = _alertConfigData.findIndex((alert) => alert.id === selectedRule);
        setSelectedRow(0);
        methods.reset(_alertConfigData[0]);
      } else if (newAlert) {
        setSelectedRow(null);
        methods.reset(defaultAlertValue);
      }
    } else {
      setSelectedRow(null);
      methods.reset(defaultAlertValue);
    }
  }, [
    combinedAlertConfigs,
    data?.result.alert_configurations,
    defaultAlertValue,
    methods,
    newAlert,
    selectedRow,
    selectedRule,
  ]);

  const handleSelectRow = useCallback(
    (rowIndex: number) => {
      clearURLQuery();
      setSelectedRow(rowIndex);
      setNewAlert(null); // Clear the new alert
      const selectedAlert = combinedAlertConfigs[rowIndex];
      methods.reset(selectedAlert); // Reset the form with the selected alert's values
    },
    [clearURLQuery, combinedAlertConfigs, methods],
  );

  const handleActiveClick = async (selectedAlert: Alert, selected: boolean) => {
    clearURLQuery();
    try {
      await updateAlert({ ...selectedAlert, disabled: !selected });
      queryClient.refetchQueries(['ALERT_CONFIGS', targetId]);
    } catch (error) {
      console.log('error>>>', error);
    }
  };

  const handleNewConfiguration = () => {
    clearURLQuery();
    const newAlertData = { ...defaultAlertValue };
    if (newAlert) {
      // newAlertData.target_id = '1';
      // If a new alert already exists, select it
      setNewAlert(newAlertData); // Store the new alert
      setSelectedRow(null); // Select the new alert row
      methods.reset(newAlertData); // Reset the form with the new alert's values
    } else {
      // Create a new alert with default values
      // newAlertData.target_id = '2';
      setNewAlert(newAlertData); // Store the new alert
      setSelectedRow(combinedAlertConfigs.length); // Select the new alert row
      methods.reset(newAlertData); // Reset the form with the new alert's values
    }
  };
  //TODO: Need to check multiple cases for redirection

  const handleCancel = () => {
    clearURLQuery();
    // setNewAlert(null); // Clear the new alert
    // setSelectedRow(null); // Deselect the row
    methods.reset(defaultAlertValue); // Reset the form
    handleCloseForm && handleCloseForm();
  };

  const handleDelete = () => {
    clearURLQuery();
    const formValues = methods.getValues();
    if (formValues.id) {
      console.log('Deleting existing alert:', formValues.id);
    }
    return;
  };

  const fixedHeaderContent = () => (
    <tr>
      <th className="col-header select"></th>
      <th className="col-header ensemble">Ensemble</th>
      <th className="col-header type">Type</th>
      <th className="col-header duration">Duration</th>
      <th className="col-header re-alert">Re-alert</th>
      <th className="col-header created-by">Created by</th>
      <th className="col-header recipients">Recipients</th>
      <th className="col-header active">Active</th>
      <th className="col-header more"></th>
    </tr>
  );

  const handleSave = async () => {
    const formValues = methods.getValues();
    const isValid = await methods.trigger();

    if (!isValid) {
      // At least one field is invalid - errors will be available in `methods.formState.errors`
      // You can display an error message or do nothing and let your form components show errors
      return;
    }

    formValues.target_type = targetType;
    formValues.target_id = targetId;
    // formValues.target_display_name

    const _noRuleBoardDestination =
      formValues.alert_destination.rule_board_destination.selected &&
      !formValues.alert_destination.rule_board_destination.rule_board_ids.length;

    const _noTeamsDestination =
      formValues.alert_destination.teams_destination.selected &&
      !formValues.alert_destination.teams_destination.teams_channels.length;

    const _noEmailDestination =
      formValues.alert_destination.email_destination.selected &&
      !formValues.alert_destination.email_destination.emails.length;

    if (_noRuleBoardDestination) {
      methods.setError('alert_destination.rule_board_destination', {
        type: 'custom',
        message: `Please select atleast 1 rule board`,
      });
    }

    if (_noTeamsDestination) {
      console.log('Please select atleast 1 channel');
      methods.setError('alert_destination.teams_destination', {
        type: 'custom',
        message: `Please select atleast 1 channel`,
      });
    }

    if (_noEmailDestination) {
      methods.setError('alert_destination.email_destination', {
        type: 'custom',
        message: `Emails are required`,
      });
    }
    if (
      formValues.alert_destination.rule_board_destination.selected &&
      !formValues.alert_destination.rule_board_destination.rule_board_ids.length
    ) {
      methods.setError('alert_destination.rule_board_destination', {
        type: 'custom',
        message: `Emails are required`,
      });
    }

    if (_noRuleBoardDestination || _noTeamsDestination || _noEmailDestination) {
      return;
    }

    if (formValues.id) {
      await updateAlert(formValues);
    } else {
      formValues.target_id = targetId;
      formValues.target_type = targetType;
      formValues.target_display_name =
        alertSummary?.result.target_display_name ||
        (alertType === TARGET_TYPE.SYSTEM ? system.infra_display_name : tags[0].tag_display_name) ||
        '';
      formValues.target_name =
        alertSummary?.result.name ||
        (alertType === TARGET_TYPE.SYSTEM ? system.name : tags[0].name) ||
        '';
      await createAlert(formValues);
    }
    handleCloseForm && handleCloseForm();
  };

  const AlertConfigTable = React.memo<AlertConfigTableProps>((props) => {
    const { alert, rowIndex, isSelected, onRowSelect } = props;
    const emails = alert.alert_destination.email_destination.emails;
    const teamsChannels = alert.alert_destination.teams_destination.teams_channels.map(
      (c) => c.channel_name,
    );
    // map it with ruleBoardList and get the name
    const boards =
      alert?.alert_destination?.rule_board_destination?.rule_board_ids?.map((id) => {
        const board = ruleBoardList?.find((b) => b.rule_board_id === id);
        return board?.rule_board_name;
      }) || [];

    const { ensembleFamilies } = useEnsembleModelsQuery();

    const ensembleFamily = ensembleFamilies.find((ef) => ef.family_id === alert.ensemble_family_id);
    const anomalies_detected = useMemo(
      () => alert.alert_conditions.anomalies_detected.selected,
      [alert.alert_conditions.anomalies_detected.selected],
    );
    const iow_limit_exceeded = useMemo(
      () => alert.alert_conditions.iow_limit_exceeded.selected,
      [alert.alert_conditions.iow_limit_exceeded.selected],
    );

    return (
      <tr
        data-index={rowIndex}
        className={isSelected ? 'selected' : ''}
        onClick={() => onRowSelect(rowIndex)}
      >
        <td className="td-val select">
          {isSelected && <Icon name={ICONS.Checkmark} width="8.6" height="6.79" color="#3C6A73" />}
        </td>
        <td className="td-val ensemble">{ensembleFamily?.family_name}</td>
        <td className="td-val type">
          <div className="row">
            {anomalies_detected && <div className="a-val">Anomaly</div>}
            {iow_limit_exceeded && <div className="l-val">Limit</div>}
          </div>
        </td>
        <td className="td-val duration">
          <div className="row">
            {anomalies_detected && (
              <div className="a-val">{alert.alert_conditions.anomalies_detected.duration}m</div>
            )}
            {iow_limit_exceeded && (
              <div className="l-val">{alert.alert_conditions.iow_limit_exceeded.duration}m</div>
            )}
          </div>
        </td>
        <td className="td-val re-alert">
          <div className="row">
            {anomalies_detected && (
              <div className="a-val">
                {alert.alert_conditions.anomalies_detected.snooze_duration}m
              </div>
            )}
            {iow_limit_exceeded && (
              <div className="l-val">
                {alert.alert_conditions.iow_limit_exceeded.snooze_duration}m
              </div>
            )}
          </div>
        </td>
        <td className="td-val created-by">{alert.user_email}</td>
        <td className="td-val recipients">
          <div className="row">
            {emails.length > 0 && (
              <div className="email-val">
                {emails.slice(0, 2).join(', ')} {emails.length > 2 && `+${emails.length - 2}`}
              </div>
            )}
            {teamsChannels.length > 0 && (
              <div className="teams-val">
                <Icon name={ICONS.Teams} disabled={true} />
                {teamsChannels.slice(0, 2).join(', ')}{' '}
                {teamsChannels.length > 2 && `+${teamsChannels.length - 2}`}
              </div>
            )}
            {boards.length > 0 && (
              <div className="board-val">
                <Icon name={ICONS.Grid} disabled={true} />
                {boards.slice(0, 2).join(', ')} {boards.length > 2 && `+${boards.length - 2}`}
              </div>
            )}
          </div>
        </td>
        <td className="td-val active">
          <span className="active-switch">
            <Switch
              selected={!alert?.disabled}
              onClick={(e, selected) => {
                handleActiveClick(alert, selected);
              }}
              dataTestId={`alerts_${alert.target_name}`}
            />
          </span>
        </td>
        <td className="td-val more">...</td>
      </tr>
    );
  });

  const editableAlert = useMemo(() => {
    const _newAlert = newAlert
      ? [defaultAlertValue]
      : selectedRow !== null &&
        combinedAlertConfigs.length &&
        combinedAlertConfigs[selectedRow] !== null
      ? [combinedAlertConfigs[selectedRow]]
      : [defaultAlertValue];
    if (!_newAlert[0].alert_conditions.anomalies_detected.anomaly_duration) {
      _newAlert[0].alert_conditions.anomalies_detected.anomaly_duration =
        _newAlert[0].alert_conditions.anomalies_detected.duration;
    }
    if (!_newAlert[0].alert_conditions.iow_limit_exceeded.exceedance_duration) {
      _newAlert[0].alert_conditions.iow_limit_exceeded.exceedance_duration =
        _newAlert[0].alert_conditions.iow_limit_exceeded.duration;
    }
    return _newAlert;
  }, [combinedAlertConfigs, defaultAlertValue, newAlert, selectedRow]);

  return (
    <Container>
      <Head>
        <TitleContainer>
          <div className="system-tag">
            {alertType === TARGET_TYPE.TAG && (
              <span className="tag">{tags[0].tag_display_name}</span>
            )}
            {alertType === TARGET_TYPE.SYSTEM && subsystemById[system?.folder] && (
              <span className="system">
                {subsystemById[system?.folder].parentName} / {subsystemById[system?.folder].name}
              </span>
            )}
            {alertType === TARGET_TYPE.TAG && (
              <span className="description">
                {tags[0].description || ''} {tags[0].uom || ''}
              </span>
            )}
          </div>
          <div className="note">Configure alerts for this {alertType.toLowerCase()}</div>
        </TitleContainer>
      </Head>
      <FormProvider {...methods}>
        <ScrollFrame>
          <div className="manager">
            <div className="configurations-table">
              {!hasRole(Role.GLOBAL_ALERT_EDITOR) && !alertConfigOwner && (
                <InfoCard type="info">
                  <Icon className="icon" name={ICONS.Info} />
                  <span data-testid="permission-info">
                    Your current permissions restrict editing some alert configurations, but you can
                    continue to edit what you do have permission for below.
                  </span>
                </InfoCard>
              )}
              <div className="title">Alert Configurations</div>
              <div className="alert-configurations">
                {isLoadingAlertConfigs ? (
                  <Loading overlay small={true} />
                ) : (
                  <TableVirtuoso
                    data={alertConfigs}
                    className="config-table"
                    fixedHeaderContent={fixedHeaderContent}
                    style={{
                      width: '100%',
                      height: `${
                        alertConfigs.length === 0
                          ? '100px'
                          : alertConfigs.length < 4
                          ? `${(alertConfigs.length + 1) * 50}px`
                          : '250px'
                      }`,
                      borderCollapse: 'collapse',
                    }}
                    components={{
                      TableRow: (props) => {
                        const { item, 'data-index': rowIndex, ...rest } = props;
                        const data = item as Alert;
                        if (!item) return null;
                        return (
                          <AlertConfigTable
                            {...(rest as React.HTMLAttributes<HTMLTableRowElement>)}
                            alert={data}
                            rowIndex={rowIndex}
                            isSelected={rowIndex === selectedRow}
                            onRowSelect={handleSelectRow}
                          />
                        );
                      },
                      EmptyPlaceholder: () => (
                        <tr>
                          <td
                            colSpan={9}
                            style={{
                              padding: '1rem',
                              textAlign: 'center',
                              background: 'transparent',
                            }}
                          >
                            No configurations available.
                          </td>
                        </tr>
                      ),
                    }}
                  />
                )}
              </div>
              <Button
                buttonSize="small"
                buttonType="secondary"
                iconName={ICONS.Plus}
                onClick={handleNewConfiguration}
              >
                New configuration
              </Button>
            </div>
            <div className="configuration-form">
              {!isLoadingAlertConfigs || newAlert ? (
                <AlertFormV2
                  targetType={alertType.toLowerCase()}
                  targetId={
                    alertType === TARGET_TYPE.SYSTEM ? system?.folder.toString() : tags[0].name
                  }
                  multipleEdit={false}
                />
              ) : null}
            </div>
          </div>
        </ScrollFrame>
        {hasRole(Role.GLOBAL_ALERT_EDITOR) || hasRole(Role.ALERT) ? (
          <ActionContainer>
            <Button buttonSize="small" buttonType="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <div className="action-buttons">
              {editableAlert[0].id && (
                <Button buttonSize="small" buttonType="secondary" onClick={handleDelete}>
                  Delete
                </Button>
              )}
              <Button buttonSize="small" buttonType="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </div>
          </ActionContainer>
        ) : null}
      </FormProvider>
    </Container>
  );
};
