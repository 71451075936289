import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import EmailChipsInput from '../../pages/alertV2/email-chip-input';

import { Icon, Switch, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

const TitleWrapper = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  margin: 20px 0px;
  p {
    margin: 0px;
    margin-left: 10px;
  }
`;
export const AlertEmails = () => {
  const { setValue, watch, clearErrors } = useFormContext();

  const sendViaEmail = watch('alert_destination.email_destination.selected');

  return (
    <>
      <TitleWrapper>
        <Switch
          selected={sendViaEmail}
          dataTestId={'teams'}
          onClick={() => {
            setValue('alert_destination.email_destination.selected', !sendViaEmail);
            clearErrors('alert_destination.email_destination');
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '5px' }}>Email</p>
          <Tooltip label={'Send alerts via email'} place="right" className="duration-info">
            <Icon name={ICONS.Info} />
          </Tooltip>
        </div>
      </TitleWrapper>
      {sendViaEmail && <EmailChipsInput />}
    </>
  );
};
