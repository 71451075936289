import styled, { css } from 'styled-components';

export const AlertTicketWrapper = styled.div<{ type?: string; status?: string }>`
  display: flex;
  position: relative;
  padding: 2px 2px 2px 8px;
  margin-bottom: 3px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${({ theme }) => theme.alert.alertTicket.background};
  border-bottom: ${({ theme }) => theme.alert.alertTicket.border};
  &:hover {
    .alert-action {
      display: inline-flex;
    }
  }
  .head {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    max-width: 100%;
    min-width: 0;
    .id {
      display: flex;
      padding: 4px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .row {
        display: flex;
        align-items: center;
        gap: 2px;
        align-self: stretch;
        overflow: hidden;
        text-overflow: ellipsis;

        /* CR/11 Data */
        font-family: 'Open Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px; /* 118.182% */
      }
      .header {
        color: ${({ theme }) => theme.alert.alertTicket.header};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .detail {
        font-size: 9px;
        color: ${({ theme }) => theme.alert.alertTicket.detail};
      }
    }
    .timer {
      display: flex;
      padding: 0px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      align-self: stretch;
      border-radius: 2px;
      font-family: 'Open Sans';
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px; /* 144.444% */
      .row {
        display: flex;
        align-items: center;
        gap: 10px;
        color: ${({ theme }) => theme.alert.alertTicket.header};
      }
      .right {
        display: flex;
        align-items: center;
        gap: 2px;
        color: ${({ theme }) => theme.alert.alertTicket.timeDuration};
        font-size: 11px;
      }
    }
  }
  ${(props) =>
    props.status === 'ARCHIVED' &&
    props.type === 'anomaly' &&
    css`
      .head {
        .id {
          .header {
            color: ${({ theme }) => theme.alert.alertTicket.detail};
          }
        }
        .timer {
          border: 1px solid ${({ theme }) => theme.alert.alertTicket.archived.anomalyTimerBorder};
        }
        path {
          fill: ${({ theme }) => theme.alert.alertTicket.detail};
        }
      }
    `}
  ${(props) =>
    props.status === 'ARCHIVED' &&
    props.type === 'limit' &&
    css`
      .head {
        .id {
          .header {
            color: ${({ theme }) => theme.alert.alertTicket.detail};
          }
        }
        .timer {
          border: 1px solid ${({ theme }) => theme.alert.alertTicket.archived.limitTimerBorder};
        }
        path {
          fill: ${({ theme }) => theme.alert.alertTicket.detail};
        }
      }
    `}

  ${(props) =>
    props.status === 'SKIPPED' &&
    props.type === 'anomaly' &&
    css`
      .head {
        .timer {
          background: rgba(31, 48, 51, 0.15);
          .right {
            color: var(--K-K-80, #ccc);
          }
        }
        .id {
          .detail {
            color: var(--Focus-Dark-Focus-2, #93bbc3);
          }
        }
      }
    `}
  ${(props) =>
    props.status === 'SKIPPED' &&
    props.type === 'limit' &&
    css`
      .head {
        .timer {
          background: rgba(255, 113, 113, 0.1);
          .right {
            color: var(--K-K-80, #ccc);
          }
        }
        .id {
          .detail {
            color: var(--Data-Vis-Pink-1, #ffb1b1);
          }
        }
      }
    `}

  ${(props) =>
    props.status === 'ALERTED' &&
    props.type === 'anomaly' &&
    css`
      border: 1px solid #26464d;
      background: ${({ theme }) => theme.alert.alertTicket.alerted.anomalyBG};
      .head {
        .timer {
          background: ${({ theme }) => theme.alert.alertTicket.alerted.timerColor};
          .row {
            color: ${({ theme }) => theme.alert.alertTicket.alerted.alertTimerColor};
          }
          .right {
            color: ${({ theme }) => theme.alert.alertTicket.alerted.alertTimerColor};
            font-size: 11px;
            font-weight: 600;
          }
        }
        .id {
          .detail {
            color: var(--Focus-Dark-Focus-2, #93bbc3);
          }
        }
      }
    `}
  ${(props) =>
    props.status === 'ALERTED' &&
    props.type === 'limit' &&
    css`
      border: 1px solid #592d2d;
      background: ${({ theme }) => theme.alert.alertTicket.alerted.limitBG};
      .head {
        .timer {
          background: rgba(255, 113, 113, 0.1);
          .row {
            color: ${({ theme }) => theme.alert.alertTicket.alerted.alertTimerColor};
          }
          .right {
            color: ${({ theme }) => theme.alert.alertTicket.alerted.alertTimerColor};
            font-size: 11px;
            font-weight: 600;
          }
        }
        .id {
          .detail {
            color: var(--Data-Vis-Pink-1, #ffb1b1);
          }
        }
      }
    `}
`;
