import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { RuleBoardData } from '@controlrooms/models';

interface AlertBoardContextProps {
  alertBoard: RuleBoardData | undefined;
  setAlertBoard: Dispatch<SetStateAction<RuleBoardData | undefined>>;
  showLog: boolean;
  setShowLog: Dispatch<SetStateAction<boolean>>;
  openShare: boolean;
  setOpenShare: Dispatch<SetStateAction<boolean>>;
  viewLink: string;
  setViewLink: Dispatch<SetStateAction<string>>;
}

const defaultState = {
  alertBoard: undefined,
  setAlertBoard: () => null,
  showLog: true,
  setShowLog: () => null,
  openShare: false,
  setOpenShare: () => null,
  viewLink: '',
  setViewLink: () => null,
};

export const AlertBoardContext = createContext<AlertBoardContextProps>(defaultState);

export const AlertBoardContextProvider: React.FC = ({ children }) => {
  const [alertBoard, setAlertBoard] = useState<RuleBoardData | undefined>();
  const [showLog, setShowLog] = useState(defaultState.showLog);
  const [openShare, setOpenShare] = useState(false);
  const [viewLink, setViewLink] = useState<string>('');

  const appState = useMemo(
    () => ({
      alertBoard,
      setAlertBoard,
      showLog,
      setShowLog,
      openShare,
      setOpenShare,
      viewLink,
      setViewLink,
    }),
    [alertBoard, openShare, showLog, viewLink],
  );

  return <AlertBoardContext.Provider value={appState}>{children}</AlertBoardContext.Provider>;
};

export const useAlertBoardContext = (): AlertBoardContextProps => {
  const context = useContext(AlertBoardContext);
  if (!context) {
    throw new Error('useAlertBoardContext must be used within a AlertBoardContextProvider');
  }
  return context;
};
