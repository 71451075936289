import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useGetRuleBoard } from '../../../app/hooks/alerts';

import { ConditionError } from './styles';

import { Checkbox, Icon, Li, Switch, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

const AlertRuleBoardPickerWrapper = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
  p {
    margin: 0px;
    margin-left: 10px;
  }
`;

const BoardList = styled(Li)`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 0px;
  margin-bottom: 5px;
  padding: 0px;
  :hover {
    background: none;
  }
  span {
    margin-left: 5px;
    font-size: 12px;
  }
`;

export const AlertDashboardPicker = memo(function TeamsWebhookPicker() {
  const {
    getValues,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
    setError,
  } = useFormContext();

  const { data: ruleBoardList } = useGetRuleBoard();
  const ruleBoardSwitchSelected = watch('alert_destination.rule_board_destination.selected');
  const ruleBoardIds = watch('alert_destination.rule_board_destination.rule_board_ids');

  useEffect(() => {
    if (ruleBoardSwitchSelected && !ruleBoardIds.length) {
      setError('alert_destination.rule_board_destination', {
        type: 'custom',
        message: `Please select atleast 1 rule board`,
      });
    } else {
      clearErrors(['alert_destination.rule_board_destination']);
    }
  }, [ruleBoardSwitchSelected, ruleBoardIds, setError, clearErrors, ruleBoardList?.length]);

  return (
    <div style={{ marginBottom: '20px' }}>
      <AlertRuleBoardPickerWrapper>
        <Switch
          selected={ruleBoardSwitchSelected}
          dataTestId={'teams'}
          onClick={() => {
            setValue('alert_destination.rule_board_destination.selected', !ruleBoardSwitchSelected);
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <p style={{ marginRight: '5px' }}>Alerts Dashboard</p>
          <Tooltip label={'Alerts Dashboard'} place="right" className="duration-info">
            <Icon name={ICONS.Info} />
          </Tooltip>
        </div>
      </AlertRuleBoardPickerWrapper>
      {ruleBoardSwitchSelected && (
        <div>
          <fieldset>
            <legend style={{ fontSize: '13px' }}>Boards</legend>
            <ul style={{ margin: '0px', padding: 0, maxHeight: '100px', overflow: 'auto' }}>
              {ruleBoardList?.map((board) => (
                <BoardList key={board.rule_board_id}>
                  <Checkbox
                    data-testid={board.rule_board_id}
                    onChange={() => {
                      const selectedRBs = new Set(
                        getValues('alert_destination.rule_board_destination.rule_board_ids') || [],
                      );
                      if (selectedRBs.has(board.rule_board_id)) {
                        selectedRBs.delete(board.rule_board_id);
                      } else {
                        selectedRBs.add(board.rule_board_id);
                      }
                      setValue(
                        'alert_destination.rule_board_destination.rule_board_ids',
                        Array.from(selectedRBs),
                      );
                    }}
                    checked={
                      watch('alert_destination.rule_board_destination.rule_board_ids')?.includes(
                        board.rule_board_id,
                      )
                        ? true
                        : false
                    }
                  />
                  <span>{board.rule_board_name}</span>
                </BoardList>
              ))}
            </ul>
          </fieldset>
          <>
            <ConditionError data-testid="alert-dashboard-error">
              {errors?.alert_destination?.rule_board_destination?.message || ''}
            </ConditionError>
          </>
        </div>
      )}
    </div>
  );
});
