import dayjs, { Dayjs } from 'dayjs';

import { TimePresetKeyType, TimeRangesType } from '../time-selection';

import { ParentTag } from './folder';

import { InitialStateType } from '@controlrooms/web/src/app/context/monitor-filtering-context';

export interface TimeRange {
  startTime: Dayjs;
  endTime: Dayjs;
}

export interface StreamingTimeRange extends TimeRange {
  streamingTimeInSeconds: number | undefined;
}

export class TimeSelection implements TimeRange {
  startTime: Dayjs;
  endTime: Dayjs;
  timezone: string;
  streamingTimeInSeconds: number | undefined;
  timeRange?: TimeRangesType;
  timePreset?: TimePresetKeyType;
  nowSelected?: boolean;

  constructor(
    startTime: dayjs.Dayjs,
    endTime: dayjs.Dayjs,
    timezone: string,
    streamingTimeInSeconds: number | undefined,
    timeRange: TimeRangesType | undefined,
    timePreset: TimePresetKeyType | undefined,
  ) {
    this.startTime = startTime;
    this.endTime = endTime;
    this.timezone = timezone;
    this.streamingTimeInSeconds = streamingTimeInSeconds;
    this.timeRange = timeRange;
    this.timePreset = timePreset;
  }
}

export interface TimeLinePoint {
  time: number;
  timestamp: string;
  value: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SVGDefsSelection = d3.Selection<SVGDefsElement, unknown, HTMLElement, any>;

export interface TimeStamp {
  time: number;
  timestamp: string | number;
}

export interface TimeValue extends TimeStamp {
  value: number;
}

export interface TimeValueString extends TimeStamp {
  value: string | number;
}

export interface AnalyzeSessionStorage {
  timeSelection: TimeSelection;
  selectedFolders: Array<number>;
  timeSelectionHistory: TimeSelection[];
  pinnedTags: ParentTag[];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  hiddenTags?: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  currentFolder: number | string | null;
  currentTenant: number | string | null;
  showAnalyzeLimits?: boolean;
  selectedViewTitle: string;
}

export interface MonitorSessionStorage {
  timeSelection: TimeSelection;
  selectedFolders: Array<number>;
  severityFilter: number;
  showMonitorLimits?: boolean;
  currentTenant: number | string | null;
  selectedLabelTypes?: InitialStateType;
  selectedViewTitle: string;
}

export enum SeverityFilter {
  'LOW' = 1,
  'MEDIUM' = 2,
  'HIGH' = 3,
}

export * from './alert';
export * from './alertV2';
export * from './anomaly';
export * from './folder';
export * from './interaction';
export * from './notification';
export * from './tag';
export * from './tenant';
export * from './view';
export * from './analyze-system-browser';
export * from './cms';
export * from './ms-teams';
export * from './limit';
