import dayjs from 'dayjs';
import { useCallback, useContext, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Role } from '../../../app/constants/auth';
import { Paths } from '../../../app/constants/paths';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useTenant } from '../../../app/context/tenant-context';
import { useUserId } from '../../../app/hooks/accounts';
import { useArchiveAlert, useMuteRuleBoard } from '../../../app/hooks/alerts';
import { useTagsByName } from '../../../app/hooks/folders';
import { useEnsembleModelsQuery } from '../../../app/hooks/tags';
import { useSaveView } from '../../../app/hooks/view';
import { useAlertBoardContext } from '../../context/alert-board-context';
import { AlertMode, useAlertContext } from '../../context/alert-v2-context';
import { useLayoutContext } from '../../context/layout-context';
import { transformToViewState } from '../../context/view-context';

import { AlertActionsWrapper } from './alert-ticket-detail-style';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
// import { AlertV2Type, TimePresetKeys } from '@controlrooms/models';
import { AlertV2Type, HiddenTag, ParentTag, PersistView, ViewType } from '@controlrooms/models';

interface AlertTicketDetailProps {
  alertLog?: AlertV2Type;
  type?: string;
  kind?: string;
}

export const AlertActions = ({ alertLog, type, kind }: AlertTicketDetailProps) => {
  const navigate = useNavigate();
  const { tenant: currentTenantId } = useTenant();
  const currentUserId = useUserId();
  const { hasRole } = useContext(AuthorizationContext);
  const { mutateAsync: createView } = useSaveView();
  const { mutateAsync: muteRule } = useMuteRuleBoard();
  const { mutateAsync: archiveAlert } = useArchiveAlert();
  const { handleModeChange } = useAlertContext();
  const { data: tagsByName } = useTagsByName();

  const { ensembleFamilies } = useEnsembleModelsQuery();
  const { setViewIds, setActiveModes, setActiveView, setViewCollection, viewCollection } =
    useLayoutContext();

  const { setOpenShare, setViewLink } = useAlertBoardContext();

  const ensembleFamily = useMemo(
    () => ensembleFamilies.find((ef) => ef.family_id === alertLog?.rule_info.ensemble_family_id),
    [alertLog?.rule_info.ensemble_family_id, ensembleFamilies],
  );

  const handleMuteClick = (muted: boolean) => {
    try {
      muteRule({
        rule_id: alertLog?.rule_info?.rule_id as number,
        muted,
      });
    } catch (error) {
      console.log(error);
    }
    console.log('mute', muted);
  };

  const constructPersistedView = useCallback(
    (viewType = ViewType.ANALYZE) => {
      const selectedFolder = alertLog?.alert_condition?.data?.tags[0]?.folder_id as number;
      const selectedTags =
        viewType === ViewType.ANALYZE
          ? [...new Set(alertLog?.alert_condition?.data?.tags.map((t) => t.tag_name))]
          : {
              [selectedFolder]: alertLog?.alert_condition?.data?.tags.map((t) => {
                return tagsByName[t.tag_name];
              }),
            };
      let pinnedTags = [] as ParentTag[];
      if (viewType === ViewType.ANALYZE && alertLog?.rule_info.target_type === 'tag') {
        const tagData = alertLog.alert_condition.data.tags[0];
        pinnedTags = [
          {
            description: tagData.tag_description,
            tag_display_name: tagData.tag_display_name,
            name: tagData.tag_name,
            uom: '',
            folder: tagData.folder_id,
          },
        ];
      }
      return {
        view_id: '',
        view: {
          selectedFolders: alertLog?.rule_info.target_type === 'tag' ? [] : [selectedFolder],
          hiddenTags: [] as HiddenTag[],
          pinnedTags: pinnedTags,
          name: 'Alert',
          type: viewType,
          timeSelection: {
            timePreset: 'custom',
            timeRange: 'custom',
            startTime: dayjs.utc(alertLog?.generate_chart_time).format('YYYY-MM-DDTHH:mm:ssZ'),
            endTime: dayjs.utc(alertLog?.alert_time).format('YYYY-MM-DDTHH:mm:ssZ'),
            // timezone: dayjs.tz.guess(),
            streamingTimeInSeconds: 0,
          },
          selectedTags,
          showLimits: type === 'limit' ? true : false,
          ensemble_family_id: alertLog?.rule_info?.ensemble_family_id || '',
        },
        ensemble_family_id: alertLog?.rule_info?.ensemble_family_id || '',
      } as PersistView;
    },
    [
      alertLog?.alert_condition.data.tags,
      alertLog?.alert_time,
      alertLog?.generate_chart_time,
      alertLog?.rule_info?.ensemble_family_id,
      alertLog?.rule_info.target_type,
      tagsByName,
      type,
    ],
  );

  const handleNavigate = (viewType: ViewType) => {
    const viewId = `alert${alertLog?.alert_id}`;
    const persistView = constructPersistedView(viewType);
    const viewState = transformToViewState(
      persistView,
      ensembleFamily || null,
      currentTenantId,
      viewId,
    );
    sessionStorage.setItem(viewId || 'error', JSON.stringify(viewState));
    setViewIds((prevItems) => Array.from(new Set([...prevItems, viewId])));
    setActiveModes((prev) => ({ ...prev, [viewId]: viewType }));
    setActiveView(viewId);
    if (viewCollection.collectionId) {
      setViewCollection((prev) => ({
        ...prev,
        viewsIds: Array.from(new Set([...prev.viewsIds, viewId])),
        views: [...prev.views.filter((v) => v.view_id !== viewId), persistView],
        isDirty: true,
      }));
    }
    navigate({
      pathname: generatePath(Paths.VIEWS, {
        tenantId: currentTenantId.toString(),
      }),
    });
  };

  const handleAssessClick = () => {
    handleNavigate(ViewType.ANALYZE);
  };

  const handleInvestigateClick = () => {
    handleNavigate(ViewType.INVESTIGATE);
  };

  const handleShareClick = async () => {
    setOpenShare(true);
    const persistView = constructPersistedView();
    persistView.view.name =
      'shared_alert_view_' + new Date().getTime().toString() + '_' + currentUserId;
    const hash = await createView({ ...persistView, shared: true, user_id: currentUserId });
    setViewLink(
      `${window.location.origin}${generatePath(Paths.SHARE, {
        tenantId: currentTenantId.toString(),
        hash,
      })}`,
    );
  };

  const handleArchiveClick = () => {
    try {
      archiveAlert(alertLog?.alert_id as number);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfigClick = () => {
    handleModeChange(AlertMode.MANAGER);
    navigate({
      pathname: generatePath(Paths.MANAGE_ALERTS_V2, {
        tenantId: currentTenantId.toString(),
      }),
      search: `?alertType=${alertLog?.rule_info.target_type}&targetId=${alertLog?.rule_info.target_id}&targetType=${alertLog?.rule_info.target_type}&folderId=${alertLog?.alert_condition.data.tags[0].folder_id}&ruleId=${alertLog?.rule_info.rule_id}`,
    });
  };

  return (
    <>
      <AlertActionsWrapper className={`alert-action ${kind === 'log' ? 'log' : 'detail'}`}>
        {alertLog?.rule_info?.muted ? (
          <Tooltip label="Mute this alert">
            <Icon
              name={ICONS.Bell}
              className="action-icon"
              onClick={() => {
                handleMuteClick(false);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip label="Mute this alert">
            <Icon
              name={ICONS.Mute}
              className="action-icon"
              onClick={() => {
                handleMuteClick(true);
              }}
            />
          </Tooltip>
        )}
        <Tooltip label="Assess">
          <Icon name={ICONS.Assess} className="action-icon" onClick={handleAssessClick} />
        </Tooltip>
        <Tooltip label="Investigate">
          <Icon name={ICONS.Investigate} className="action-icon" onClick={handleInvestigateClick} />
        </Tooltip>
        <Tooltip label="Share">
          <Icon name={ICONS.Export} className="action-icon" onClick={handleShareClick} />
        </Tooltip>
        <Tooltip label="Archive">
          <Icon name={ICONS.Inbox} className="action-icon" onClick={handleArchiveClick} />
        </Tooltip>
        {hasRole(Role.ALERT_MANAGER_V2) && (
          <Tooltip label="Manage alert">
            <Icon name={ICONS.Gear} className="action-icon" onClick={handleConfigClick} />
          </Tooltip>
        )}
      </AlertActionsWrapper>
      {/* <Modal open={openShare}>
        <ModalContents
          title={'Share this alert'}
          styles={{ content: { maxWidth: '350px' } }}
          shouldCloseOnEsc
          closeButtonCallback={() => {
            setOpenShare(!openShare);
          }}
          dataTestIdCancel="modal-close-icon-share-alert"
        >
          <AlertExportModal link={viewLink} />
        </ModalContents>
      </Modal> */}
    </>
  );
};
