import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Role } from '../../app/constants/auth';
import { AuthorizationContext } from '../../app/context/authorization-context';

interface AlertContextProps {
  alertMode: AlertMode;
  setAlertMode: Dispatch<SetStateAction<AlertMode>>;
  handleModeChange: (mode: AlertMode) => void;
}

export enum AlertMode {
  BOARD = 'BOARD',
  MANAGER = 'MANAGER',
}

const defaultState = {
  alertMode: AlertMode.BOARD,
  setAlertMode: () => null,
  handleModeChange: () => null,
};

export const AlertContext = createContext<AlertContextProps>(defaultState);

export const AlertContextProvider: React.FC = ({ children }) => {
  const { hasRole } = useContext(AuthorizationContext);
  const [alertMode, setAlertMode] = useState<AlertMode>(() => {
    const storedAlertMode = sessionStorage.getItem('alertMode');

    // Check if the stored value is one of the enum values.
    if (storedAlertMode === AlertMode.BOARD || storedAlertMode === AlertMode.MANAGER) {
      if (hasRole(Role.ALERT_MANAGER_V2) && storedAlertMode === AlertMode.MANAGER) {
        return AlertMode.MANAGER;
      } else if (hasRole(Role.ALERT_DASHBOARD_V2) && storedAlertMode === AlertMode.BOARD) {
        return AlertMode.BOARD;
      } else {
        return hasRole(Role.ALERT_MANAGER_V2) ? AlertMode.MANAGER : AlertMode.BOARD;
      }
    }

    // Fallback to default if storedAlertMode is null or invalid.
    return hasRole(Role.ALERT_DASHBOARD_V2) ? AlertMode.BOARD : AlertMode.MANAGER;
  });

  const handleModeChange = (mode: AlertMode) => {
    setAlertMode(mode);
    sessionStorage.setItem(
      'alertMode',
      mode === AlertMode.BOARD || mode === AlertMode.MANAGER ? mode : AlertMode.BOARD,
    );
  };

  const appState = useMemo(
    () => ({
      alertMode,
      setAlertMode,
      handleModeChange,
    }),
    [alertMode],
  );

  return <AlertContext.Provider value={appState}>{children}</AlertContext.Provider>;
};

export const useAlertContext = (): AlertContextProps => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within a AlertContextProvider');
  }
  return context;
};
