import styled from 'styled-components';

import {
  breakpoints,
  customScrollPrimary,
  customScrollSecondary,
} from '../../../app/global-styles';
import { rowSharedCss } from '../../components/analyze-system-browser/styles/shared-styles';

import { StyledCheckbox, StyledIcon } from '@controlrooms/components';
import { colors, Size, text } from '@controlrooms/design-tokens';

export const Ul = styled.ul<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 211px;
  padding: 0;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  list-style: none;
  border: 1px solid black;
  border-radius: 4px;
  z-index: 10;
  ${(props) => {
    if (!props.isOpen) {
      return `
        display: none;
      `;
    }
  }}
`;

export const MonitorContent = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  ${customScrollPrimary}
  .heatmap-virtuoso {
    ${customScrollSecondary}
  }
`;

export const LabelTooltip = styled.div<{ labelTooltipPosition: { x: number; y: number } }>`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 3px;
  color: ${({ theme }) => theme.tooltip.textColor};
  background-color: ${({ theme }) => theme.tooltip.backgroundColor};
  box-shadow: ${colors.elevation.dark[2]};
  padding: 0.25rem;
  ${(props) => {
    if (props.labelTooltipPosition) {
      return `
      top: ${props.labelTooltipPosition.y}px;
      left: ${props.labelTooltipPosition.x}px;
    `;
    }
  }}

  ::after {
    content: '';
    position: absolute;
    bottom: -5px; /* Adjust this value as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent; /* Half of the triangle width */
    border-right: 5px solid transparent; /* Half of the triangle width */
    border-top: 5px solid ${({ theme }) => theme.tooltip.backgroundColor}; /* Triangle height and color */
  }
`;

export const GroupTooltip = styled.div<{ groupTooltipPosition: { x: number; y: number } }>`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  box-shadow: ${colors.elevation.dark[2]};

  ${(props) => {
    if (props.groupTooltipPosition) {
      return `
        top: ${props.groupTooltipPosition.y}px;
        left: ${props.groupTooltipPosition.x}px;
      `;
    }
  }}

  .group-tooltip-button {
    margin: 0;
    cursor: pointer;
    padding: 0;
    border: none;
    background: none;

    svg {
      fill: ${({ theme }) => theme.modal.panel.unselectedTextColor};

      &:hover {
        fill: ${({ theme }) => theme.systemBrowser.header.search.textHighlightColor};
      }
    }
  }
`;

export const StyledSidebar = styled.div`
  height: 100%;
  position: relative;
  z-index: 1;
  .folder-header {
    background: ${({ theme }) => theme.sidebar.headers.backgroundColor};
    font-size: 11px;
    font-weight: 700;
    line-height: 17px;
    padding: 2px 28px 2px 38px;
  }
`;

export const StyledSubFolderRow = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  box-shadow: ${({ theme }) => theme.sidebar.folders.boxShadow};
  &:nth-child(even) {
    background: ${({ theme }) => theme.sidebar.folders.evenBackgroundColor};
  }
  ${rowSharedCss}
`;

export const StyledSubFolder = styled.div`
  display: flex;
  align-items: center;
  width: 311px;
  padding: 0 3px 0 6px;
  display: flex;
  justify-content: space-between;
  .folder-content {
    display: flex;
    max-width: calc(331px - 70px);
    flex-grow: 1;
    align-items: center;
  }
  .drag-handle,
  .folder-checkbox {
    margin-right: 5px;
    visibility: hidden;
  }
  span {
    font-size: ${text.size[Size.DATA_13]};
    font-weight: 400;
    line-height: 28px;
    color: ${({ theme }) => theme.sidebar.folders.textColor};
    white-space: nowrap;
    text-overflow: ellipsis;
    &.system-id {
      font-size: ${text.size[Size.LABEL_9]};
      text-align: left;
    }
    &.isAnomalous {
      color: ${({ theme }) => theme.sidebar.folders.isAnomalous};
    }
  }

  .description-wrapper {
    min-width: 0;
    font-family: 'Open Sans', sans-serif;
    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .analyze-link {
    margin-left: auto;
    margin-right: 8px;
    visibility: hidden;
    text-align: right;

    button {
      background: transparent;
      border: 0;
      cursor: pointer;
      // width: 100%;
      text-align: right;

      ${StyledIcon} {
        display: inline-block;
      }
    }
  }

  .folder-menu {
    display: flex;
    position: relative;
    align-items: center;
    button {
      padding: 0 0 0 8px;
    }
  }

  .menu-select {
    margin: 0;
    padding: 0;
    visibility: hidden;
  }

  &:hover {
    .analyze-link,
    .menu-select {
      visibility: initial;
    }
    .drag-handle {
      cursor: grab;
    }
  }

  .name-wrapper {
    display: flex;
  }
`;

export const FolderSystems = styled.div`
  position: relative;
  .heatmap-virtuoso {
    div[data-testid='virtuoso-item-list'] > div {
      background: ${({ theme }) => theme.sidebar.folders.backgroundColor};
      &:hover,
      &.hover,
      &.selected,
      &.checked {
        background: ${({ theme }) => theme.sidebar.folders.selected.backgroundGradient};
        ${StyledCheckbox}:first-of-type {
          visibility: visible;
          svg > path {
            fill: ${({ theme }) => theme.checkbox.checkColor};
          }
        }
      }
      &.hover,
      &:hover {
        background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};
        ${StyledCheckbox}:first-of-type {
          border: 1px solid ${({ theme }) => theme.sidebar.folders.hover.checkboxBorderColor};
        }
      }
      &:nth-of-type(even) {
        &,
        .header {
          background-color: ${({ theme }) => theme.sidebar.folders.evenBackgroundColor};
        }
      }
    }
  }
`;

export const FolderHeatmap = styled.div`
  margin-left: auto;
  top: 0;
  right: 0;
  position: absolute;
  height: 100%;
`;

export const SidebarWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 320px;
`;

export const SidebarFooter = styled.div`
  flex-grow: 1;
  background: ${({ theme }) => theme.sidebar.footer.backgroundColor};
  display: flex;
  align-items: top;
  flex-direction: column;
  border-top: 0;
  position: fixed;
  bottom: 0;
  ${breakpoints.low} {
    right: 15%;
  }
  &.isScrollable {
    position: relative;
    border-top: 1px ${({ theme }) => theme.sidebar.footer.backgroundColor} solid;
  }

  &:before {
    content: '';
    width: calc(100% - 4px);
    margin-top: 2px;
    margin-left: 2px;
    height: 1px;
    background: ${colors.content['neutral-5']};
  }

  .gta-wrapper {
    padding: 11px 14px 11px 23px;
    display: flex;
    justify-content: space-between;
  }

  button {
    border: 0;
    background: transparent;
    height: 24px;
    cursor: pointer;
    &.link {
      background: linear-gradient(180deg, #224b4e 0%, #1f3133 49.48%, #1f3133 100%);
      border: 1px solid #0a1415;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      font-weight: 700;
      font-size: 11px;
      line-height: 17px;
      color: ${colors.content['neutral-1']};
      display: flex;
      align-items: center;
      span {
        margin-left: 3px;
      }
    }
  }

  .button-group {
    display: flex;
  }
`;

export const Header = styled.div`
  color: ${colors.content['neutral-3']};
  cursor: default;
  display: flex;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  margin-bottom: 5px;
  padding: 4px 0 4px 17px;
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.content['neutral-3']};
`;
