import React, { createRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useTagExplorer } from '../../../app-v2/context/tag-explorer-context';

import { Button, Icon, Li, OptionsGroup, TextInput, Tooltip, Ul } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';
import { MatchType } from '@controlrooms/models';

const matchTypes = [
  {
    value: MatchType.ANY,
    label: 'Match any',
    dataTestId: 'match-any-list',
    tooltipText: 'Tags where ANY entered keyword matches on that tag',
  },
  {
    value: MatchType.ALL,
    label: 'Match all',
    dataTestId: 'match-all-list',
    tooltipText: 'Tags where ALL entered keywords MUST match on that tag',
  },
];

const StyledSearch = styled.div`
  .search-wrapper {
    padding: 8px 22px 0;
    width: calc(100% - 44px);
  }

  .search-wrapper input::placeholder {
    color: ${colors.k[40]};
  }

  .search-options {
    display: flex;
    align-items: center;
    padding-right: 10px;
    color: ${({ theme }) => theme.search.content.textColor};
    .match-select {
      margin: 0;
    }
    .match-title {
      color: ${({ theme }) => theme.search.content.textColor};
    }
    .current-selections {
      display: flex;
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
      line-height: 13px;
      flex-grow: 1;
      align-items: center;
      .current-checkbox {
        margin-right: 5px;
        border: 1px solid ${({ theme }) => theme.search.checkboxBorder};
        border-radius: 3px;
        div {
          background: ${({ theme }) => theme.textInput.background};
          border: none;
          box-shadow: none;
        }
      }
    }

    .match-dropdown {
      padding: 10px 0;
      width: 103px;
    }
    .search-count {
      color: ${({ theme }) => theme.systemBrowser.header.search.searchCountColor};
      font-family: 'Open Sans', sans-serif;
    }
  }
`;

const SearchContainer = styled.div``;

const SearchBar: React.FC = () => {
  const ulRef = useRef(null);
  const searchRef = createRef<HTMLInputElement>();
  const [isMatchTypeSelectOpen, setIsMatchTypeSelectOpen] = useState(false);
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0]);
  const { searchTerm, setSearchTerm, setMatchType } = useTagExplorer();

  const handleMatchTypeClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMatchTypeSelectOpen(!isMatchTypeSelectOpen);
  };

  useEffect(() => {
    searchRef?.current?.focus();
  }, [searchRef, setSearchTerm]);

  return (
    <SearchContainer>
      <StyledSearch>
        <div className="search-wrapper">
          <TextInput
            data-testid="search-systems-input"
            placeholder="Search"
            id="tag-search-input"
            onChange={({ currentTarget }) => setSearchTerm(currentTarget?.value)}
            onKeyDown={(e: React.KeyboardEvent) => {
              e.stopPropagation();
            }}
            value={searchTerm}
            ref={searchRef}
          >
            <button
              data-testid="clear-search-button"
              className="clear-search"
              onClick={() => {
                setSearchTerm('');
              }}
            >
              <Icon name={ICONS.CloseX} width="14" height="14" />
            </button>
          </TextInput>
        </div>
        <div className="search-options">
          <OptionsGroup className="match-select">
            <Tooltip label={selectedMatchType.tooltipText || ''} place="top">
              <Button
                data-testid="match-type"
                buttonSize="small"
                buttonType={'menu-text'}
                className="match-title"
                onClick={(e) => {
                  handleMatchTypeClick(e);
                }}
                aria-haspopup="listbox"
                aria-expanded={isMatchTypeSelectOpen}
              >
                {selectedMatchType?.label}
              </Button>
            </Tooltip>
            <Ul isOpen={isMatchTypeSelectOpen} className="match-dropdown" ref={ulRef}>
              {matchTypes?.map((matchType) => (
                <Tooltip label={matchType.tooltipText || ''} place="top" key={`${matchType.value}`}>
                  <Li
                    className="match-menu-item"
                    data-testid={matchType.dataTestId}
                    onClick={(e) => {
                      handleMatchTypeClick(e);
                      setSelectedMatchType(matchType);
                      setMatchType(matchType.value);
                    }}
                  >
                    {matchType.label}
                  </Li>
                </Tooltip>
              ))}
            </Ul>
          </OptionsGroup>
        </div>
      </StyledSearch>
    </SearchContainer>
  );
};

export default SearchBar;
