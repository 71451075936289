import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { memo, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import { customSlimScroll } from '../../../app/global-styles';
import { useGetAlertsForBoard } from '../../../app/hooks/alerts';
import { useTenants } from '../../../app/hooks/tenants'; // Adjust the import as needed

import { AlertTypes } from './alert-board';
import { AlertTicket } from './alert-ticket';

import { AlertV2Type } from '@controlrooms/models';

dayjs.extend(utc);
dayjs.extend(timezone);

const AlertLogsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  flex: 1 0 0;
  align-self: stretch;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;
  .date-range {
    padding-top: 10px;
    color: var(--K-K-40, #666);
    font-family: 'Open Sans';
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px; /* 144.444% */
    text-transform: uppercase;
  }
  .alert-log-virtuoso {
    ${customSlimScroll}
    div[data-test-id='virtuoso-item-list'] {
      padding: 10px 10px 0px 10px;
    }
  }
`;

// Define a union type for items in the list.
type ListItem = { kind: 'label'; text: string } | { kind: 'alert'; alert: AlertV2Type };

export const AlertLogs = memo(
  ({ boardId, viewType }: { boardId: string; viewType: AlertTypes }) => {
    const { data: _boardAlerts } = useGetAlertsForBoard(boardId);
    const boardAlerts = useMemo(
      () => _boardAlerts?.alerts || ([] as AlertV2Type[]),
      [_boardAlerts],
    );

    const { currentTenant } = useTenants();
    // Convert current date to tenant's timezone
    const todayStart = dayjs().tz(currentTenant?.timezone).startOf('day');
    const yesterdayStart = todayStart.subtract(1, 'day');

    // Build a single list that includes label items and alert items.
    const labeledData: ListItem[] = useMemo(() => {
      // Filter alerts based on viewType (if not ALL)
      const filteredAlerts = boardAlerts.filter((alert) => {
        if (viewType === AlertTypes.ALL) {
          return true;
        } else if (viewType === AlertTypes.MUTED) {
          return alert?.rule_info?.muted;
        } else if (viewType === AlertTypes.SKIPPED) {
          //TODO has to be reviewed
          return false;
        } else if (viewType === AlertTypes.ARCHIVED) {
          //TODO has to be reviewed
          return false;
        }
      });

      // Group alerts by tenant's timezone date
      const todayAlerts = filteredAlerts.filter((alert) =>
        dayjs.utc(alert.alert_time).tz(currentTenant?.timezone).isSame(todayStart, 'day'),
      );
      const yesterdayAlerts = filteredAlerts.filter((alert) =>
        dayjs.utc(alert.alert_time).tz(currentTenant?.timezone).isSame(yesterdayStart, 'day'),
      );
      const earlierAlerts = filteredAlerts.filter((alert) =>
        dayjs.utc(alert.alert_time).tz(currentTenant?.timezone).isBefore(yesterdayStart, 'day'),
      );

      // Build the combined list with labels and alerts.
      const list: ListItem[] = [];
      if (todayAlerts.length > 0) {
        list.push({ kind: 'label', text: 'TODAY' });
        todayAlerts.forEach((alert) => list.push({ kind: 'alert', alert }));
      }
      if (yesterdayAlerts.length > 0) {
        list.push({ kind: 'label', text: 'YESTERDAY' });
        yesterdayAlerts.forEach((alert) => list.push({ kind: 'alert', alert }));
      }
      if (earlierAlerts.length > 0) {
        list.push({ kind: 'label', text: 'EARLIER' });
        earlierAlerts.forEach((alert) => list.push({ kind: 'alert', alert }));
      }

      return list;
    }, [boardAlerts, viewType, currentTenant, todayStart, yesterdayStart]);

    return (
      <AlertLogsWrapper>
        <List>
          <Section>
            <Virtuoso
              className="alert-log-virtuoso"
              style={{ height: 'calc(100vh - 160px)', width: '100%' }}
              data={labeledData}
              overscan={10}
              itemContent={(index, item: ListItem) => {
                if (item.kind === 'label') {
                  return <div className="date-range">{item.text}</div>;
                }
                return (
                  <AlertTicket
                    type={item.alert.alert_condition.type}
                    status={item.alert.alert_status}
                    alertLog={item.alert}
                    key={index}
                  />
                );
              }}
            />
          </Section>
        </List>
      </AlertLogsWrapper>
    );
  },
);
