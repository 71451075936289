import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAnalytics } from '../../../app/analytics';
import { Role } from '../../../app/constants/auth';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useViewContext } from '../../context/view-context';
import { TARGET_TYPE } from '../../pages/alertV2/alert-manager';
import AlertModal from '../alert-modal/alert-modal';
import { AlertModalV2 } from '../alert-modal/alert-modal-v2';

import { ExpandIcon } from './styles/styles';
import {
  SubSystemContainer,
  SubSystemViewContainer,
  SystemSectionContainer,
  SystemViewContainer,
} from './styles/system-styles';

import { Button, Checkbox, Icon, Li, OptionsGroup, Tooltip, Ul } from '@controlrooms/components';
import {
  ALERT_SEARCH_PARAM,
  ICONS,
  SUB_SYSTEMS_SEARCH_PARAM,
  TAGS_SEARCH_PARAM,
} from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { Checkable, Navigable, Plant, SubSystem, System, ViewType } from '@controlrooms/models';
import { sanitizeString } from '@controlrooms/utils';

const NavIcon: React.FC<{ onClick: () => void; dataTestId: string; hasTags: boolean }> = ({
  onClick = () => undefined,
  dataTestId,
  hasTags,
}) => (
  <div
    className="nav-icon"
    onClick={onClick}
    data-testid={dataTestId}
    style={{ visibility: hasTags ? 'visible' : 'hidden' }}
  >
    <Icon height="6" name={ICONS.Chevron} width="11" />
  </div>
);

type SubSystemViewProps = SubSystem & Checkable & Navigable & { depth?: number };

const SubSystemView: React.FC<SubSystemViewProps> = React.memo(
  ({
    depth = 1,
    description,
    folder: id,
    handleCheckboxCheck,
    name,
    infra_display_name,
    onNav = () => undefined,
    selectedFolders = [],
    subfolders = [],
    tags = [],
    has_model = false,
  }) => {
    const hasSubfolders = Boolean(subfolders?.length);
    const hasTags = Boolean(tags?.length);
    const { track } = useAnalytics();
    const [search, setSearchParams] = useSearchParams();
    const { viewState, setViewState } = useViewContext();
    const isTrendSearch = viewState.isTrendSearch;
    const subSystemParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);
    const tagNameParams = search.get(TAGS_SEARCH_PARAM);
    const alertView = search.get(ALERT_SEARCH_PARAM);
    const pinnedTags = useMemo(
      () => viewState.view[ViewType.ANALYZE].pinnedTags || [],
      [viewState],
    );
    // Determine if the folder or any descendants are selected or have pinned tags
    const hasSelectedDescendant = useMemo(() => {
      const checkSelected = (folder: SubSystem): boolean => {
        if (selectedFolders.includes(folder.folder)) {
          return true;
        }
        // Check if any tags in the folder are pinned
        if (
          folder.tags?.some((tag) =>
            pinnedTags.some(
              (pinnedTag) => pinnedTag.name === tag.name && pinnedTag.folder === folder.folder,
            ),
          )
        ) {
          return true;
        }
        // Recursively check subfolders
        return folder.subfolders?.some((subfolder: SubSystem) => checkSelected(subfolder)) || false;
      };
      return checkSelected({
        folder: id,
        name,
        infra_display_name,
        description,
        has_model,
        subfolders,
        tags,
      });
    }, [
      id,
      name,
      infra_display_name,
      description,
      has_model,
      subfolders,
      tags,
      selectedFolders,
      pinnedTags,
    ]);

    // Initialize expanded state
    const [expanded, setExpanded] = useState(() => hasSelectedDescendant);

    const [userToggledExpanded, setUserToggledExpanded] = useState(false);

    // Handle expand/collapse
    const handleExpand = () => {
      const newExpandedState = !expanded;
      track('Analyze - SubSystem View', {
        expandSubFolders: newExpandedState,
        expandedSystemId: id,
        expandedSystemName: name,
      });
      setExpanded(newExpandedState);
      setUserToggledExpanded(true);
    };

    // Automatically expand if a descendant is selected or has pinned tags, and the user hasn't manually collapsed it
    useEffect(() => {
      if (!userToggledExpanded && hasSelectedDescendant && !expanded) {
        setExpanded(true);
      }
    }, [hasSelectedDescendant, expanded, userToggledExpanded]);

    const handleCheckboxChange = useCallback(() => {
      if (subSystemParams || tagNameParams || alertView) {
        setSearchParams('');
      }
      track('Analyze - SubSystem View', {
        selectedSystemId: id,
        selectedSystemName: name,
      });
      handleCheckboxCheck(id);
      setViewState((prev) => ({
        ...prev,
        isDirty: true,
      }));
    }, [
      subSystemParams,
      tagNameParams,
      track,
      id,
      name,
      handleCheckboxCheck,
      setViewState,
      alertView,
      setSearchParams,
    ]);

    const handleNav = () => id && onNav(id);

    // Check if the folder is selected
    const isChecked = useMemo(() => selectedFolders?.includes(id), [selectedFolders, id]);

    const subSystemViewContainerClasses = classNames({
      expanded: expanded,
      'has-tags': hasTags,
      'has-subfolders': hasSubfolders,
      'is-child': depth > 1,
    });

    const handleNavClick = () => {
      track('Analyze - SubSystem View', {
        navigateToFolderWithId: id,
        navigate: 'clicked',
        hasTags: hasTags,
      });
      handleNav();
    };

    return (
      <SubSystemViewContainer className={subSystemViewContainerClasses} depth={depth}>
        <div className={`header ${isChecked ? 'checked' : ''}`}>
          <SubSystemContainer depth={depth}>
            <Checkbox
              dataTestId={`system-view-checkbox-${name}`}
              checked={Boolean(isChecked)}
              className="sub-system-check"
              onChange={handleCheckboxChange}
            />
            <div
              data-testid={`system-view-${name}`}
              className="name truncate"
              onClick={() => {
                track('Analyze - SubSystem View', {
                  checkedSystemId: id,
                  checkedSystemName: name,
                });
                handleCheckboxCheck(id);
              }}
            >
              {hasSubfolders ? (
                <div
                  className="expand-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleExpand();
                  }}
                >
                  <Icon
                    name={ICONS.Arrow}
                    style={{
                      transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                      transition: 'transform 0.2s',
                      fill: expanded ? '#a0a38d' : 'transparent',
                      stroke: expanded ? 'transparent' : '#a0a38d',
                    }}
                  />
                </div>
              ) : (
                <span style={{ width: depth === 1 ? '30px' : '20px', display: 'inline-block' }} />
              )}
              <Tooltip label={infra_display_name || ''} place="top">
                {infra_display_name}
              </Tooltip>
            </div>
            <div
              className="description"
              onClick={(e) => {
                e.preventDefault();
                track('Analyze - SubSystem View', {
                  navigateToFolderWithId: id,
                  navigate: 'clicked',
                });
                handleNav();
              }}
            >
              {description && infra_display_name && <span>:&nbsp;</span>}
              {description}
            </div>
            {!isTrendSearch && (
              <>
                <SystemMenu
                  handleNavClick={handleNavClick}
                  targetId={id}
                  name={name || ''}
                  hasTags={hasTags}
                />
                <NavIcon
                  dataTestId={`navigate-to-tags-${name}`}
                  onClick={handleNavClick}
                  hasTags={hasTags}
                />
              </>
            )}
          </SubSystemContainer>
        </div>

        {expanded && (
          <div className="content">
            {hasSubfolders &&
              subfolders?.map((subfolder: SubSystem) => (
                <SubSystemView
                  key={`${id}-${subfolder.folder}`}
                  depth={depth + 1}
                  handleCheckboxCheck={handleCheckboxCheck}
                  onNav={onNav}
                  selectedFolders={selectedFolders}
                  {...subfolder}
                />
              ))}
          </div>
        )}
      </SubSystemViewContainer>
    );
  },
);

type SystemSectionProps = Partial<System<SubSystem>> &
  Checkable &
  Navigable & { selectedFolders?: number[] };

export const SystemSection: React.FC<SystemSectionProps> = React.memo(
  ({
    folder,
    name,
    onNav = () => undefined,
    description,
    subfolders,
    handleCheckboxCheck,
    selectedFolders = [],
  }) => {
    const { track } = useAnalytics();
    const { viewState } = useViewContext();
    const defaultExpandLevels = 1; // Set default expansion level to 1

    const pinnedTags = useMemo(
      () => viewState.view[ViewType.ANALYZE].pinnedTags || [],
      [viewState],
    );

    // Determine if any subfolders have selected descendants or pinned tags
    const hasSelectedDescendant = useMemo(() => {
      const checkSelected = (folder: SubSystem): boolean => {
        if (selectedFolders.includes(folder.folder)) {
          return true;
        }
        // Check if any tags in the folder are pinned
        if (
          folder.tags?.some((tag) =>
            pinnedTags.some(
              (pinnedTag) => pinnedTag.name === tag.name && pinnedTag.folder === folder.folder,
            ),
          )
        ) {
          return true;
        }
        // Recursively check subfolders
        return folder.subfolders?.some((subfolder: SubSystem) => checkSelected(subfolder)) || false;
      };
      return subfolders?.some((subfolder: SubSystem) => checkSelected(subfolder)) || false;
    }, [subfolders, selectedFolders, pinnedTags]);

    // Initialize expanded state
    const [expanded, setExpanded] = useState(() => {
      return defaultExpandLevels == 1 || hasSelectedDescendant;
    });

    const [userToggledExpanded, setUserToggledExpanded] = useState(false);

    // Handle expand/collapse
    const handleExpand = useCallback(() => {
      if (subfolders?.length) {
        const newExpandedState = !expanded;
        track('Analyze - System Section', {
          expandSection: newExpandedState,
          expandedSectionName: name,
        });
        setExpanded(newExpandedState);
        setUserToggledExpanded(true);
      }
    }, [expanded, name, subfolders?.length, track]);

    // Automatically expand if a descendant is selected or has pinned tags, and the user hasn't manually collapsed it
    useEffect(() => {
      if (!userToggledExpanded && hasSelectedDescendant && !expanded) {
        setExpanded(true);
      }
    }, [hasSelectedDescendant, expanded, userToggledExpanded]);

    return (
      <SystemSectionContainer className={expanded ? 'expanded' : ''}>
        <div
          className="section-header plant-name"
          data-testid="section-header-plant-name"
          onClick={handleExpand}
        >
          <div className="icon-wrapper">{!!subfolders?.length && <ExpandIcon />}</div>
          {name && (
            <div className="name">
              <Tooltip label={name}>{name}</Tooltip>
            </div>
          )}
          <div className="description">
            {description && <Tooltip label={description}>{description}</Tooltip>}
            {description && name && <span>:&nbsp;</span>}
          </div>
        </div>

        {expanded && (
          <div className="section-content">
            {subfolders?.map((subfolder: SubSystem, index) => (
              <SubSystemView
                handleCheckboxCheck={handleCheckboxCheck}
                key={sanitizeString(`${index}-${folder}-${subfolder.name}`)}
                onNav={onNav}
                selectedFolders={selectedFolders}
                {...subfolder}
              />
            ))}
          </div>
        )}
      </SystemSectionContainer>
    );
  },
);

export interface SystemViewProps extends Checkable, Navigable {
  plant?: Plant;
  selectedFolders?: number[];
}

export const SystemView: React.FC<SystemViewProps> = React.memo(
  ({ handleCheckboxCheck, onNav, plant, selectedFolders }) => {
    const systems = plant?.subfolders ?? [];
    return (
      <SystemViewContainer>
        {systems.map((system) => (
          <SystemSection
            handleCheckboxCheck={handleCheckboxCheck}
            key={system.folder}
            onNav={onNav}
            selectedFolders={selectedFolders}
            {...system}
          />
        ))}
      </SystemViewContainer>
    );
  },
);

const SystemMenu = ({
  handleNavClick,
  targetId,
  name,
  hasTags,
}: {
  handleNavClick: () => void;
  targetId: number;
  name: string;
  hasTags: boolean;
}) => {
  const [isMenuSelectOpen, setIsMenuSelectOpen] = useState(false);
  const { canUserReadAlert, hasRole } = useContext(AuthorizationContext);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const ulRef = useRef(null);
  useClickOutside(ulRef, () => setIsMenuSelectOpen(false));

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMenuSelectOpen(!isMenuSelectOpen);
  };

  return (
    <OptionsGroup className="menu-select">
      <Button
        buttonType="icon"
        buttonSize="small"
        iconName="menu"
        data-testid={`system_view_menu_${name}`}
        className="no-border"
        onClick={handleMenuClick}
        aria-haspopup="listbox"
        aria-expanded={isMenuSelectOpen}
      />
      <Ul isOpen={isMenuSelectOpen} position="absolute" className="dropdown" ref={ulRef}>
        {hasTags && (
          <Li data-testid="view-tags" key="view-in-analyze">
            <div onClick={handleNavClick}>View Tags</div>
          </Li>
        )}
        {canUserReadAlert && (
          <Li data-testid={`${name}-manage-alert`} key="manage-alert">
            <AlertModal targetType="system" targetId={targetId}>
              <div>Manage Alerts</div>
            </AlertModal>
          </Li>
        )}
        {hasRole(Role.ALERT_MANAGER_V2) && (
          <Li
            data-testid={`${name}-manage-alert`}
            key="manage-alert-v2"
            onClick={() => {
              setShowAlertModal(true);
            }}
          >
            <div>Manage Alerts V2</div>
          </Li>
        )}
        {showAlertModal && (
          <AlertModalV2
            onConfirm={async (state) => {
              console.log(state);
              setShowAlertModal(false);
            }}
            onCancel={() => setShowAlertModal(false)}
            alert={{
              alertType: TARGET_TYPE.SYSTEM,
              systemId: targetId,
              tags: [],
            }}
          />
        )}
      </Ul>
    </OptionsGroup>
  );
};
