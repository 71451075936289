import classNames from 'classnames';
import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AlertFooter } from './alert-footer';
import { AlertForm } from './alert-form';
import { DurationInfoTitle } from './styles';

import {
  Icon,
  Modal,
  ModalContents,
  ModalContext,
  ModalOpenButton,
  Tooltip,
} from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { Alert } from '@controlrooms/models';

interface AlertModalProps {
  children?: React.ReactNode;
  alertList?: Alert[];
  targetType?: string;
  targetId?: string | number;
  multipleEdit?: boolean;
  onSuccessCallback?: () => void;
}

export enum AlertConditions {
  'ANOMALIES_DETECTED' = 'anomalies_detected',
  'IOW_LIMIT_EXCEEDED' = 'iow_limit_exceeded',
  'SPC_LIMIT_EXCEEDED' = 'spc_limit_exceeded',
}

export const defaultAlertValue: Alert = {
  alert_conditions: {
    anomalies_detected: {
      selected: false,
      duration: null,
      anomaly_duration: null,
      snooze_duration: null,
    },
    iow_limit_exceeded: {
      selected: false,
      duration: null,
      exceedance_duration: null,
      snooze_duration: null,
    },
  },
  alert_destination: {
    email_destination: {
      selected: true,
      emails: [],
    },
    teams_destination: {
      selected: true,
      teams_channels: [],
    },
    rule_board_destination: {
      selected: true,
      rule_board_ids: [],
    },
  },
  alert_view_summary: {
    name: '',
    description: '',
    pinned_tag_count: null,
    system_count: null,
    tag_count: null,
    infra_display_name: '',
    tag_display_name: '',
    target_display_name: '',
  },
  disabled: false,
  target_type: '',
  target_id: '',
  target_name: '',
  target_display_name: '',
};

export const AlertModal: React.FC<AlertModalProps> = ({
  children,
  alertList,
  targetType = '',
  targetId = '',
  multipleEdit = false,
  onSuccessCallback,
}) => {
  const { isOpen, setIsOpen } = useContext(ModalContext);

  const methods = useForm<Alert>({
    mode: 'onChange',
    defaultValues: defaultAlertValue,
  });

  return (
    <FormProvider {...methods}>
      <Modal unmountOnExit open={isOpen}>
        <ModalOpenButton>{children}</ModalOpenButton>
        <ModalContents
          title={`Manage alerts ${targetType ? `for this ${targetType}` : ''}`}
          styles={{ content: { width: '720px' } }}
          closeButtonCallback={() => {
            setIsOpen(false);
          }}
          dataTestIdCancel="modal-close-icon-alert"
          shouldCloseOnEsc={false}
          footerPrompt={
            <AlertFooter
              multipleEdit={multipleEdit}
              alertList={alertList || [defaultAlertValue]}
              onSuccessCallback={onSuccessCallback}
            />
          }
        >
          <AlertForm
            alertList={structuredClone(alertList || [defaultAlertValue])}
            targetType={targetType}
            targetId={targetId}
            multipleEdit={multipleEdit}
          />
        </ModalContents>
      </Modal>
    </FormProvider>
  );
};

export const DurationTitle = () => (
  <DurationInfoTitle>
    <span>Duration</span>
    <Tooltip
      label={'Trigger an alert if condition persists for this duration'}
      // offset='left': 8}"
      place="bottom,left"
      className="duration-info"
    >
      <Icon name={ICONS.Info} />
    </Tooltip>
  </DurationInfoTitle>
);

export const SnoozeTitle: React.FC<{ width?: string }> = ({ width = '100%' }) => {
  const titleClassNames = classNames({
    ellipsis: width ? true : false,
  });
  const content = (
    <DurationInfoTitle>
      <span className={titleClassNames} style={{ width: width }}>
        Don’t alert again for
      </span>
      <Tooltip
        label={'If condition continues, don’t send another message until this time has passed'}
        // offset="{'left': 8}"
        place="bottom,left"
        className="duration-info"
      >
        <Icon name={ICONS.Info} />
      </Tooltip>
    </DurationInfoTitle>
  );

  return content;
};

export const EnsembleFamilyTitle: React.FC<{ width?: string }> = ({ width = '100%' }) => {
  const titleClassNames = classNames({
    ellipsis: width ? true : false,
  });
  const content = (
    <DurationInfoTitle>
      <span className={titleClassNames} style={{ width: width }}>
        Ensemble Family
      </span>
      <Tooltip
        label="Ensemble Family"
        // offset="{'left': 8}"
        place="bottom,left"
        className="ensemble-family"
      >
        <Icon name={ICONS.Info} />
      </Tooltip>
    </DurationInfoTitle>
  );

  return content;
};

export const getDirtyValues = <
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>,
>(
  dirtyFields: DirtyFields,
  values: Values,
): Partial<typeof values> => {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev;

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
          : values[key],
    };
  }, {});

  return dirtyValues;
};

export default React.memo(AlertModal);
