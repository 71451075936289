import { useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useEnsembleModelsQuery } from '../../../app/hooks/tags';
import { HeaderContainer } from '../browser-filter/styles';

import { AlertDashboardPicker } from './alert-dashboard-picker';
import { SnoozeTitle } from './alert-modal';
import { AlertEmails } from './email-input';
import {
  ActiveSelection,
  ConditionError,
  ContentTitle,
  ContentWrapper,
  DisableWrapper,
  EditAlert,
  InfoCard,
  LeftColumn,
  RightColumn,
} from './styles';
import { TeamsWebhookWrapper as TeamsWebhook } from './teams-webhook-picker';

import {
  Button,
  FormCheckbox,
  Icon,
  Li,
  OptionsGroup,
  Switch,
  TextInput,
  Ul,
} from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { Alert, Ensemble } from '@controlrooms/models';

export enum AlertConditions {
  'ANOMALIES_DETECTED' = 'anomalies_detected',
  'IOW_LIMIT_EXCEEDED' = 'iow_limit_exceeded',
  'SPC_LIMIT_EXCEEDED' = 'spc_limit_exceeded',
}

enum AlertConditionsLabel {
  'ANOMALIES_DETECTED' = 'Anomalies detected',
  'IOW_LIMIT_EXCEEDED' = 'Limit exceeded',
  'SPC_LIMIT_EXCEEDED' = 'SPC Limit exceeded',
}

interface AlertModalFormProps {
  alertList?: Alert[];
  targetType: string;
  targetId: string | number;
  multipleEdit?: boolean;
}

const EditAlertV2 = styled(EditAlert)`
  padding: 0;
`;

const LeftColumnV2 = styled(LeftColumn)`
  min-width: 400;
  width: auto;
`;

export const AlertFormV2: React.FC<AlertModalFormProps> = ({ multipleEdit }) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    clearErrors,
    watch,
  } = useFormContext();
  const { ensembleFamilies } = useEnsembleModelsQuery();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const filtersRef = useRef(null);

  const toggleViewActions = () => setIsFiltersOpen((prevState) => !prevState);
  useClickOutside(filtersRef, () => setIsFiltersOpen(false));

  const handleOnOptionChange = (option: Ensemble) => {
    setValue('ensemble_family_id', option.family_id, { shouldDirty: true, shouldTouch: true });
    setIsFiltersOpen(false);
  };

  const alertEnsembleFamilyId = watch('ensemble_family_id');

  const alertEnsemble = useMemo(() => {
    return ensembleFamilies.find((ef) => ef.family_id === alertEnsembleFamilyId);
  }, [alertEnsembleFamilyId, ensembleFamilies]);

  return (
    <EditAlertV2>
      {errors['apiError']?.message && (
        <InfoCard type="error">
          <Icon className="icon" name={ICONS.SystemDown} />
          <span data-testid="alert-error">{errors['apiError']?.message}</span>
        </InfoCard>
      )}
      <ContentWrapper>
        <LeftColumnV2>
          <ActiveSelection>
            <ContentTitle>Turn alert on/off</ContentTitle>
            <div className="active-wrapper">
              <Switch
                selected={!watch('disabled')}
                onClick={(e, selected) => {
                  setValue('disabled', !selected, {
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                  trigger('disabled');
                }}
                dataTestId={'alerts_form'}
              />
              <span className="active">Active</span>
            </div>
          </ActiveSelection>
          <DisableWrapper disabled={watch('disabled')}>
            <ContentTitle>Alert fires when...</ContentTitle>
            <div className="conditions">
              <div className="alert-config anomalies">
                <div className="section-header">
                  <div className="checkbox">
                    <FormCheckbox
                      dataTestId={`${AlertConditions.ANOMALIES_DETECTED}-checkbox`}
                      {...register(
                        `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                        {
                          validate: (value) => {
                            if (multipleEdit) {
                              return true;
                            }
                            const conditions = getValues([
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            ]);
                            if (!value) {
                              clearErrors([
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                              ]);
                            }
                            if (conditions?.includes(true)) {
                              clearErrors([
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                              ]);
                              return true;
                            } else {
                              return 'At least one condition is required';
                            }
                          },
                        },
                      )}
                      checked={watch(
                        `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                      )}
                      disabled={errors['apiError']?.message}
                      customOnChange={() => {
                        trigger([
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                        ]);
                      }}
                    />
                    <span>
                      {
                        AlertConditionsLabel[
                          AlertConditions.ANOMALIES_DETECTED.toUpperCase() as keyof typeof AlertConditionsLabel
                        ]
                      }
                    </span>
                  </div>
                  <div className="ensemble">
                    <span>Ensemble family: </span>
                    <OptionsGroup className="filter-select">
                      <Button
                        data-testid="alert-family-browser-filter"
                        buttonSize="small"
                        buttonType="menu"
                        className="filter-title"
                        onClick={toggleViewActions}
                        aria-haspopup="listbox"
                        aria-expanded={isFiltersOpen}
                      >
                        {alertEnsemble?.family_name || ''}
                      </Button>
                      <Ul
                        isOpen={isFiltersOpen}
                        position="absolute"
                        className="filter-dropdown"
                        ref={filtersRef}
                      >
                        <HeaderContainer>ENSEMBLE FAMILIES</HeaderContainer>
                        {ensembleFamilies?.map((option) => {
                          const isSelected = alertEnsemble?.family_id === option.family_id;
                          return (
                            <Li
                              key={option.family_id}
                              className={`filter-menu-item ${!isSelected ? 'unselected' : ''}`}
                              data-testid={`ensemble_family_${option.family_id}`}
                              onClick={() => {
                                handleOnOptionChange(option);
                              }}
                            >
                              {isSelected && (
                                <Icon
                                  data-testid={`${option.family_id}-checkmark`}
                                  name={ICONS.CheckmarkDefault}
                                />
                              )}
                              <span className="ensemble-family">{option.family_name}</span>
                            </Li>
                          );
                        })}
                      </Ul>
                    </OptionsGroup>
                  </div>
                </div>
                <div className="duration-field">
                  <div className="wrapper">
                    <span className="pre-text">Alert if</span>
                    <div style={{ display: 'inline-flex', margin: '0 5px' }}>
                      <TextInput
                        type="number"
                        data-testid={`${AlertConditions.ANOMALIES_DETECTED}-edit-anomaly_duration`}
                        value={watch(
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                        )}
                        className="duration duration-input"
                        label=""
                        placeholder=""
                        // errorId={`input--${alert?.alert_view_summary?.name}-limits-exceedance-duration-error`}
                        disabled={
                          !getValues(
                            `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                          )
                        }
                        {...register(
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                          {
                            setValueAs: (v) => {
                              if (!v) return null;
                              return parseInt(v, 10);
                            },
                            validate: (value) => {
                              if (parseInt(value, 10) <= 0) {
                                return 'Must be greater than 0';
                              }
                              if (multipleEdit) {
                                return true;
                              }
                              const duration = getValues(
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                              );
                              if (parseInt(value, 10) > duration) {
                                return 'Must be less than or equal to duration';
                              }
                              if (
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                                ) &&
                                !value
                              ) {
                                return 'Required field';
                              }
                              return true;
                            },
                          },
                        )}
                        errorMessage={
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`
                            .split('.')
                            .reduce((v, k) => (v || {})[k], errors)?.message || ''
                        }
                        errorId={`input--anomalies-exceedance-duration-error`}
                      />
                      <div className="actions">
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowUp}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                                ) || 0,
                                10,
                              ) + 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                            );
                          }}
                          data-testid={`${AlertConditions.ANOMALIES_DETECTED}-increment-anomaly_duration`}
                          tabIndex={-1}
                        />
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowDown}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                                ) || 0,
                                10,
                              ) - 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                            );
                          }}
                          data-testid={`${AlertConditions.ANOMALIES_DETECTED}-decrement-anomaly_duration`}
                          tabIndex={-1}
                        />
                      </div>
                    </div>
                    <span className="pre-text">out of the past</span>
                    <div style={{ display: 'inline-flex', margin: '0 5px' }}>
                      <TextInput
                        type="number"
                        data-testid={`${AlertConditions.ANOMALIES_DETECTED}-edit-duration`}
                        value={watch(
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                        )}
                        className="duration duration-input"
                        label=""
                        placeholder=""
                        // errorId={`input--${alert?.alert_view_summary?.name}-limits-duration-error`}
                        disabled={
                          !getValues(
                            `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                          )
                        }
                        {...register(
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                          {
                            setValueAs: (v) => {
                              if (!v) return null;
                              return parseInt(v, 10);
                            },
                            validate: (value) => {
                              trigger(
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                              );
                              if (parseInt(value, 10) <= 0) {
                                return 'Must be greater than 0';
                              }
                              if (multipleEdit) {
                                return true;
                              }
                              if (
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                                ) &&
                                !value
                              ) {
                                return 'Required field';
                              }
                              return true;
                            },
                          },
                        )}
                        errorMessage={
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`
                            .split('.')
                            .reduce((v, k) => (v || {})[k], errors)?.message || ''
                        }
                        errorId={`input--anomalies-duration-error`}
                      />
                      <div className="actions">
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowUp}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                                ) || 0,
                                10,
                              ) + 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                            );
                            if (
                              AlertConditions.ANOMALIES_DETECTED ===
                              AlertConditions.ANOMALIES_DETECTED
                            )
                              trigger(
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                              );
                          }}
                          data-testid={`${AlertConditions.ANOMALIES_DETECTED}-increment-duration`}
                          tabIndex={-1}
                        />
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowDown}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                                ) || 0,
                                10,
                              ) - 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.duration`,
                            );
                            if (
                              AlertConditions.ANOMALIES_DETECTED ===
                              AlertConditions.ANOMALIES_DETECTED
                            )
                              trigger(
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.anomaly_duration`,
                              );
                          }}
                          data-testid={`${AlertConditions.ANOMALIES_DETECTED}-decrement-duration`}
                          tabIndex={-1}
                        />
                      </div>
                    </div>
                    <span className="pre-text">have anomalies.</span>
                  </div>
                </div>
                <div className="duration-field">
                  <div className="wrapper" style={{ marginTop: '15px' }}>
                    <SnoozeTitle />
                    <div style={{ display: 'flex', marginTop: '6px' }}>
                      <TextInput
                        type="number"
                        data-testid={`${AlertConditions.ANOMALIES_DETECTED}-edit-snooze`}
                        value={watch(
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                        )}
                        className="duration"
                        label=""
                        placeholder=""
                        disabled={
                          !getValues(
                            `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                          )
                        }
                        // errorId={`input--${alert?.alert_view_summary?.name}-anomalies-snooze-duration-error`}
                        {...register(
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                          {
                            setValueAs: (v) => {
                              if (!v) return null;
                              return parseInt(v, 10);
                            },
                            validate: (value) => {
                              if (parseInt(value, 10) <= 0) {
                                return 'Must be greater than 0';
                              }
                              if (multipleEdit) {
                                return true;
                              }
                              if (
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                                ) &&
                                !value
                              ) {
                                return 'Required field';
                              }
                              return true;
                            },
                          },
                        )}
                        errorMessage={
                          `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`
                            .split('.')
                            .reduce((v, k) => (v || {})[k], errors)?.message || ''
                        }
                        errorId={`input--anomalies-snooze-duration-error`}
                      />
                      <div className="actions">
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowUp}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                                ) || 0,
                                10,
                              ) + 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                            );
                          }}
                          data-testid={`${AlertConditions.ANOMALIES_DETECTED}-increment-snooze-duration`}
                          tabIndex={-1}
                        />
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowDown}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                                ) || 0,
                                10,
                              ) - 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.snooze_duration`,
                            );
                          }}
                          data-testid={`${AlertConditions.ANOMALIES_DETECTED}-decrement-snooze-duration`}
                          tabIndex={-1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Limits */}
              <div className="alert-config iow-limits">
                <div className="section-header">
                  <div className="checkbox">
                    <FormCheckbox
                      dataTestId={`${AlertConditions.IOW_LIMIT_EXCEEDED}-checkbox`}
                      {...register(
                        `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                        {
                          validate: (value) => {
                            if (multipleEdit) {
                              return true;
                            }
                            const conditions = getValues([
                              `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            ]);
                            if (!value) {
                              clearErrors([
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                              ]);
                            }
                            if (conditions?.includes(true)) {
                              clearErrors([
                                `alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`,
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                              ]);
                              return true;
                            } else {
                              return 'At least one condition is required';
                            }
                          },
                        },
                      )}
                      checked={watch(
                        `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                      )}
                      disabled={errors['apiError']?.message}
                      customOnChange={() => {
                        trigger([
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                        ]);
                      }}
                    />
                    <span>
                      {
                        AlertConditionsLabel[
                          AlertConditions.IOW_LIMIT_EXCEEDED.toUpperCase() as keyof typeof AlertConditionsLabel
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className="duration-field">
                  <div className="wrapper">
                    <span className="pre-text">Alert if</span>
                    <div style={{ display: 'inline-flex', margin: '0 5px' }}>
                      <TextInput
                        type="number"
                        data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-edit-exceedance-duration`}
                        value={watch(
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                        )}
                        className="duration duration-input"
                        label=""
                        placeholder=""
                        // errorId={`input--${alert?.alert_view_summary?.name}-limits-exceedance-duration-error`}
                        disabled={
                          !getValues(
                            `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                          )
                        }
                        {...register(
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                          {
                            setValueAs: (v) => {
                              if (!v) return null;
                              return parseInt(v, 10);
                            },
                            validate: (value) => {
                              if (parseInt(value, 10) <= 0) {
                                return 'Must be greater than 0';
                              }
                              const duration = getValues(
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                              );
                              if (parseInt(value, 10) > duration) {
                                return 'Must be less than or equal to duration';
                              }
                              if (multipleEdit) {
                                return true;
                              }
                              if (
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                                ) &&
                                !value
                              ) {
                                return 'Required field';
                              }
                              return true;
                            },
                          },
                        )}
                        errorMessage={
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`
                            .split('.')
                            .reduce((v, k) => (v || {})[k], errors)?.message || ''
                        }
                        errorId={`input--limits-exceedance-duration-error`}
                      />
                      <div className="actions">
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowUp}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                                ) || 0,
                                10,
                              ) + 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                            );
                          }}
                          data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-increment-exceedance-duration`}
                          tabIndex={-1}
                        />
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowDown}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                                ) || 0,
                                10,
                              ) - 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                            );
                          }}
                          data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-decrement-exceedance-duration`}
                          tabIndex={-1}
                        />
                      </div>
                    </div>
                    <span className="pre-text">out of the past</span>
                    <div style={{ display: 'inline-flex', margin: '0 5px' }}>
                      <TextInput
                        type="number"
                        data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-edit-duration`}
                        value={watch(
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                        )}
                        className="duration duration-input"
                        label=""
                        placeholder=""
                        // errorId={`input--${alert?.alert_view_summary?.name}-limits-duration-error`}
                        disabled={
                          !getValues(
                            `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                          )
                        }
                        {...register(
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                          {
                            setValueAs: (v) => {
                              if (!v) return null;
                              return parseInt(v, 10);
                            },
                            validate: (value) => {
                              trigger(
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                              );
                              if (parseInt(value, 10) <= 0) {
                                return 'Must be greater than 0';
                              }
                              if (multipleEdit) {
                                return true;
                              }
                              if (
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                                ) &&
                                !value
                              ) {
                                return 'Required field';
                              }
                              return true;
                            },
                          },
                        )}
                        errorMessage={
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`
                            .split('.')
                            .reduce((v, k) => (v || {})[k], errors)?.message || ''
                        }
                        errorId={`input--limits-exceedance-duration-error`}
                      />
                      <div className="actions">
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowUp}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                                ) || 0,
                                10,
                              ) + 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                            );
                            if (
                              AlertConditions.IOW_LIMIT_EXCEEDED ===
                              AlertConditions.IOW_LIMIT_EXCEEDED
                            )
                              trigger(
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                              );
                          }}
                          data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-increment-duration`}
                          tabIndex={-1}
                        />
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowDown}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                                ) || 0,
                                10,
                              ) - 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.duration`,
                            );
                            if (
                              AlertConditions.IOW_LIMIT_EXCEEDED ===
                              AlertConditions.IOW_LIMIT_EXCEEDED
                            )
                              trigger(
                                `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.exceedance_duration`,
                              );
                          }}
                          data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-decrement-duration`}
                          tabIndex={-1}
                        />
                      </div>
                    </div>
                    <span className="pre-text">have limits exceeded.</span>
                  </div>
                  {/* <div className="wrapper" style={{ marginTop: '-14px' }}>
                  </div> */}
                </div>
                <div className="duration-field">
                  <div className="wrapper" style={{ marginTop: '15px' }}>
                    <SnoozeTitle />
                    <div style={{ display: 'flex', marginTop: '6px' }}>
                      <TextInput
                        type="number"
                        data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-edit-snooze`}
                        value={watch(
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                        )}
                        className="duration duration-input"
                        label=""
                        placeholder=""
                        disabled={
                          !getValues(
                            `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                          )
                        }
                        // errorId={`input--${alert?.alert_view_summary?.name}-limits-snooze-duration-error`}
                        {...register(
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                          {
                            setValueAs: (v) => {
                              if (!v) return null;
                              return parseInt(v, 10);
                            },
                            validate: (value) => {
                              if (parseInt(value, 10) <= 0) {
                                return 'Must be greater than 0';
                              }
                              if (multipleEdit) {
                                return true;
                              }
                              if (
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                                ) &&
                                !value
                              ) {
                                return 'Required field';
                              }
                              return true;
                            },
                          },
                        )}
                        errorMessage={
                          `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`
                            .split('.')
                            .reduce((v, k) => (v || {})[k], errors)?.message || ''
                        }
                        errorId={`input--limits-snooze-duration-error`}
                      />
                      <div className="actions">
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowUp}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                                ) || 0,
                                10,
                              ) + 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                            );
                          }}
                          data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-increment-snooze-duration`}
                          tabIndex={-1}
                        />
                        <Button
                          buttonSize="small"
                          buttonType="icon"
                          iconName={ICONS.ArrowDown}
                          disabled={
                            !getValues(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.selected`,
                            )
                          }
                          onClick={() => {
                            setValue(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                              parseInt(
                                getValues(
                                  `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                                ) || 0,
                                10,
                              ) - 1,
                              { shouldDirty: true, shouldTouch: true },
                            );
                            trigger(
                              `alert_conditions.${AlertConditions.IOW_LIMIT_EXCEEDED}.snooze_duration`,
                            );
                          }}
                          data-testid={`${AlertConditions.IOW_LIMIT_EXCEEDED}-decrement-snooze-duration`}
                          tabIndex={-1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ConditionError data-testid="alert-condition-error">
              {`alert_conditions.${AlertConditions.ANOMALIES_DETECTED}.selected`
                .split('.')
                .reduce((v, k) => (v || {})[k], errors)?.message || ''}
            </ConditionError>
          </DisableWrapper>
        </LeftColumnV2>
        <RightColumn>
          <DisableWrapper disabled={watch('disabled')}>
            <ContentTitle>Recipients</ContentTitle>
            <AlertDashboardPicker />
            <TeamsWebhook />
            <AlertEmails />
          </DisableWrapper>
        </RightColumn>
      </ContentWrapper>
    </EditAlertV2>
  );
};
