import dayjs from 'dayjs';
import React from 'react';

import { useTenants } from '../../hooks/tenants';

import { AlertActions } from './alert-actions';
import { AlertTypes } from './alert-board';
import { AlertTicketWrapper } from './alert-ticket-style';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { AlertV2Type } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

interface AlertTicketProps {
  alertLog?: AlertV2Type;
  type?: string;
  status?: string;
}

export const AlertTicket = ({
  alertLog,
  type = 'limit',
  status = AlertTypes.ALERTED,
}: AlertTicketProps) => {
  const { currentTenant } = useTenants();

  return (
    <AlertTicketWrapper type={type} status={status}>
      <div className="head">
        <div className="id">
          <div className="row">
            {alertLog?.rule_info.target_path.map((path, index) => {
              if (index === 0) {
                return;
              }
              return (
                <React.Fragment key={index}>
                  <span className="header">
                    <Tooltip label={path || ''}>{path}</Tooltip>
                  </span>{' '}
                  /
                </React.Fragment>
              );
            })}
            <span className="header">
              <Tooltip label={alertLog?.rule_info?.target_name || ''}>
                {alertLog?.rule_info.target_name}
              </Tooltip>
            </span>
          </div>
          {type === 'anomaly' ? (
            <div className="row detail">
              <Icon name={ICONS.AnomalyAlert} />
              <span>Anomaly</span>
            </div>
          ) : (
            <div className="row detail">
              <Icon name={ICONS.LimitAlert} />
              <span>Limit exceedence</span>
            </div>
          )}
        </div>
        <div className="timer">
          <div className="row">
            {dayjs.utc(alertLog?.alert_time).local().format('MMM D YY HH:mm')}{' '}
            {TimeUtils.zoneFormat(currentTenant.timezone, 'z')}
          </div>
          <div className="right">
            {alertLog?.rule_info.muted ? (
              <>
                <Icon name={ICONS.Mute} />
                <span>
                  until{' '}
                  {dayjs.utc(alertLog?.rule_info?.mute_duration).local().format('MMM D YY HH:mm')}
                </span>
              </>
            ) : (
              <>
                <Icon name={ICONS.Stopwatch} />
                <span>{alertLog?.alert_condition?.snooze_duration}m</span>
              </>
            )}
          </div>
        </div>
      </div>
      <AlertActions alertLog={alertLog} type={type} kind="log" />
    </AlertTicketWrapper>
  );
};
