import { Composition } from 'atomic-layout';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { NoDataSelected } from '../../../app/components/no-tag-system-selected';
import { Role } from '../../../app/constants/auth';
import { Paths } from '../../../app/constants/paths';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useTenant } from '../../../app/context/tenant-context';
import {
  FolderSort,
  usePlantFolders,
  useSubSystemsById,
  useTagsByName,
} from '../../../app/hooks/folders';
import { TenantSelector } from '../../components/header';
import FolderTree from '../../components/system-explorer/FolderTree';
import SearchBar from '../../components/system-explorer/SearchBar';
import { AlertMode, useAlertContext } from '../../context/alert-v2-context';
import { TagExplorerProvider } from '../../context/tag-explorer-context';

import { AlertConfiguration } from './alert-configurations';
import { AlertsV2Wrapper, areasMobile, Frame, SidePanel, ViewAlertContainer } from './style';

import { Button, Container } from '@controlrooms/components';
import { SubSystem, Tag } from '@controlrooms/models';

export enum TARGET_TYPE {
  SYSTEM = 'SYSTEM',
  TAG = 'TAG',
  VIEW = 'VIEW',
}

export const AlertManager: React.FC = () => {
  // const { sidebarWidth } = usePanelContext();
  const { handleModeChange } = useAlertContext();
  const { hasRole } = useContext(AuthorizationContext);
  const {
    isLoading: isFoldersLoading,
    isError: isFoldersError,
    data: folders,
  } = usePlantFolders(FolderSort.DEFAULT);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: systemsById } = useSubSystemsById();
  const { data: tagsByName } = useTagsByName();
  const { tenant: currentTenantId } = useTenant();

  const queryClient = useQueryClient();
  //TODO: Need to work on Tag rule selection
  // const _alertType = searchParams.get('alertType');
  const _targetId: string = searchParams.get('targetId') || '';
  const _targetType = searchParams.get('targetType');
  const _folderId = searchParams.get('folderId');
  const _ruleId = searchParams.get('ruleId');

  const [selectedTags, setSelectedTags] = useState<Record<number, Tag[]>>({});
  const [selectedSystems, setSelectedSystems] = useState<SubSystem[]>([]);

  const folderId = useMemo(
    () => selectedSystems[0]?.folder || (_folderId as unknown as number),
    [_folderId, selectedSystems],
  );

  useEffect(() => {
    if (_ruleId) {
      if (_targetType === TARGET_TYPE.SYSTEM.toLowerCase() && _targetId) {
        setSelectedSystems([systemsById[Number(_targetId)] as SubSystem]);
        setSelectedTags({
          [Number(_targetId)]: [],
        });
      } else if (_targetType === TARGET_TYPE.TAG.toLowerCase() && _targetId && _folderId) {
        setSelectedSystems([]);
        if (Object.keys(tagsByName).length && _targetId) {
          setSelectedTags({
            [Number(_folderId)]: [tagsByName[_targetId]],
          });
        }
      }
    }
  }, [_folderId, _ruleId, _targetId, _targetType, folderId, systemsById, tagsByName]);

  const alertType = useMemo(() => {
    if (folderId) {
      if (selectedTags[folderId]?.length === 0) return TARGET_TYPE.SYSTEM;
      else if (selectedTags[folderId]?.length > 0) return TARGET_TYPE.TAG;
    }
    return undefined;
  }, [folderId, selectedTags]);

  return (
    <AlertsV2Wrapper>
      <Container isPage>
        <Composition
          areas={areasMobile}
          areasLg={areasMobile}
          templateCols="auto"
          templateColsLg={`${320}px 1fr`}
          templateRows="auto"
          flexGrow="1"
        >
          {(Areas) => (
            <>
              <Areas.Sidebar>
                <TagExplorerProvider>
                  <SidePanel className="side-panel">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <TenantSelector />
                      <div className="back-placeholder">
                        {hasRole(Role.ALERT_DASHBOARD_V2) && (
                          <Button
                            buttonType="text"
                            buttonSize="small"
                            onClick={() => handleModeChange(AlertMode.BOARD)}
                          >
                            {hasRole(Role.ALERT_DASHBOARD_V2) ? '< Back to Alert Dashboard' : ''}
                          </Button>
                        )}
                      </div>
                    </div>
                    <SearchBar />
                    {isFoldersLoading && <div>Loading...</div>}
                    {!folders && isFoldersError && <div>Something went wrong</div>}
                    {!isFoldersLoading && folders && (
                      <div className="folder-tree-section">
                        <FolderTree
                          preSelectedTags={{}}
                          defaultSelectedTags={selectedTags}
                          multiselect={false}
                          systemSelectable={true}
                          folders={folders?.subfolders as SubSystem[]}
                          onSelectTags={(selectedTags, selectedSystems) => {
                            queryClient.removeQueries(['ALERT_CONFIGS']);
                            navigate({
                              pathname: generatePath(Paths.MANAGE_ALERTS_V2, {
                                tenantId: currentTenantId.toString(),
                              }),
                              search: '',
                            });
                            setSelectedTags(selectedTags);
                            setSelectedSystems(selectedSystems);
                          }}
                        />
                      </div>
                    )}
                  </SidePanel>
                </TagExplorerProvider>
              </Areas.Sidebar>
              <Areas.Content>
                {!isFoldersLoading && folders && (
                  <ViewAlertContainer>
                    <Frame>
                      {alertType && folderId ? (
                        <AlertConfiguration
                          alertType={alertType}
                          systemId={folderId}
                          tags={selectedTags[folderId]}
                          selectedRule={_ruleId ? Number(_ruleId) : undefined}
                        />
                      ) : (
                        <NoDataSelected>
                          <h1>Select a tag or system at left</h1>
                          <p>to view alert configurations</p>
                        </NoDataSelected>
                      )}
                    </Frame>
                  </ViewAlertContainer>
                )}
              </Areas.Content>
            </>
          )}
        </Composition>
      </Container>
    </AlertsV2Wrapper>
  );
};
