import React, { useRef, KeyboardEvent, ClipboardEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { ConditionError } from '../../components/alert-modal/styles';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

const Container = styled.div`
  margin: 5px 0;
  font-family: sans-serif;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #242522;
  padding: 6px;
  gap: 5px;
`;

const Chip = styled.div`
  border-radius: 4px;
  background: ${({ theme }) => theme.alert.modal.emailChipBg};
  display: flex;
  padding: 5px 4px 5px 8px;
  align-items: center;
  gap: 5px;
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;

  span {
    margin-right: 8px;
  }
`;

const ChipRemoveButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const ChipsInput = styled.input`
  background: none;
  border: none;
  outline: none;
  flex-grow: 1;
  min-width: 100px;
  margin: 2px;
  color: ${({ theme }) => theme.alert.modal.textColor};
`;

const EmailChipsInput: React.FC = () => {
  const {
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const sendViaEmail = watch('alert_destination.email_destination.emails');
  const emails: string[] = watch('alert_destination.email_destination.emails');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emails.length) {
      clearErrors(['alert_destination.email_destination']);
    } else if (sendViaEmail && !emails.length) {
      setError('alert_destination.email_destination', {
        type: 'custom',
        message: 'Emails are required',
      });
    }
  }, [sendViaEmail, emails, clearErrors, setError]);

  // Helper function to parse and add emails
  // Simple email regex pattern; adjust to your needs
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const addEmails = (emailString: string) => {
    // Split by whitespace, comma, or semicolon
    const parsedEmails = emailString
      .split(/[\s,;]+/)
      .map((e) => e.trim())
      .filter((e) => e.length > 0);

    const newEmails = [...emails];
    parsedEmails.forEach((email) => {
      // Check if valid email and not already in the list
      if (emailRegex.test(email) && !newEmails.includes(email)) {
        newEmails.push(email);
      }
    });

    setValue('alert_destination.email_destination.emails', newEmails);
  };

  // Handle pressing Enter, comma, semicolon, backspace, or delete
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    // If user presses Enter, comma, or semicolon
    if (e.key === 'Enter' || e.key === ',' || e.key === ';') {
      e.preventDefault();
      addEmails(target.value);
      target.value = '';
    }
    // If user presses Backspace or Delete while input is empty -> remove last chip
    else if (
      (e.key === 'Backspace' || e.key === 'Delete') &&
      target.value === '' &&
      emails.length > 0
    ) {
      e.preventDefault();
      removeEmail(emails.length - 1);
    }
  };

  // Handle pasting multiple emails
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text');
    addEmails(pasteData);
  };

  // Remove email by index
  const removeEmail = (indexToRemove: number) => {
    const _emails = emails.filter((_, i) => i !== indexToRemove);
    setValue('alert_destination.email_destination.emails', _emails);
  };

  return (
    <Container>
      <ChipsContainer>
        {emails.map((email, index) => (
          <Chip key={index}>
            <span>{email}</span>
            <ChipRemoveButton type="button" onClick={() => removeEmail(index)}>
              <Icon className="close" name={ICONS.Close} />
            </ChipRemoveButton>
          </Chip>
        ))}

        <ChipsInput
          id="email-chips-input"
          ref={inputRef}
          type="text"
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
        />
      </ChipsContainer>
      <ConditionError data-testid="alert-dashboard-error">
        {errors?.alert_destination?.email_destination?.message || ''}
      </ConditionError>
    </Container>
  );
};

export default EmailChipsInput;
