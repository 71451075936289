import React, {
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import ReactModal from 'react-modal';

import {
  ModalContainer,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalHeaderText,
  ModalStyles,
  HeaderInfoContainer,
  ModalSubHeaderText,
  FooterInfoContainer,
} from './styles';

import { Button } from '@controlrooms/components';
import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useFirstRender, usePrevious } from '@controlrooms/hooks';
import { FunctionUtils } from '@controlrooms/utils';

interface ContextProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  unmountOnExit: boolean;
}

const defaultState = {
  isOpen: false,
  setIsOpen: (): boolean => false,
  unmountOnExit: false,
};

export const ModalContext = createContext<ContextProps>(defaultState);

export enum ConfirmationBannerType {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface ModalProps {
  unmountOnExit?: boolean;
  open?: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, unmountOnExit = false, open }) => {
  const [isOpen, setIsOpen] = useState(open || false);
  const previousOpen = usePrevious(open);
  const isFirstRender = useFirstRender();

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    useEffect(() => {
      if (open === undefined && previousOpen !== undefined) {
        console.warn(
          'Modal is changing from controlled to uncontrolled. Decide between using a controlled or uncontrolled modal for the lifetime of the component.',
        );
      }
      if (!isFirstRender && open !== undefined && previousOpen === undefined) {
        console.warn(
          'Modal is changing from uncontrolled to controlled. Decide between using a controlled or uncontrolled modal for the lifetime of the component.',
        );
      }
    }, [isFirstRender, open, previousOpen]);
  }

  useEffect(() => {
    open !== undefined && setIsOpen(open);
  }, [open]);

  const value = { isOpen, setIsOpen, unmountOnExit };
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

const ModalDismissButton: React.FC = ({ children: child }) => {
  const { setIsOpen } = useContext(ModalContext);
  if (React.isValidElement(child)) {
    return React.cloneElement(child, {
      onClick: FunctionUtils.callAll(() => setIsOpen(false), child.props.onClick),
    });
  }
  return <></>;
};

const ModalConfirmButton: React.FC<{
  callback?: () => void;
}> = ({ callback, children: child }) => {
  if (React.isValidElement(child)) {
    return React.cloneElement(child, {
      onClick: callback,
    });
  }
  return <></>;
};

const ModalOpenButton: React.FC = ({ children: child }) => {
  const { setIsOpen } = useContext(ModalContext);
  if (React.isValidElement(child)) {
    return React.cloneElement(child, {
      onClick: FunctionUtils.callAll(() => setIsOpen(true), child.props.onClick),
    });
  }
  return <></>;
};
const ModalContentsBase: React.FC<{
  shouldCloseOnEsc?: boolean;
  styles?: ReactModal.Styles;
}> = ({ styles, shouldCloseOnEsc = true, children, ...props }) => {
  const { isOpen, setIsOpen, unmountOnExit } = useContext(ModalContext);

  if (unmountOnExit && !isOpen) return <></>;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={shouldCloseOnEsc}
      style={Object.assign({}, ModalStyles, {
        content: Object.assign({ background: 'unset' }, ModalStyles.content, styles?.content),
        overlay: Object.assign({}, ModalStyles.overlay, styles?.overlay),
      })}
      appElement={document.getElementById('root') as HTMLElement}
      {...props}
    >
      {children}
    </ReactModal>
  );
};

const ModalContents: React.FC<{
  title?: string;
  subTitle?: string;
  secondary?: boolean;
  confirmButtonCallback?: () => void | Promise<void> | boolean;
  confirmButtonText?: string;
  footerPrompt?: string | JSX.Element;
  closeButtonCallback?: () => void | Promise<void> | boolean;
  closeButtonText?: string;
  swapButtonOrder?: boolean;
  headerInfoComponent?: React.ReactNode;
  shouldCloseOnEsc?: boolean;
  styles?: ReactModal.Styles;
  confirmButtonDisabled?: boolean;
  dataTestIdCancel?: string;
  dataTestIdConfirm?: string;
  confirmButtonClassName?: string;
}> = ({
  title,
  subTitle,
  secondary,
  headerInfoComponent,
  footerPrompt,
  closeButtonCallback,
  closeButtonText,
  shouldCloseOnEsc,
  confirmButtonCallback,
  confirmButtonText,
  swapButtonOrder,
  styles,
  confirmButtonDisabled,
  confirmButtonClassName,
  children,
  ...props
}) => {
  const { setIsOpen } = useContext(ModalContext);
  const hasConfirmButton = !!(confirmButtonCallback || confirmButtonText);
  return (
    <ModalContentsBase {...props} styles={styles} shouldCloseOnEsc={shouldCloseOnEsc}>
      <ModalContainer>
        <ModalHeader>
          <div className="modal-flex">
            <ModalHeaderText data-testid={`${title?.toLowerCase().split(' ').join('_')}`}>
              {title}
            </ModalHeaderText>
            {headerInfoComponent && (
              <HeaderInfoContainer>{headerInfoComponent}</HeaderInfoContainer>
            )}
            <ModalDismissButton>
              <ModalCloseButton onClick={closeButtonCallback}>
                <Icon
                  data-testid={props.dataTestIdCancel || 'modal-close-icon'}
                  name={ICONS.Close}
                  width="10"
                  height="10"
                />
              </ModalCloseButton>
            </ModalDismissButton>
          </div>
          {subTitle && <ModalSubHeaderText>{subTitle}</ModalSubHeaderText>}
        </ModalHeader>
        <ModalBody secondary={secondary}>{children}</ModalBody>
        {(closeButtonText || footerPrompt || hasConfirmButton) && (
          <ModalFooter customConfirm={!!confirmButtonClassName} secondary={secondary}>
            {closeButtonText && (
              <ModalDismissButton>
                <Button
                  data-testid={props.dataTestIdCancel || 'modal-cancel-button'}
                  buttonSize="small"
                  buttonType={hasConfirmButton ? 'secondary' : 'primary'}
                  style={swapButtonOrder ? { order: 2 } : undefined}
                  onClick={closeButtonCallback}
                >
                  {closeButtonText || 'Close'}
                </Button>
              </ModalDismissButton>
            )}
            {footerPrompt && <FooterInfoContainer>{footerPrompt}</FooterInfoContainer>}
            {hasConfirmButton && (
              <ModalConfirmButton
                callback={() => {
                  if (confirmButtonDisabled) return;
                  if (confirmButtonCallback) {
                    confirmButtonCallback() && setIsOpen(false);
                  } else {
                    setIsOpen(false);
                  }
                }}
              >
                <Button
                  data-testid={props.dataTestIdConfirm || 'modal-confirm-button'}
                  className={confirmButtonClassName || 'confirm'}
                  buttonSize="small"
                  buttonType={!confirmButtonClassName ? 'primary' : 'secondary'}
                  disabled={confirmButtonDisabled}
                >
                  {confirmButtonText || 'Update'}
                </Button>
              </ModalConfirmButton>
            )}
          </ModalFooter>
        )}
      </ModalContainer>
    </ModalContentsBase>
  );
};

export { Modal, ModalDismissButton, ModalOpenButton, ModalContents };
