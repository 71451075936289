import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Role } from '../../../app/constants/auth';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useAlertsAPI, useTeamsWebhookChannels } from '../../../app/hooks/alerts';
import { WebhookModal } from '../../components/webhook-modal/webhook-modal';

import { Button, ButtonGroup, Icon, Modal, ModalContents } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';

const ChannelActions = styled(ButtonGroup)`
  display: flex;
  width: 80px;
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const WebhookItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 31px;
  align-items: center
  justify-content: space-between;
  border-radius: 2px;
  background: ${({ theme }) => theme.alert.alertBackgroundColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 2px;

  .details {
    padding: 15px;
    .title {
      color: ${({ theme }) => theme.alert.content.text};
      margin: 0px;
      color: ${({ theme }) => theme.alert.content.text};
    }
    .type {
      margin-top: 0px;
      display: flex;
      font-size: 14px;
      color: #666;
    }
  }
`;

export const WebhookWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: flex-start;
  width: 80%;
  max-width: 1320px;
`;

const ConfirmationInfo = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  width: 350px;
  margin: 20px;
  color: ${({ theme }) => theme.alert.modal.textColor};
  white-space: pre-line;
`;

const DeleteActionGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface ManageWebhooksProps {
  onDeleteCallBack: () => void;
}

export const ManageWebhooks: React.FC<ManageWebhooksProps> = ({ onDeleteCallBack }) => {
  const { data: teamsWebhookChannels, isLoading } = useTeamsWebhookChannels();
  const { hasRole } = useContext(AuthorizationContext);

  const { deleteTeamsWebHookMutation } = useAlertsAPI();
  const { mutateAsync: deleteTeamsWebHook } = deleteTeamsWebHookMutation;

  const registeredChannels = useMemo(
    () => teamsWebhookChannels?.result || [],
    [teamsWebhookChannels?.result],
  );

  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    channel_name: '',
    title: '',
  });

  const handleDeleteChannel = async (channel_name: string) => {
    setConfirmDelete({
      open: true,
      title: 'Webhook delete',
      channel_name,
    });
  };

  const onCloseDeleteConfirmation = () => {
    setConfirmDelete({ open: false, channel_name: '', title: '' });
  };

  const onDeleteConfirmation = async (channel_name: string) => {
    try {
      await deleteTeamsWebHook(channel_name);
      setConfirmDelete({ open: false, channel_name: '', title: '' });
      onDeleteCallBack();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      setConfirmDelete({ open: false, channel_name: '', title: '' });
    }
  };

  if (isLoading) return <>Loading...</>;

  return (
    <>
      {registeredChannels.map((channel) => {
        return (
          <WebhookItem>
            <div className="details">
              <p data-testid={`webhook_title_${channel.channel_name}`} className="title">
                {channel.channel_name}
              </p>
              <div className="type">
                <Icon name={ICONS.Teams} /> Teams
              </div>
            </div>
            <ChannelActions className="channelActionGroup">
              {hasRole(Role.TEAMS_ADMIN) && (
                <WebhookModal
                  webhookChannel={{
                    channel_id: channel.channel_id || '1',
                    channel_name: channel.channel_name,
                    webhook_url: channel.webhook_url || '',
                  }}
                >
                  <Button
                    data-testid="edit-outgoing-webhook-button"
                    buttonType="secondary"
                    buttonSize="small"
                  >
                    Edit
                  </Button>
                </WebhookModal>
              )}
              {hasRole(Role.TEAMS_ADMIN) && (
                <Button
                  data-testid={`delete_webhook_${channel.channel_name}`}
                  buttonType="secondary"
                  buttonSize="small"
                  onClick={() => {
                    handleDeleteChannel(channel.channel_name);
                  }}
                >
                  Delete
                </Button>
              )}
            </ChannelActions>
          </WebhookItem>
        );
      })}
      <Modal open={confirmDelete.open}>
        <ModalContents
          title={'Delete Webhook'}
          styles={{ content: { maxWidth: '350px' } }}
          shouldCloseOnEsc={false}
          footerPrompt={
            <DeleteWebhookActions
              target={confirmDelete.channel_name}
              onCancel={onCloseDeleteConfirmation}
              onConfirmation={() => onDeleteConfirmation(confirmDelete.channel_name)}
            />
          }
        >
          <div>
            <ConfirmationInfo>
              Are you sure you want to delete the webhook "<b>{confirmDelete.channel_name}</b>"?
            </ConfirmationInfo>
            <ConfirmationInfo>
              Performing this action will affect some alert rules to stop sending notifications to
              the channel.
            </ConfirmationInfo>
          </div>
        </ModalContents>
      </Modal>
    </>
  );
};

const DeleteWebhookActions = ({
  target,
  onCancel,
  onConfirmation,
}: {
  target: string;
  onCancel: () => void;
  onConfirmation: (channel_name: string) => void;
}) => {
  return (
    <DeleteActionGroup>
      <Button
        data-testid="cancel-edit-alert"
        buttonSize="small"
        buttonType="secondary"
        onClick={() => {
          onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        data-testid="delete-alert"
        buttonSize="small"
        buttonType="primary"
        type="submit"
        onClick={() => {
          onConfirmation(target);
        }}
      >
        Delete Alert
      </Button>
    </DeleteActionGroup>
  );
};
