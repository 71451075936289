import React from 'react';
import styled from 'styled-components';

import Arrow from '../../../assets/arrow.svg';
import { breakpoints } from '../../global-styles';

const NoDataSelectedWrap = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 0px auto;
  .desk-view {
    display: none;
    ${breakpoints.desk} {
      display: block;
    }
  }
  .mobile-view {
    display: block;
    ${breakpoints.desk} {
      display: none;
    }
  }
  h1 {
    font-size: 30px;
    text-align: center;
    font-weight: 200;
    padding: 0;
    margin-bottom: 5px;
    filter: brightness(0.8);
  }
  p {
    padding: 0;
    margin: 0 10px;
    flex-direction: column;
    display: flex;
    filter: brightness(0.8);
  }
  span {
    font-size: 30px;
  }
  img {
    margin-top: 25px;
  }
`;

export const NoDataSelected: React.FC = ({ children }) => (
  <NoDataSelectedWrap id="no-data">
    <div className="img-wrap">
      <div className="desk-view">
        <img className="left-arrow" src={Arrow} alt="Arrow Pointing Left" />
      </div>
    </div>
    <div className="text-wrap">
      <div className="desk-view">{children}</div>
      <div className="mobile-view">{children}</div>
    </div>
  </NoDataSelectedWrap>
);
