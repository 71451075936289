import styled from 'styled-components';

import { customScrollPrimary } from '../../../app/global-styles';

import { Ul } from '@controlrooms/components';
import { colors } from '@controlrooms/design-tokens';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  // background: var(--Container-BG-2, #f9f8f4);
  min-width: 1000px;
  /* Elevation/Light/Elevation 2 */
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
`;

export const Head = styled.div`
  display: flex;
  padding: 8px 8px 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const ScrollFrame = styled.div`
  padding-top: 10px;
  height: 100%;
  width: 100%;
  // max-height: 800px;
  overflow: auto;
  .manager {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    .configuration-form {
      margin: 30px;
      display: flex;
      flex-direction: column;
      align-self: normal;
    }
    .configurations-table {
      display: flex;
      padding: 0px 24px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      .title {
        display: flex;
        padding-top: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
      }
      .alert-configurations {
        display: flex;
        padding-bottom: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        align-self: stretch;
        .config-table {
          border-bottom: 1px solid #ddd;

          table {
            width: 100%;
            tbody {
              /* Layout/Inset/Light */
              box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.12) inset;
              border-radius: 4px;
              background: var(--Container-BG-2, #f9f8f4);

              /* Layout/Inset/Light */
              box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.12) inset;
              border: 1px solid #ddd;
            }
            .col-header {
              color: ${({ theme }) => theme.limit.limitConfTable.tableHeaderTextColor};
              background: ${({ theme }) => theme.limit.limitsColBgColor};
              text-align: left;
              /* CR/11 Data */
              font-family: 'Open Sans';
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 13px; /* 118.182% */
              padding: 10px 0px;
              box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.15);
              &.select {
                padding-left: 20px;
              }
            }
            tr {
              cursor: pointer;
              padding: 10px 0px;
              align-items: center;
              align-self: stretch;
              min-height: 40px;
              max-height: 70px;
              background: ${({ theme }) => theme.limit.limitConfTable.tableBgColor};
              &:nth-child(even) {
                background: ${({ theme }) => theme.limit.backgroundColor};
              }
              &:hover {
                background: ${({ theme }) => theme.limit.limitConfTable.tableHoverColor};
              }
              &.selected {
                background: ${({ theme }) => theme.limit.limitConfTable.tableHoverColor};
              }
              .teams-val,
              .board-val {
                display: flex;
                gap: 5px;
                margin-top: 5px;
              }
            }
            .td-val {
              overflow: hidden;
              color: ${({ theme }) => theme.limit.content.text};
              text-overflow: ellipsis;
              vertical-align: middle;
              padding: 10px 0px;
              /* CR/11 Data */
              font-family: 'Open Sans';
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 13px; /* 118.182% */
              &.select {
                padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  padding: 8px 20px 16px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-top: 1px solid var(--Container-BG-6, #b7b3ae);
  // background: var(--Container-BG-1, #fcfcfa);
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1);
  .action-buttons {
    display: flex;
    gap: 10px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  background: var(--Container-BG-2, #f9f8f4);
  border-radius: 4px;
  background: ${({ theme }) => theme.limit.limitConfTable.tableBgColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

  .system-tag {
    display: flex;
    align-items: baseline;
    gap: 4px;
    flex: 1 0 0;
  }
  .tag {
    display: flex;
    height: 20px;
    padding: 0px 5px;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    background: var(--K-K-80, #ccc);
    color: var(--K-K-20, #333);

    /* CR/13 Data */
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 146.154% */
  }

  .system {
    display: flex;
    height: 20px;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
  }

  .description {
    font-family: 'Space Grotesk';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 111.765% */
    color: ${({ theme }) => theme.limit.content.text};

    /* CR/17 Data */
    font-family: 'Space Grotesk';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 111.765% */
  }
  .note {
    color: ${({ theme }) => theme.limit.content.text};

    /* CR/11 Data */
    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px; /* 118.182% */
  }
`;

export const areasMobile = `
sidebar content
`;

export const AlertsV2Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

export const ViewAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.limit.backgroundColor};
  width: 100%;
  height: calc(100vh);
  .active-count {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.alert.alertTicketDetail.header};
    font-family: 'Open Sans';
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: 23px; /* 100% */
    text-align: center;
  }
`;
export const Frame = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
`;

export const SidePanel = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  // flex-grow: 1;
  background: ${({ theme }) => theme.investigate.backgroundColor};
  box-sizing: border-box;
  z-index: 1;
  header {
    display: flex;
    justify-content: space-between;
  }
  .sort-field {
    width: 160px;
  }

  .filter-select {
    padding-left: 10px;
  }

  .folder-tree-section {
    height: calc(100vh - 75px - 74px);
  }

  .back-placeholder {
    height: 25px;
  }
  .manage-alert {
    justify-content: flex-start;
  }

  .scroll-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    background-color: ${({ theme }) => theme.sidebar.folders.backgroundColor};
    /* Layout/Inset/Dark */
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.36) inset;
    height: calc(100vh - 135px);
  }
`;

export const HeaderContainer = styled.span`
  color: ${colors.content['neutral-3']} !important;
  cursor: default;
  display: flex;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  margin-bottom: 5px;
  padding: 4px 0 4px 17px;
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.content['neutral-3']};
`;

export const StyledUl = styled(Ul)`
  li {
    display: flex;
    padding-left: 10px;
    .name {
      padding-left: 10px;
    }
  }
`;

export const FilterSection = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  background: ${({ theme }) => theme.v2.header.tenantHeaderBg};
  // background: var(--UI-Dark-View-Header, linear-gradient(90deg, #253d3f 0%, #0a1315 100%));
  /* Elevation/Dark/Elevation 1 */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  .board-list {
    display: flex;
    // height: 24px;
    align-items: center;
    gap: 2px;
    button {
      overflow: hidden;
      color: ${({ theme }) => theme.v2.header.content.color};
      text-overflow: ellipsis;

      /* CR/13 Data */
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 146.154% */
    }
  }
  .board-list-dropdown {
    top: 60px;
  }
  .filter > button {
    padding: 0;
  }
  .tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    .left {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;

export const AlertHeader = styled.div`
  display: flex;
  height: 50px;
  padding: 12px 20px 0;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  .close-log {
    display: flex;
    color: var(--K-K-55, #8c8c8c);
    text-overflow: ellipsis;
    cursor: pointer;

    /* CR/13 Data */
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 146.154% */
  }
  .board-name {
    font-size: 31px;
    margin-right: 40px;
    text-transform: capitalize;
  }
  .manage-alert {
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

export const CreateAlertBoard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  align-self: stretch;
  input {
    height: 20px;
  }
  .actions {
    display: flex;
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 15px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 20px;
  // padding: 0 40px;
  .alert-log-virtuoso {
    ${customScrollPrimary}
  }
`;

export const BottomFrame = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: calc(100vw - 370px);
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin: 30px 0 20px;

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    svg {
      height: 16px;
      path {
        fill: ${({ theme }) => theme.alert.alertTicketDetail.header};
      }
    }
  }
  .value {
    color: ${({ theme }) => theme.alert.alertTicketDetail.header};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;

export const BottomGrid = styled.div`
  display: flex;
  animation: marquee 50s linear infinite;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  > div {
    min-width: 240px;
    max-width: 400px;
  }
  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  &:hover {
    animation-play-state: paused; /* Stops animation on hover */
  }
`;

export const GridItem = styled.div`
  display: flex;
  justify-content: center;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
  padding: 10px;
  ${customScrollPrimary}
`;
