/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import React, { useContext, useRef, useState } from 'react';

import { AlertModal } from '../../../app/components/alert-modal';
import { Role } from '../../../app/constants/auth';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { Analyze, AnalyzeV2 } from '../../../app/pages/routes';
import { AlertModalV2 } from '../../components/alert-modal/alert-modal-v2';
import { TARGET_TYPE } from '../alertV2/alert-manager';

import { StyledSubFolder } from './styles';

import { Button, Checkbox, Icon, Li, OptionsGroup, Tooltip, Ul } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { SubSystemAnomalous } from '@controlrooms/models';

// TODO: resolve the below any types
interface Props {
  subsystem: SubSystemAnomalous;
  handleCheckboxCheck: any;
  handleAnalyzeLink: any;
  isChecked: boolean;
  showAnalyzeLink: boolean;
  wideSpacing: boolean;
  lastSystem: boolean;
  width: number;
}

export const SubFolder: React.FC<Props> = ({
  subsystem,
  handleCheckboxCheck,
  handleAnalyzeLink,
  isChecked,
  wideSpacing,
  lastSystem,
  width,
}) => {
  return (
    <StyledSubFolder
      style={{
        height: wideSpacing ? 60 : 30,
        width: `${width}px`,
      }}
      className={classNames('subfolder', { selected: isChecked })}
    >
      <div className="folder-content">
        <Icon className="drag-handle" name={ICONS.RowDragHandle} width="6" height="10" />
        <Checkbox
          className="folder-checkbox"
          id={`folder-checkbox-${subsystem.folder}`}
          checked={isChecked}
          dataTestId={`system-view-checkbox-${subsystem.name}`}
          onChange={handleCheckboxCheck}
        />
        <div
          data-testid={`system-view-${subsystem.name}`}
          className="description-wrapper"
          onClick={handleCheckboxCheck}
        >
          <Tooltip
            label={
              subsystem?.infra_display_name && subsystem?.infra_display_name?.length > 40
                ? subsystem?.infra_display_name
                : ''
            }
            place="top"
          >
            {subsystem?.infra_display_name}
          </Tooltip>
        </div>
        <span className={classNames('system-id')} onClick={handleAnalyzeLink}>
          {subsystem?.description && subsystem?.infra_display_name && <span>:&nbsp;</span>}
          {subsystem?.description}
        </span>
      </div>
      <div className="folder-menu">
        <SubSystemMenu
          lastSystem={lastSystem}
          handleAnalyzeLink={handleAnalyzeLink}
          targetId={subsystem?.folder}
          name={subsystem?.name}
        />
        <Tooltip label="Go to Assess" className="analyze-link">
          <button
            data-testid={`go-to-analyze-${subsystem?.name}`}
            type="button"
            onMouseOver={() => Analyze.preload()}
            onClick={handleAnalyzeLink}
          >
            <Icon name={ICONS.AnalyzeLink} width="13" height="10" />
          </button>
        </Tooltip>
      </div>
    </StyledSubFolder>
  );
};

const SubSystemMenu = ({
  handleAnalyzeLink,
  targetId,
  name,
  lastSystem,
}: {
  handleAnalyzeLink: any;
  targetId: number;
  name: string;
  lastSystem: boolean;
}) => {
  const [isMenuSelectOpen, setIsMenuSelectOpen] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { canUserReadAlert, hasRole } = useContext(AuthorizationContext);

  const ulRef = useRef(null);
  useClickOutside(ulRef, () => setIsMenuSelectOpen(false));

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMenuSelectOpen(!isMenuSelectOpen);
  };

  return (
    <OptionsGroup
      className="menu-select"
      style={{
        position: 'absolute',
        zIndex: 1,
        right: 30,
      }}
    >
      <Button
        buttonType="icon"
        buttonSize="small"
        iconName="menu"
        data-testid={`subsystem_view_menu_${name}`}
        className="no-border"
        onClick={(e) => handleMenuClick(e)}
        aria-haspopup="listbox"
        aria-expanded={isMenuSelectOpen}
      />
      <Ul
        showOnTop={lastSystem}
        isOpen={isMenuSelectOpen}
        position="absolute"
        className="dropdown"
        ref={ulRef}
      >
        <Li data-testid="view-tags" key="view-in-analyze">
          <div onMouseOver={() => AnalyzeV2.preload()} onClick={handleAnalyzeLink}>
            View in Assess
          </div>
        </Li>
        {canUserReadAlert && (
          <Li data-testid={`${name}-manage-alert`} key="manage-alert">
            <AlertModal targetType="system" targetId={targetId}>
              <div>Manage Alerts</div>
            </AlertModal>
          </Li>
        )}
        {hasRole(Role.ALERT_MANAGER_V2) && (
          <Li
            data-testid={`${name}-manage-alert`}
            key="manage-alert-v2"
            onClick={() => {
              setShowAlertModal(true);
            }}
          >
            <div>Manage Alerts V2</div>
          </Li>
        )}
        {showAlertModal && (
          <AlertModalV2
            onConfirm={async (state) => {
              console.log(state);
              setShowAlertModal(false);
            }}
            onCancel={() => setShowAlertModal(false)}
            alert={{
              alertType: TARGET_TYPE.SYSTEM,
              systemId: targetId,
              tags: [],
            }}
          />
        )}
        {/* <Li data-testid="more-actions" key="more-actions">
          <span>More Actions</span>
        </Li> */}
      </Ul>
    </OptionsGroup>
  );
};
