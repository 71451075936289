import * as d3 from 'd3';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { colors } from '@controlrooms/design-tokens';
import { AnomalyRange, Mode, TimeSeries } from '@controlrooms/models';

export enum ChartType {
  LIMIT = 'LIMIT',
  ANOMALY = 'ANOMALY',
}

// Hook to observe container size using ResizeObserver
export const useResizeObserver = (ref: React.RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null);
  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries.length || !entries[0].contentRect) return;
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  }, [ref]);
  return dimensions;
};

/** Returns the threshold value for a given time from an array of Modes.
 *  If no Mode covers the given time, returns null.
 */
export const getThresholdForTime = (
  thresholds: Mode[] | undefined,
  time: number,
): number | null => {
  if (!thresholds) return null;
  for (const mode of thresholds) {
    if (time >= mode.start && time <= mode.end) {
      return mode.value;
    }
  }
  return null;
};

/** Draw the area chart for one series with limit shadings.
 *  The area (between each point's max and min) is filled in horizontal bands:
 *    - Above highHigh: rgba(255, 113, 113, 0.80)
 *    - Between highHigh and high: rgba(255, 113, 113, 0.30)
 *    - Between high and low: rgba(0, 0, 0, 0.20)
 *    - Between low and lowLow: rgba(255, 113, 113, 0.30)
 *    - Below lowLow: rgba(255, 113, 113, 0.80)
 *
 *  The shading is applied only between the x‑positions of the first and last data points.
 */

// Define an interface for a time segment.
interface TimeSegment {
  start: number;
  end: number;
  effective: {
    highHigh: number | null;
    high: number | null;
    low: number | null;
    lowLow: number | null;
  };
}

/**
 * Given threshold Mode arrays and an overall time range,
 * compute the segments (by time) where threshold settings change.
 * For each segment (defined by adjacent boundaries), determine the effective threshold
 * by taking the Mode value covering the midpoint (if any) for each category.
 */
export const computeThresholdSegments = (
  thresholds: { highHigh: Mode[]; high: Mode[]; low: Mode[]; lowLow: Mode[] },
  overallRange: [number, number],
): TimeSegment[] => {
  const [overallStart, overallEnd] = overallRange;
  // Collect boundaries: overall start and end plus each mode's start and end (if within overall range)
  const boundaries = new Set<number>();
  boundaries.add(overallStart);
  boundaries.add(overallEnd);
  (['highHigh', 'high', 'low', 'lowLow'] as (keyof typeof thresholds)[]).forEach((cat) => {
    thresholds[cat].forEach((mode) => {
      if (mode.start >= overallStart && mode.start <= overallEnd) boundaries.add(mode.start);
      if (mode.end >= overallStart && mode.end <= overallEnd) boundaries.add(mode.end);
    });
  });
  const sortedBoundaries = Array.from(boundaries).sort((a, b) => a - b);
  const segments: TimeSegment[] = [];
  for (let i = 0; i < sortedBoundaries.length - 1; i++) {
    const segStart = sortedBoundaries[i];
    const segEnd = sortedBoundaries[i + 1];
    const mid = (segStart + segEnd) / 2;
    const effective = {
      highHigh: thresholds.highHigh.find((m) => m.start <= mid && m.end >= mid)?.value ?? null,
      high: thresholds.high.find((m) => m.start <= mid && m.end >= mid)?.value ?? null,
      low: thresholds.low.find((m) => m.start <= mid && m.end >= mid)?.value ?? null,
      lowLow: thresholds.lowLow.find((m) => m.start <= mid && m.end >= mid)?.value ?? null,
    };
    segments.push({ start: segStart, end: segEnd, effective });
  }
  return segments;
};

/**
 * Updated drawAreaChart:
 * - thresholds is now of type { highHigh: Mode[]; high: Mode[]; low: Mode[]; lowLow: Mode[] }.
 * - We compute time segments (TS) from the overall timeseries range using computeThresholdSegments.
 * - For each segment, we compute:
 *    xStart = xScale(seg.start), xEnd = xScale(seg.end)
 *    yHighHigh = yScale(effective.highHigh), etc.
 * - Then we fill five horizontal bands for that segment.
 */
export const drawAreaChart = (
  ctx: CanvasRenderingContext2D,
  timeseries: TimeSeries[],
  xScale: d3.ScaleTime<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  thresholds: { highHigh: Mode[]; high: Mode[]; low: Mode[]; lowLow: Mode[] },
  alertType: ChartType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
) => {
  if (!timeseries.length) return;
  if (!timeseries.every((d) => d.min !== undefined && d.max !== undefined)) return;

  // Build the overall area path (polygon between the max and min values).
  const areaPath = new Path2D();
  areaPath.moveTo(xScale(timeseries[0].time), yScale(timeseries[0].max as number));
  for (let i = 1; i < timeseries.length; i++) {
    areaPath.lineTo(xScale(timeseries[i].time), yScale(timeseries[i].max as number));
  }
  for (let i = timeseries.length - 1; i >= 0; i--) {
    areaPath.lineTo(xScale(timeseries[i].time), yScale(timeseries[i].min as number));
  }
  areaPath.closePath();

  ctx.save();

  // Overall timeseries range.
  const tsStart = timeseries[0].time;
  const tsEnd = timeseries[timeseries.length - 1].time;

  // Compute segments from thresholds.
  const segments = computeThresholdSegments(thresholds, [tsStart, tsEnd]);

  segments.forEach((seg) => {
    // Compute the x-range for the segment.
    const segXStart = xScale(seg.start);
    const segXEnd = xScale(seg.end);
    const segWidth = segXEnd - segXStart;

    // Determine effective thresholds for this segment.
    // For high: prefer a value from thresholds.high if available; otherwise, from thresholds.highHigh.
    const effectiveHigh = seg.effective.high !== null ? seg.effective.high : seg.effective.highHigh;
    // For low: prefer a value from thresholds.low if available; otherwise, from thresholds.lowLow.
    const effectiveLow = seg.effective.low !== null ? seg.effective.low : seg.effective.lowLow;

    // Compute y positions (if effective value is available).
    const yEffHigh = effectiveHigh !== null ? yScale(effectiveHigh) : null;
    const yEffLow = effectiveLow !== null ? yScale(effectiveLow) : null;

    // Now, fill the area in bands:
    if (yEffHigh !== null && yEffLow !== null) {
      // Both thresholds exist:
      // Exceedance above effectiveHigh.
      ctx.save();
      ctx.beginPath();
      ctx.rect(segXStart, 0, segWidth, yEffHigh);
      ctx.clip();
      ctx.fillStyle =
        alertType === ChartType.ANOMALY ? theme.chart.areaFillColor : colors.datavis['pink-1-50'];
      ctx.fill(areaPath);
      ctx.restore();

      // Safe region between effectiveHigh and effectiveLow.
      ctx.save();
      ctx.beginPath();
      ctx.rect(segXStart, yEffHigh, segWidth, yEffLow - yEffHigh);
      ctx.clip();
      ctx.fillStyle = theme.chart.areaFillColor;
      ctx.fill(areaPath);
      ctx.restore();

      // Exceedance below effectiveLow.
      ctx.save();
      ctx.beginPath();
      ctx.rect(segXStart, yEffLow, segWidth, ctx.canvas.height);
      ctx.clip();
      ctx.fillStyle =
        alertType === ChartType.ANOMALY ? theme.chart.areaFillColor : colors.datavis['pink-1-50'];
      ctx.fill(areaPath);
      ctx.restore();
    } else if (yEffHigh !== null && yEffLow === null) {
      // Only effective high exists.
      // Safe region is below effective high; exceedance region is above.
      // Exceedance (above effectiveHigh)
      ctx.save();
      ctx.beginPath();
      ctx.rect(segXStart, 0, segWidth, yEffHigh);
      ctx.clip();
      ctx.fillStyle =
        alertType === ChartType.ANOMALY ? theme.chart.areaFillColor : colors.datavis['pink-1-50'];
      ctx.fill(areaPath);
      ctx.restore();
      // Safe (below effectiveHigh)
      ctx.save();
      ctx.beginPath();
      ctx.rect(segXStart, yEffHigh, segWidth, ctx.canvas.height);
      ctx.clip();
      ctx.fillStyle = theme.chart.areaFillColor;
      ctx.fill(areaPath);
      ctx.restore();
    } else if (yEffHigh === null && yEffLow !== null) {
      // Only effective low exists.
      // Safe region is above effective low; exceedance region is below.
      // Safe (above effectiveLow)
      ctx.save();
      ctx.beginPath();
      ctx.rect(segXStart, 0, segWidth, yEffLow);
      ctx.clip();
      ctx.fillStyle = theme.chart.areaFillColor;
      ctx.fill(areaPath);
      ctx.restore();
      // Exceedance (below effectiveLow)
      ctx.save();
      ctx.beginPath();
      ctx.rect(segXStart, yEffLow, segWidth, ctx.canvas.height);
      ctx.clip();
      ctx.fillStyle =
        alertType === ChartType.ANOMALY ? theme.chart.areaFillColor : colors.datavis['pink-1-50'];
      ctx.fill(areaPath);
      ctx.restore();
    }
  });

  ctx.restore();
};

/** Draw the normal data line for one series. */
export const drawDataLine = (
  ctx: CanvasRenderingContext2D,
  timeseries: { time: Date | number; value: number }[],
  xScale: d3.ScaleTime<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
) => {
  ctx.beginPath();
  ctx.strokeStyle = theme.investigate?.chart?.trendLine;
  ctx.lineWidth = 2;
  timeseries.forEach((d, i) => {
    const x = xScale(d.time);
    const y = yScale(d.value);
    if (i === 0) ctx.moveTo(x, y);
    else ctx.lineTo(x, y);
  });
  ctx.stroke();
};

/** Draw coordinate axes. */
export const drawAxes = (
  ctx: CanvasRenderingContext2D,
  innerWidth: number,
  innerHeight: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
) => {
  ctx.beginPath();
  ctx.strokeStyle = theme.timeLine.minorTickColor; // Y-axis
  ctx.lineWidth = 1;
  ctx.moveTo(0.5, 0);
  ctx.lineTo(0.5, innerHeight);
  ctx.stroke();
  ctx.beginPath();
  ctx.strokeStyle = 'transparent'; // X-axis
  ctx.lineWidth = 1;
  ctx.moveTo(0, innerHeight + 0.5);
  ctx.lineTo(innerWidth, innerHeight + 0.5);
  ctx.stroke();
};

/** Draw x-axis ticks and labels. */
export const drawXAxisTicks = (
  ctx: CanvasRenderingContext2D,
  xScale: d3.ScaleTime<number, number>,
  innerWidth: number,
  innerHeight: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
) => {
  const majorTicks = xScale.ticks(5);
  const allTicks = xScale.ticks(20);
  const minorTicks = allTicks.filter(
    (t) => !majorTicks.some((mt) => Math.abs(t.getTime() - mt.getTime()) < 1000),
  );
  ctx.strokeStyle = theme.timeLine.minorTickColor;
  minorTicks.forEach((tick) => {
    const x = xScale(tick);
    ctx.beginPath();
    ctx.moveTo(x + 0.5, innerHeight + 0.5);
    ctx.lineTo(x + 0.5, innerHeight + 3);
    ctx.stroke();
  });
  ctx.textBaseline = 'top';
  ctx.fillStyle = theme.timeLine.majorTickColor;
  ctx.font = '12px Arial';
  majorTicks.forEach((tick, i) => {
    const x = xScale(tick);
    ctx.beginPath();
    ctx.strokeStyle = theme.timeLine.majorTickColor;
    ctx.moveTo(x + 0.5, innerHeight + 0.5);
    ctx.lineTo(x + 0.5, innerHeight + 5);
    ctx.stroke();
    // Only draw labels for the first and last major tick.
    if (i === 0 || i === majorTicks.length - 1) {
      const label = d3.timeFormat('%b %d, %Y %H:%M')(tick);
      // const tz = tick.toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ').pop();

      ctx.fillText(`${label}`, x - 20, innerHeight + 7);
    }
  });
};

/** Draw y-axis ticks with values. */
export const drawYAxisTicks = (
  ctx: CanvasRenderingContext2D,
  yScale: d3.ScaleLinear<number, number>,
) => {
  const yTicks = yScale.ticks(5);
  ctx.textAlign = 'right';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = '#000';
  ctx.font = '12px Arial';
  yTicks.forEach((tick) => {
    const y = yScale(tick);
    ctx.beginPath();
    ctx.strokeStyle = '';
    ctx.moveTo(-5 + 0.5, y + 0.5);
    ctx.lineTo(0 + 0.5, y + 0.5);
    ctx.stroke();
    ctx.fillText(String(tick), -7, y);
  });
};

/** Draw anomaly lines for one series. */
export const drawAnomalyLines = (
  ctx: CanvasRenderingContext2D,
  anomalyData: AnomalyRange[] | undefined,
  xScale: d3.ScaleTime<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
  exportImg = false,
) => {
  if (!anomalyData || anomalyData.length === 0) return;
  ctx.save();
  ctx.filter = exportImg ? 'none' : 'blur(2px)';
  ctx.lineWidth = 5;
  ctx.strokeStyle = theme.chart.anomalyLines;
  anomalyData.forEach((anomaly) => {
    if (anomaly.yScale && anomaly.yScale.length > 0) {
      ctx.beginPath();
      anomaly.yScale.forEach((point, i) => {
        const x = xScale(dayjs(point.time).valueOf());
        const y = yScale(point.value);
        if (i === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
      });
      ctx.stroke();
    }
  });
  ctx.restore();
};

/** Draw horizontal limit lines with labels over specified time ranges. */
export const drawLimitLines = (
  ctx: CanvasRenderingContext2D,
  limits: { highHighs?: Mode[]; highs?: Mode[]; lowLows?: Mode[]; lows?: Mode[] },
  xScale: d3.ScaleTime<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  innerWidth: number,
) => {
  const drawLineForLimits = (limitArray: Mode[] | undefined, label: string, color: string) => {
    if (!limitArray) return;
    limitArray.forEach((mode) => {
      const xStart = Math.max(0, xScale(mode.start));
      const xEnd = Math.min(innerWidth, xScale(mode.end));
      if (xEnd <= xStart) return;
      const y = yScale(mode.value);
      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.lineWidth = 1;
      ctx.moveTo(xStart, y + 0.5);
      ctx.lineTo(xEnd, y + 0.5);
      ctx.stroke();
      ctx.fillStyle = color;
      ctx.font = '10px Arial';
      ctx.textBaseline = 'middle';
      ctx.fillText(label, xStart + 2, y);
    });
  };
  drawLineForLimits(limits.highHighs, 'HighHigh', 'red');
  drawLineForLimits(limits.highs, 'High', 'orange');
  drawLineForLimits(limits.lowLows, 'LowLow', 'blue');
  drawLineForLimits(limits.lows, 'Low', 'green');
};

/** Draw crosshair and hovered point. */
export const drawCrosshair = (
  ctx: CanvasRenderingContext2D,
  hoveredPoint: { x: number; y: number } | null,
  innerWidth: number,
  innerHeight: number,
) => {
  if (!hoveredPoint) return;
  ctx.save();
  ctx.setLineDash([5, 5]);
  ctx.strokeStyle = 'rgba(0,0,0,0.5)';
  ctx.beginPath();
  ctx.moveTo(hoveredPoint.x + 0.5, 0);
  ctx.lineTo(hoveredPoint.x + 0.5, innerHeight);
  ctx.stroke();
  ctx.beginPath();
  ctx.moveTo(0, hoveredPoint.y + 0.5);
  ctx.lineTo(innerWidth, hoveredPoint.y + 0.5);
  ctx.stroke();
  ctx.restore();
  ctx.beginPath();
  ctx.arc(hoveredPoint.x, hoveredPoint.y, 5, 0, 2 * Math.PI);
  ctx.fillStyle = 'red';
  ctx.fill();
};

/** Draw shaded data limit line segments.
 *  Segments in the safe zone (between low and high) are drawn in black.
 *  Segments above high or below low are drawn in '#FF7171'.
 */
export const drawDataLimitLine = (
  ctx: CanvasRenderingContext2D,
  timeseries: TimeSeries[],
  xScale: d3.ScaleTime<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  thresholds: { high: Mode[]; highHigh: Mode[]; low: Mode[]; lowLow: Mode[] },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
) => {
  if (!timeseries || timeseries.length < 2) return;

  const safeColor = theme.chart.lineColor;
  const exceedanceColor = theme.chart.limitIndicator;

  // Helper: compute intersection point along the segment where value equals threshold.
  const computeIntersection = (pt1: TimeSeries, pt2: TimeSeries, threshold: number): number =>
    pt1.time + ((threshold - pt1.value) * (pt2.time - pt1.time)) / (pt2.value - pt1.value);

  // Helper: draw a line segment.
  const drawSegment = (
    startTime: number,
    startValue: number,
    endTime: number,
    endValue: number,
    color: string,
  ) => {
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.moveTo(xScale(startTime), yScale(startValue));
    ctx.lineTo(xScale(endTime), yScale(endValue));
    ctx.stroke();
  };

  for (let i = 0; i < timeseries.length - 1; i++) {
    const pt1 = timeseries[i];
    const pt2 = timeseries[i + 1];
    const midTime = (pt1.time + pt2.time) / 2;

    // Compute effective high threshold:
    const highCandidate = thresholds.high ? getThresholdForTime(thresholds.high, midTime) : null;
    const highHighCandidate = thresholds.highHigh
      ? getThresholdForTime(thresholds.highHigh, midTime)
      : null;
    const effectiveHigh = highCandidate !== null ? highCandidate : highHighCandidate;
    // Compute effective low threshold:
    const lowCandidate = thresholds.low ? getThresholdForTime(thresholds.low, midTime) : null;
    const lowLowCandidate = thresholds.lowLow
      ? getThresholdForTime(thresholds.lowLow, midTime)
      : null;
    const effectiveLow = lowCandidate !== null ? lowCandidate : lowLowCandidate;

    // If no effective thresholds available, treat segment as safe.
    if (effectiveHigh === null && effectiveLow === null) {
      drawSegment(pt1.time, pt1.value, pt2.time, pt2.value, safeColor);
      continue;
    }
    const currentHigh = effectiveHigh !== null ? effectiveHigh : Infinity;
    const currentLow = effectiveLow !== null ? effectiveLow : -Infinity;

    // Determine status for each point.
    const status1 =
      pt1.value > currentHigh
        ? 'HIGH_EXCEEDANCE'
        : pt1.value < currentLow
        ? 'LOW_EXCEEDANCE'
        : 'safe';
    const status2 =
      pt2.value > currentHigh
        ? 'HIGH_EXCEEDANCE'
        : pt2.value < currentLow
        ? 'LOW_EXCEEDANCE'
        : 'safe';

    if (status1 === status2) {
      const color = status1 === 'safe' ? safeColor : exceedanceColor;
      drawSegment(pt1.time, pt1.value, pt2.time, pt2.value, color);
    } else {
      const segments: {
        startTime: number;
        startValue: number;
        endTime: number;
        endValue: number;
        color: string;
      }[] = [];
      if (status1 === 'safe' && status2 === 'HIGH_EXCEEDANCE') {
        const intersectTime = computeIntersection(pt1, pt2, currentHigh);
        segments.push({
          startTime: pt1.time,
          startValue: pt1.value,
          endTime: intersectTime,
          endValue: currentHigh,
          color: safeColor,
        });
        segments.push({
          startTime: intersectTime,
          startValue: currentHigh,
          endTime: pt2.time,
          endValue: pt2.value,
          color: exceedanceColor,
        });
      } else if (status1 === 'HIGH_EXCEEDANCE' && status2 === 'safe') {
        const intersectTime = computeIntersection(pt1, pt2, currentHigh);
        segments.push({
          startTime: pt1.time,
          startValue: pt1.value,
          endTime: intersectTime,
          endValue: currentHigh,
          color: exceedanceColor,
        });
        segments.push({
          startTime: intersectTime,
          startValue: currentHigh,
          endTime: pt2.time,
          endValue: pt2.value,
          color: safeColor,
        });
      } else if (status1 === 'safe' && status2 === 'LOW_EXCEEDANCE') {
        const intersectTime = computeIntersection(pt1, pt2, currentLow);
        segments.push({
          startTime: pt1.time,
          startValue: pt1.value,
          endTime: intersectTime,
          endValue: currentLow,
          color: safeColor,
        });
        segments.push({
          startTime: intersectTime,
          startValue: currentLow,
          endTime: pt2.time,
          endValue: pt2.value,
          color: exceedanceColor,
        });
      } else if (status1 === 'LOW_EXCEEDANCE' && status2 === 'safe') {
        const intersectTime = computeIntersection(pt1, pt2, currentLow);
        segments.push({
          startTime: pt1.time,
          startValue: pt1.value,
          endTime: intersectTime,
          endValue: currentLow,
          color: exceedanceColor,
        });
        segments.push({
          startTime: intersectTime,
          startValue: currentLow,
          endTime: pt2.time,
          endValue: pt2.value,
          color: safeColor,
        });
      } else if (status1 === 'HIGH_EXCEEDANCE' && status2 === 'LOW_EXCEEDANCE') {
        // Crosses from above to below: segment crosses both thresholds.
        const tHigh = computeIntersection(pt1, pt2, currentHigh);
        const tLow = computeIntersection(pt1, pt2, currentLow);
        segments.push({
          startTime: pt1.time,
          startValue: pt1.value,
          endTime: tHigh,
          endValue: currentHigh,
          color: exceedanceColor,
        });
        segments.push({
          startTime: tHigh,
          startValue: currentHigh,
          endTime: tLow,
          endValue: currentLow,
          color: safeColor,
        });
        segments.push({
          startTime: tLow,
          startValue: currentLow,
          endTime: pt2.time,
          endValue: pt2.value,
          color: exceedanceColor,
        });
      } else if (status1 === 'LOW_EXCEEDANCE' && status2 === 'HIGH_EXCEEDANCE') {
        const tLow = computeIntersection(pt1, pt2, currentLow);
        const tHigh = computeIntersection(pt1, pt2, currentHigh);
        segments.push({
          startTime: pt1.time,
          startValue: pt1.value,
          endTime: tLow,
          endValue: currentLow,
          color: exceedanceColor,
        });
        segments.push({
          startTime: tLow,
          startValue: currentLow,
          endTime: tHigh,
          endValue: currentHigh,
          color: safeColor,
        });
        segments.push({
          startTime: tHigh,
          startValue: currentHigh,
          endTime: pt2.time,
          endValue: pt2.value,
          color: exceedanceColor,
        });
      }
      segments.forEach((seg) => {
        drawSegment(seg.startTime, seg.startValue, seg.endTime, seg.endValue, seg.color);
      });
    }
  }
};
