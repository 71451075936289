import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  archiveAlert,
  createAlert,
  createRuleBoard,
  deleteAlert,
  deleteAlertByCustomViewId,
  deleteMultipleAlert,
  deleteRuleBoard,
  deleteTeamsWebHook,
  getAlertByID,
  getAlertByTargetID,
  getAlertConfigs,
  getAlertSearchSuggestion,
  getAlertSummary,
  getAlerts,
  getRegisteredChannels,
  getRuleBoardById,
  getRuleBoardList,
  muteRuleBoard,
  patchAlert,
  registerTeamsWebHook,
  testTeamsWebHook,
  updateAlert,
  updateRuleBoard,
  updateTeamsWebHook,
} from '../services/alerts';

import { MuteData, RuleBoard, RuleBoardData } from '@controlrooms/models';

export const useTeamsWebhookChannels = () => {
  return useQuery('registeredTeamsChannels', () => getRegisteredChannels());
};

export const useGetAlertSummary = (target_type: string, target_id: string) => {
  return useQuery(
    ['ALERT_SUMMARY', target_id],
    () => getAlertSummary({ targetType: target_type, targetId: target_id }),
    {
      cacheTime: 10,
    },
  );
};

export const useAlertsAPI = () => {
  const queryClient = useQueryClient();

  const createAlertMutation = useMutation(createAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertsMutation = useMutation(getAlerts, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertByIDMutation = useMutation(getAlertByID, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertByTargetIDMutation = useMutation(getAlertByTargetID, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertSummaryMutation = useMutation(getAlertSummary, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getAlertSearchSuggestionMutation = useMutation(getAlertSearchSuggestion, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const updateAlertMutation = useMutation(updateAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const patchAlertMutation = useMutation(patchAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const deleteAlertMutation = useMutation(deleteAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const deleteMultipleAlertMutation = useMutation(deleteMultipleAlert, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const getRegisteredChannelsMutation = useMutation(getRegisteredChannels, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });
  // const useAlertSearchSuggestion = (params: any) =>
  //   useQuery(['searchSuggestion', params], () => getAlertSearchSuggestion(params));

  const deleteAlertByCustomViewMutation = useMutation(deleteAlertByCustomViewId, {
    onSuccess: () => queryClient.invalidateQueries('alerts'),
  });

  const registerTeamsWebHookMutation = useMutation(registerTeamsWebHook, {
    onSuccess: () => queryClient.invalidateQueries('registeredTeamsChannels'),
  });

  const updateTeamsWebHookMutation = useMutation(updateTeamsWebHook, {
    onSuccess: () => queryClient.invalidateQueries('registeredTeamsChannels'),
  });

  const deleteTeamsWebHookMutation = useMutation(deleteTeamsWebHook, {
    onSuccess: () => queryClient.invalidateQueries('registeredTeamsChannels'),
  });

  const testTeamsWebHookMutation = useMutation(testTeamsWebHook);

  return {
    createAlertMutation,
    getAlertsMutation,
    getAlertByIDMutation,
    getAlertByTargetIDMutation,
    getAlertSummaryMutation,
    getAlertSearchSuggestionMutation,
    updateAlertMutation,
    patchAlertMutation,
    deleteAlertMutation,
    deleteMultipleAlertMutation,
    getRegisteredChannelsMutation,
    deleteAlertByCustomViewMutation,
    registerTeamsWebHookMutation,
    deleteTeamsWebHookMutation,
    updateTeamsWebHookMutation,
    testTeamsWebHookMutation,
    // useAlertSearchSuggestion,
  };
};
export const useFetchAlertConfigs = (target_type: string, target_id: string) => {
  return useQuery(
    ['ALERT_CONFIGS', target_id],
    () => getAlertConfigs({ targetType: target_type.toLowerCase(), targetId: target_id }),
    {
      cacheTime: 2,
    },
  );
};

export const useGetRuleBoard = () => {
  return useQuery(['ALERT_BOARDS'], getRuleBoardList, {
    retry: 0,
    select: (ruleBoards: RuleBoard[]) => ruleBoards,
  });
};
export const useGetAlertsForBoard = (boardId: string) => {
  return useQuery(['ALERTS_BY_BOARD_ID', boardId], () => getRuleBoardById(boardId));
};

export const useCreateRuleBoard = (
  onSuccess?: (data: string) => Promise<unknown> | void,
  onError?: (error: AxiosError) => Promise<unknown> | void,
) => {
  const queryClient = useQueryClient();
  return useMutation((data: RuleBoardData) => createRuleBoard(data), {
    onSuccess() {
      queryClient.invalidateQueries(['ALERT_BOARDS']);
    },
    onError,
  });
};

export const useUpdateRuleBoard = () => {
  const queryClient = useQueryClient();
  return useMutation((data: RuleBoardData) => updateRuleBoard(data), {
    onSuccess() {
      queryClient.invalidateQueries(['ALERT_BOARDS']);
    },
  });
};

export const useMuteRuleBoard = () => {
  const queryClient = useQueryClient();
  return useMutation((data: MuteData) => muteRuleBoard(data.rule_id, data), {
    onSuccess() {
      queryClient.invalidateQueries(['ALERTS_BY_BOARD_ID']);
    },
  });
};

export const useArchiveAlert = () => {
  const queryClient = useQueryClient();
  return useMutation((alertId: number) => archiveAlert(alertId), {
    onSuccess() {
      queryClient.invalidateQueries(['ALERTS_BY_BOARD_ID']);
    },
  });
};

export const useDeleteRuleBoard = () => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deleteRuleBoard(id), {
    onSuccess() {
      queryClient.invalidateQueries(['ALERT_BOARDS']);
    },
  });
};

export const useGetAlertConfigs = ({
  alertType,
  targetId,
}: {
  alertType: string;
  targetId: string;
}) => {
  const queryString = `filter_by=${alertType.toLowerCase()}&filter_text=${targetId}`;
  return useQuery({
    queryKey: ['ALERT_CONFIGS', targetId],
    queryFn: () => getAlerts(queryString),
    staleTime: 60 * 1000,
  });
};
