import styled, { css } from 'styled-components';

export const AlertTicketDetailWrapper = styled.div<{ type?: string; status?: string }>`
  display: flex;
  position: relative;
  min-height: 260px;
  max-height: 360px;
  min-width: 320px;
  max-width: 800px;
  padding: 5px 5px 10px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 2px solid #387c8a;
  background: ${({ theme }) => theme.alert.alertTicketDetail.background};
  &:hover {
    .alert-action {
      display: inline-flex;
    }
  }
  .head {
    display: flex;
    height: 62px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    max-width: 100%;
    min-width: 0;
    .id {
      display: flex;
      padding-left: 5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1;
      max-width: 100%;
      min-width: 0;
      .header-system {
        display: flex;
        align-items: center;
        gap: 2px;
        align-self: stretch;

        color: ${({ theme }) => theme.alert.alertTicketDetail.header};
        text-align: center;
        font-family: 'Open Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .header {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .name {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        svg {
          height: 24px;
        }
        .text {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          flex: 1 0 0;
          overflow: hidden;
          color: ${({ theme }) => theme.alert.alertTicketDetail.anomalyName};
          text-overflow: ellipsis;
          font-family: 'Open Sans';
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    .timer {
      display: flex;
      min-width: 100px;
      padding: 0px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      align-self: stretch;
      border-radius: 5px;
      background: ${({ theme }) => theme.alert.alertTicketDetail.anomalyTimerBackground};
      .time {
        color: ${({ theme }) => theme.alert.alertTicketDetail.timerText};
        font-family: 'Open Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .frame {
        display: flex;
        align-items: center;
        gap: 5px;
        .value {
          color: ${({ theme }) => theme.alert.alertTicketDetail.timerText};
          font-family: 'Open Sans';
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    .chart {
      display: flex;
      min-height: 80px;
      max-height: 142px;
      padding: 0px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
    }
    .time-scale {
      height: 37px;
      text-align: center;
      align-self: stretch;
      color: ${({ theme }) => theme.alert.alertTicketDetail.anomalyName};
      /* CR/13 Data */
      font-family: 'Open Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 146.154% */
    }
  }
  ${(props) =>
    props.type === 'limit' &&
    css`
      border: 2px solid #592d2d;
      background: ${({ theme }) => theme.alert.alertTicketDetail.anomalyBackground};
      .head {
        .id {
          .name {
            .text {
              color: ${({ theme }) => theme.alert.alertTicketDetail.limitName};
            }
          }
        }
        .timer {
          background: ${({ theme }) => theme.alert.alertTicketDetail.limitTimerBackground};
        }
      }
      .body {
        .time-scale {
          color: ${({ theme }) => theme.alert.alertTicketDetail.limitName};
          font-size: 18px;
        }
      }
    `}
`;

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 12px;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 4px;
  column-gap: 10px;
  align-self: stretch;
  .tag {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .name {
      display: flex;
      padding: 0px 3px;
      align-items: center;
      gap: 1px;
      border-radius: 2px;
      background: var(--Charting-Yellow-2, #e8c23a);
      color: var(--K-K-5, #0d0d0d);
      font-family: 'Open Sans';
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 11px; /* 122.222% */
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      flex: 1 0 0;
      overflow: hidden;
      color: ${({ theme }) => theme.alert.alertTicketDetail.tagDescription};
      text-overflow: ellipsis;

      /* CR/11 Data */
      font-family: 'Open Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px; /* 118.182% */
    }
  }
`;

export const AlertActionsWrapper = styled.div`
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  border: 4px solid rgba(30, 132, 152, 0.05);
  background: ${({ theme }) => theme.alert.alertTicketDetail.actionBG};
  box-shadow: 0px 4px 36px 0px #000;
  backdrop-filter: blur(8px);
  .action-icon {
    display: flex;
    width: 40px;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    > svg {
      height: 20px;
      width: 20px;
      path {
        fill: ${({ theme }) => theme.alert.alertTicketDetail.actionIconColor};
      }
    }
  }
  &.log {
    padding: 2px;
    gap: 0;
    bottom: 10px;
    left: 50%;
  }
`;
