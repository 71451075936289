import { Composition } from 'atomic-layout';
import React, { FormEvent, useEffect, useRef, useState } from 'react';

import { AlertExportModal } from '../../../app/components/export-modal/export-modal';
import { Loading } from '../../../app/components/loading/loading';
import { NoDataSelected } from '../../../app/components/no-tag-system-selected';
import {
  useCreateRuleBoard,
  useDeleteRuleBoard,
  useGetRuleBoard,
  useUpdateRuleBoard,
} from '../../../app/hooks/alerts';
import { TenantSelector } from '../../components/header';
import { useAlertBoardContext } from '../../context/alert-board-context';

import { AlertList } from './alert-list';
import { AlertLogs } from './alert-logs';
import {
  AlertsV2Wrapper,
  areasMobile,
  CreateAlertBoard,
  FilterSection,
  Frame,
  HeaderContainer,
  SidePanel,
  StyledUl,
} from './style';

import {
  Button,
  Container,
  Icon,
  Li,
  Modal,
  ModalContents,
  TextInput,
} from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { RuleBoardData } from '@controlrooms/models';

export enum AlertTypes {
  ALL = 'ALL',
  ALERTED = 'ALERTED',
  SKIPPED = 'SKIPPED',
  MUTED = 'MUTED',
  ARCHIVED = 'ARCHIVED',
}

const viewTypeList = [
  { type: AlertTypes.ALL, label: 'All' },
  { type: AlertTypes.ALERTED, label: 'Alerted' },
  { type: AlertTypes.ARCHIVED, label: 'Archived' },
  { type: AlertTypes.MUTED, label: 'Muted' },
  { type: AlertTypes.SKIPPED, label: 'Skipped' },
];

export const AlertBoard: React.FC = () => {
  const [selectedViewType, setSelectedViewType] = useState(viewTypeList[0]);

  const { showLog, alertBoard, openShare, setOpenShare, viewLink, setViewLink } =
    useAlertBoardContext();
  const { isLoading: isRuleBoardLoading } = useGetRuleBoard();
  return (
    <AlertsV2Wrapper>
      <Container isPage>
        <Composition
          areas={areasMobile}
          areasLg={areasMobile}
          templateCols="auto"
          templateColsLg={showLog ? `${320}px 1fr` : `0 1fr`}
          templateRows="auto"
          flexGrow="1"
        >
          {(Areas) => (
            <>
              <Areas.Sidebar style={{ display: showLog ? '' : 'none' }}>
                <SidePanel className="side-panel">
                  <div style={{ display: 'flex' }}>
                    <TenantSelector height={60} />
                  </div>
                  <FilterSection>
                    <AlertBoardPicker />
                    <div className="tools">
                      <div className="left">
                        <ViewFilter onChange={(option) => setSelectedViewType(option)} />
                      </div>
                      <div className="right"></div>
                    </div>
                  </FilterSection>
                  <div className="scroll-area">
                    {alertBoard?.rule_board_id && (
                      <AlertLogs
                        boardId={alertBoard?.rule_board_id}
                        viewType={selectedViewType.type}
                      />
                    )}
                  </div>
                </SidePanel>
              </Areas.Sidebar>
              <Areas.Content>
                {alertBoard?.rule_board_id && <AlertList boardId={alertBoard?.rule_board_id} />}
                {isRuleBoardLoading && <Loading overlay small={true} />}
                {!isRuleBoardLoading && !alertBoard?.rule_board_id && (
                  <Frame>
                    <NoDataSelected>
                      <h1>Select an alert board at top left</h1>
                      <p>to view alert configurations</p>
                    </NoDataSelected>
                  </Frame>
                )}
                <Modal open={openShare}>
                  <ModalContents
                    title={'Share this alert'}
                    styles={{ content: { maxWidth: '350px' } }}
                    shouldCloseOnEsc={false}
                    closeButtonCallback={() => {
                      setOpenShare(!openShare);
                      setViewLink('');
                    }}
                    dataTestIdCancel="modal-close-icon-share-alert"
                  >
                    <AlertExportModal link={viewLink} />
                  </ModalContents>
                </Modal>
              </Areas.Content>
            </>
          )}
        </Composition>
      </Container>
    </AlertsV2Wrapper>
  );
};

const AlertBoardPicker = () => {
  const [isBoardListOpen, setIsBoardListOpen] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [boardName, setBoardName] = useState('');
  const filtersRef = useRef(null);
  const { alertBoard, setAlertBoard } = useAlertBoardContext();

  const { data: ruleBoardList } = useGetRuleBoard();
  const { mutateAsync: createRuleBoard, isLoading: isSaveLoading } = useCreateRuleBoard();
  const { mutateAsync: updateRuleBoard, isLoading: isUpdateLoading } = useUpdateRuleBoard();
  const { mutateAsync: deleteRuleBoard, isLoading: isDeleteLoading } = useDeleteRuleBoard();

  useEffect(() => {
    if (!alertBoard?.rule_board_id && ruleBoardList?.length) {
      setAlertBoard(ruleBoardList[0]);
    }
  }, [alertBoard?.rule_board_id, ruleBoardList, setAlertBoard]);

  useClickOutside(filtersRef, () => setIsBoardListOpen(false));

  const handleCreateOrUpdateRuleBoard = async () => {
    try {
      if (isSaveLoading || isUpdateLoading) {
        return;
      }
      if (isCreateMode) {
        const res = await createRuleBoard({ rule_board_name: boardName });
        console.log(res);
        setAlertBoard(res.result);
        setIsCreateMode(false);
      } else {
        const result = await updateRuleBoard({ ...alertBoard, rule_board_name: boardName });
        console.log(result);
        setAlertBoard((prev) => {
          return { ...prev, rule_board_name: boardName };
        });
        setIsEditMode(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteRuleBoard = async () => {
    try {
      if (isDeleteLoading) {
        return;
      }
      const result = await deleteRuleBoard(alertBoard?.rule_board_id as string);
      console.log(result);
      setAlertBoard({} as RuleBoardData);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isCreateMode || isEditMode ? (
        <CreateAlertBoard>
          <TextInput
            data-testid="board-name-input"
            placeholder="Name this Alert Board"
            value={boardName}
            // errorMessage={fieldError}
            errorId="custom_view_creation_error"
            onKeyDown={(e: React.KeyboardEvent) => {
              e.stopPropagation();
            }}
            onChange={(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const target: HTMLInputElement = e.currentTarget as HTMLInputElement;
              // setFieldError('');
              setBoardName(target.value);
            }}
          />
          <div className="actions">
            <Button
              data-testid="save-custom-view"
              buttonSize="small"
              buttonType="text"
              onClick={() => {
                handleCreateOrUpdateRuleBoard();
              }}
              disabled={isSaveLoading}
            >
              {isSaveLoading ? 'Saving...' : 'Save'}
            </Button>
            <Button
              buttonSize="small"
              buttonType="text"
              data-testid="cancel-custom-view"
              onClick={() => {
                setBoardName('');
                setIsCreateMode(false);
                setIsEditMode(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </CreateAlertBoard>
      ) : (
        <div className="board-list filter">
          <Button
            buttonType="menu-text"
            buttonSize="small"
            onClick={() => setIsBoardListOpen(true)}
          >
            <span>{alertBoard?.rule_board_name || 'Select Board'}</span>
          </Button>
          <StyledUl isOpen={isBoardListOpen} className="board-list-dropdown" ref={filtersRef}>
            <HeaderContainer>ALERT BOARDS</HeaderContainer>
            {ruleBoardList?.length ? (
              ruleBoardList?.map((option) => {
                const isSelected = option.rule_board_id === alertBoard?.rule_board_id;
                return (
                  <Li
                    className={`filter-menu-item ${!isSelected ? 'unselected' : ''}`}
                    onClick={() => {
                      setBoardName(option.rule_board_name || '');
                      setIsBoardListOpen(false);
                      setAlertBoard(option);
                    }}
                    key={option.rule_board_id}
                  >
                    <Icon name={ICONS.CheckmarkDefault} color={!isSelected ? 'transparent' : ''} />
                    <span className="name">{option.rule_board_name}</span>
                  </Li>
                );
              })
            ) : (
              <Li style={{ paddingLeft: '23px' }}>No boards</Li>
            )}
            {}
            <Li className="separator"></Li>
            <Li
              style={{ paddingLeft: '23px' }}
              onClick={() => {
                setBoardName('');
                setIsCreateMode(true);
                setIsBoardListOpen(false);
              }}
            >
              Create new board
            </Li>
            <Li
              style={{ paddingLeft: '23px' }}
              onClick={() => {
                if (!alertBoard?.rule_board_name) return;
                setBoardName(alertBoard?.rule_board_name || '');
                setIsEditMode(true);
                setIsBoardListOpen(false);
              }}
              isDisabled={Boolean(!alertBoard?.rule_board_name)}
            >
              Rename this board
            </Li>
            <Li
              style={{ paddingLeft: '23px' }}
              onClick={() => {
                handleDeleteRuleBoard();
                setIsBoardListOpen(false);
              }}
            >
              Delete this board
            </Li>
          </StyledUl>
        </div>
      )}
    </>
  );
};

const ViewFilter = ({
  onChange,
}: {
  onChange: (value: { type: AlertTypes; label: string }) => void;
}) => {
  const [isViewTypeListOpen, setIsViewTypeListOpen] = useState(false);
  const viewTypeFiltersRef = useRef(null);
  useClickOutside(viewTypeFiltersRef, () => setIsViewTypeListOpen(false));
  const [selectedViewType, setSelectedViewType] = useState(viewTypeList[0]);

  const handleFilterChange = (option: { type: AlertTypes; label: string }) => {
    onChange(option);
    setSelectedViewType({ ...option });
    setIsViewTypeListOpen(false);
  };

  return (
    <div className="view-type filter">
      <Button buttonType="menu-text" buttonSize="small" onClick={() => setIsViewTypeListOpen(true)}>
        <span>
          {'View '}
          {selectedViewType.label}
        </span>
      </Button>
      <StyledUl
        isOpen={isViewTypeListOpen}
        className="board-list-dropdown"
        ref={viewTypeFiltersRef}
      >
        {viewTypeList?.map((option) => {
          return (
            <Li
              className={`filter-menu-item`}
              onClick={() => {
                handleFilterChange(option);
                setIsViewTypeListOpen(false);
              }}
              key={option.type}
            >
              <span className="name">{option.label}</span>
            </Li>
          );
        })}
      </StyledUl>
    </div>
  );
};
